import React, { memo } from "react";
import memoize from "memoize-one";
import clsx from "clsx";

import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { FixedSizeList as List, areEqual } from "react-window";
import IconoFlechaAzul from "../../icons/icono-flecha-derecha-blue2.png";

const createRayoData = memoize((items, rayo, classes) => ({
  items,
  rayo,
  classes,
}));

function ListaRayos({ rayos, width, rayo, tipo, filtro_rayos, classes }) {
  let filterRayos = rayos.filter((rayo) => {
    let bandera = false;
    if (
      rayo.status_rayo
        .toString()
        .toLowerCase()
        .includes(tipo.toString().toLowerCase()) &&
      (rayo.name_rayo.toLowerCase().includes(filtro_rayos.toLowerCase()) ||
        rayo.dni_rayo.toLowerCase().includes(filtro_rayos.toLowerCase()) ||
        rayo._id.toLowerCase().includes(filtro_rayos.toLowerCase()))
    ) {
      bandera = true;
    }
    return bandera;
  });
  const itemData = createRayoData(filterRayos, rayo, classes);
  let height = window.innerHeight - 64 - 102;
  // let height = window.innerHeight - 64 - 99;

  return (
    <List
      itemCount={filterRayos.length}
      itemData={itemData}
      height={height}
      width={width}
      itemSize={70}
    >
      {ItemRayo}
    </List>
  );
}

const ItemRayo = memo(({ data, index, style }) => {
  const { items, rayo, classes } = data;
  const item = items[index];

  return (
    <div
      className={"w-100 m-0 px-3 pt-3 pb-2"}
      style={{ top: style.top, position: style.position }}
    >
      <div className="row w-100 m-0 cursor-pointer" onClick={() => rayo(item)}>
        <Box display="inline-flex" justifyContent="flex-start" width="65px">
          <Box position="relative" height="50px" width="50px">
            <img
              src={item.image_rayo}
              height="50"
              width="50"
              alt="Rayo"
              className="img-rayo"
            />
            <div
              className={
                item.status_rayo === 1
                  ? "position-absolute dot activo"
                  : "position-absolute dot inactivo"
              }
              style={{ bottom: -4, right: -4 }}
            ></div>
          </Box>
        </Box>

        <Box
          display="inline-flex"
          alignItems="center"
          width="calc(100% - 65px - 54px - 20px)"
        >
          <div
            style={{ position: "relative", width: "100%", display: "block" }}
          >
            <p className="d-flex align-items-center mb-0 text-exo-bold txt-rayo-name color-dark-grey">
              {item.name_rayo}
            </p>
            <p className="d-flex align-items-center mb-0 text-roboto-regular txt-carta color-dark-grey">
              {"+ " + item.number_rayo}
            </p>
            <p className="d-flex align-items-center mb-0 text-roboto-regular txt-carta color-dark-grey text-truncate w-100">
              {item.providers
                ? item.providers.length !== 0
                  ? item.providers[0].name_provider
                  : false
                : false}
            </p>
          </div>
        </Box>

        <Box
          display="inline-flex"
          justifyContent="flex-end"
          alignItems="center"
          width="54px"
        >
          <div>
            <Box
              className={clsx(
                "txt-rayo-name button-number-task d-flex align-items-center justify-content-center d-block mx-auto",
                classes.btnTasks
              )}
            >
              <div>
                <p className="mb-0 txt-carta-name text-exo-bold text-center">
                  {item.tasks_in_progress_rayo}
                </p>
                <p className="text-center text-roboto-regular min-txt-carta mb-0 text-white">
                  Tareas
                </p>
              </div>
            </Box>
            <p
              className={clsx(
                "text-center text-exo-bold txt-carta-small mb-0",
                classes.textPrimary
              )}
            >
              Optimizar
            </p>
            <p
              className={clsx(
                "text-center text-exo-bold txt-carta-small mb-0",
                classes.textPrimary
              )}
            >
              Tareas
            </p>
          </div>
        </Box>

        <Box
          display="inline-flex"
          justifyContent="flex-end"
          alignItems="center"
          width="20px"
          className="cursor-pointer"
        >
          <img src={IconoFlechaAzul} width="10" alt="ir" />
        </Box>
      </div>
    </div>
  );
}, areEqual);

const styles = (theme) => ({
  btnTasks: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: 30,
    height: 30,
    borderRadius: 4,
  },
  textPrimary: {
    color: theme.palette.primary.main,
  },
});

export default withStyles(styles, { withTheme: true })(ListaRayos);
