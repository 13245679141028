import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import IconoFlechaBlanca from '../../icons/icono-up-white.svg';

const iconStyles = {};
const CustomExpandMore = withStyles(iconStyles)(({ className, classes, ...rest }) => {
    return (
        <IconButton aria-label="toggle password visibility" edge="end" {...rest} className={className}>
            <img src={IconoFlechaBlanca} alt="icono" width="18" className="rotar-icono-180 r-0" />
        </IconButton>
    );
});

export default CustomExpandMore;
