import React, { useState, useEffect } from "react";
import url from "../config";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import IconoRayo from "../assets/icons/logo-rayo-blue.png";

const PerfilRayo = (props) => {
  const classes = useStyles();
  const paramId = props.match.params.id;
  const [rayo, setRayo] = useState(null);

  useEffect(() => {
    getRayoById(paramId);
  }, [paramId]);

  const getRayoById = async (id) => {
    await url.api.get("/Rayos/rayo/" + id).then((response) => {
      setRayo(response.data);
    });
  };

  return (
    <Box
      height="100vh"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding="10px"
    >
      {rayo ? (
        <Paper elevation={3} className={classes.paper}>
          <img
            src={rayo.image_rayo}
            className={classes.img}
            alt={rayo.name_rayo}
          />
          <Typography variant="h5" align="center">
            {rayo.name_rayo}
          </Typography>
          <Typography variant="body2" align="center">
            {rayo.email_rayo}
          </Typography>

          <Box display="inline-flex" marginTop={3} width="100%">
            <Box width="140px">
              <Typography variant="body2" className={classes.bold}>
                Estado
              </Typography>
            </Box>
            <Box width="calc(100% - 140px)">
              <Typography variant="body2">Vigente</Typography>
            </Box>
          </Box>

          <Box display="inline-flex" width="100%">
            <Box width="140px">
              <Typography variant="body2" className={classes.bold}>
                DNI
              </Typography>
            </Box>
            <Box width="calc(100% - 140px)">
              <Typography variant="body2">{rayo.dni_rayo}</Typography>
            </Box>
          </Box>

          <Box display="inline-flex" width="100%">
            <Box width="140px">
              <Typography variant="body2" className={classes.bold}>
                Nacionalidad
              </Typography>
            </Box>
            <Box width="calc(100% - 140px)">
              <Typography variant="body2">{rayo.nationality_rayo}</Typography>
            </Box>
          </Box>

          <Box display="inline-flex" width="100%">
            <Box width="140px">
              <Typography variant="body2" className={classes.bold}>
                Dirección
              </Typography>
            </Box>
            <Box width="calc(100% - 140px)">
              <Typography variant="body2">
                {rayo.coordinateDirection}
              </Typography>
            </Box>
          </Box>

          <img src={IconoRayo} className={classes.logo} alt="Logo rayo" />
        </Paper>
      ) : (
        false
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 500,
    width: "100%",
    padding: 30,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  img: {
    height: 100,
    width: 100,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 50,
    marginBottom: 20,
  },
  logo: {
    width: 100,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 30,
  },
  text: {
    marginLeft: "0.5rem !important",
    lineHeight: "1.6 !important",
  },
  bold: {
    fontFamily: "Roboto-Bold",
  },
}));

export default PerfilRayo;
