import url from '../../config';
import axios from 'axios';

export const ERROR_TIPO_ACTIVIDADES = 'ERROR_TIPO_ACTIVIDADES';
export const RES_ERROR = "RES_ERROR";
export const error = () => {
  return async (dispatch) => {
    dispatch({ type: RES_ERROR });
  };
};

export const OBTENER_TIPO_ACTIVIDADES = 'OBTENER_TIPO_ACTIVIDADES';
export const obtenerTipoActividades = () => {
    return async (dispatch) => {
        try {
            let usuario = JSON.parse(sessionStorage.getItem("user"));
            await axios({
                method: 'get',
                url: url.host + '/Type-Activities/type_activities/',
                headers: { tenant: usuario.tenant },
            }).then((response) => {
                switch (response.status) {
                    case 200:
                        dispatch({
                            type: OBTENER_TIPO_ACTIVIDADES,
                            tipoActividades: response.data,
                        });
                        break;

                    default:
                        dispatch({
                            type: ERROR_TIPO_ACTIVIDADES,
                            error: 'Error ' + response.status + ' al realizar la petición obtener los tipos de actividades',
                        });
                        break;
                }
            }).catch((e)=>{
                dispatch({
                    type: ERROR_TIPO_ACTIVIDADES,
                    error: 'Error al realizar la petición obtener los tipos de actividades',
                });
            })
        } catch (e) {
            dispatch({
                type: ERROR_TIPO_ACTIVIDADES,
                error: 'Error al realizar la petición obtener los tipos de actividades',
            });
        }
    };
};