import React from "react";
import $ from "jquery";
import moment from "moment";
import DayPicker from "react-day-picker";

import { MenuItem, Select, FormControl, Checkbox } from "@material-ui/core";
import { Radio } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Slider from "@material-ui/core/Slider";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import IconoDocumento from "../../icons/icono-document-download-blue.svg";
import IconoHome from "../../icons/icono-home-white.svg";
import IconoArrowBottom from "../../icons/icono-down-white.svg";
import IconoCalendar from "../../icons/icono-calendar-white.png";
import CustomExpandMore from "../custom/CustomExpandMore";
import { WEEKDAYS_LONG, WEEKDAYS_SHORT, MONTHS } from "../../utils/Helpers";

class DescargarCSV extends React.Component {
  valuetext = (value) => {
    let num = "";
    if (value < 10) {
      num = "0";
    }
    return `${num}${value}:00`;
  };

  render() {
    const { from, to } = this.props;
    const modifiers = { start: from, end: to };

    const StringTiempo = () => {
      return this.props.selectedDaysOrder.length === 0 ? (
        <img width="16" src={IconoCalendar} alt="Icono calendario" />
      ) : this.props.selectedDaysOrder.length === 1 ? (
        moment(this.props.selectedDaysOrder[0]).format("DD-MM-YYYY") +
        " " +
        this.props.selectedHours[0] +
        ":00" +
        " - " +
        moment(this.props.selectedDaysOrder[0]).format("DD-MM-YYYY") +
        " " +
        this.props.selectedHours[1] +
        ":00"
      ) : (
        this.props.selectedDaysOrder.map((day, i) => {
          if (i === 1) {
            return (
              " - " +
              moment(day).format("DD-MM-YYYY") +
              " " +
              this.props.selectedHours[i] +
              ":00"
            );
          } else {
            return (
              moment(day).format("DD-MM-YYYY") +
              " " +
              this.props.selectedHours[i] +
              ":00"
            );
          }
        })
      );
    };

    return (
      <div
        className="modal-dialog modal-dialog-centered modal-lg modal-csv"
        role="document"
      >
        <div className="modal-content modal-white">
          <div className="modal-header">
            <img src={IconoDocumento} height="37" alt="Logo Rayo" />
            <h4
              className="modal-title title-text-blue ml-3"
              id="editarModalLabel"
            >
              Descargue los datos de su tarea en un archivo CSV
            </h4>
          </div>
          <div className="modal-body pt-0">
            <div className="w-100 d-flex justify-content-center">
              <RadioGroup
                aria-label="gender"
                row
                name="csvCantidadTareas"
                value={this.props.cantidadTareas}
                onChange={(e) => this.props.handleChange(e)}
              >
                <FormControlLabel
                  value="todas"
                  control={<Radio color="primary" />}
                  label="Tareas filtradas"
                />
                <FormControlLabel
                  value="hoy"
                  control={<Radio color="primary" />}
                  label="Tareas de hoy"
                />
                <FormControlLabel
                  disabled={this.props.disabledPersonalizado ? false : true}
                  value="personalizadas"
                  control={<Radio color="primary" />}
                  label="Selección personalizada"
                />
              </RadioGroup>
            </div>
            <div className="w-100 d-flex justify-content-center">
              <FormControlLabel
                control={
                  <Checkbox
                    name="csvIncluirOrder"
                    color="primary"
                    value={this.props.incluirOrder}
                    onChange={(e) =>
                      this.props.handleState(e.target.name, e.target.checked)
                    }
                  />
                }
                label="Incluir Order ID asociados"
              />
            </div>

            {this.props.cantidadTareas === "todas" ? (
              <Box>
                <strong className="mb-2">Filtros</strong>
                <Grid container>
                  <Grid item={true} sm={6} className="mb-2 pr-2">
                    <p className="mb-1">Seleccione rango de fechas</p>
                    <Button
                      className="border-blue-btn mr-0"
                      style={{ width: "calc(100% - 36px)" }}
                      onClick={(e) => {
                        const clickX = 408;
                        this.props.handleState(
                          "openCalendar",
                          !this.props.openCalendar
                        );
                        this.props.handleState("positionCalendar", {
                          right: clickX,
                          top: 176,
                        });
                      }}
                      aria-haspopup="true"
                      aria-expanded="false"
                      aria-controls="fade-menu"
                    >
                      <StringTiempo />
                    </Button>
                    <div
                      className={
                        this.props.openCalendar
                          ? "dropdown-menu p-0 calendario-drop show show-drop"
                          : "dropdown-menu p-0 calendario-drop"
                      }
                      style={this.props.positionCalendar}
                      ref={(ref) => {
                        this.calendar = ref;
                      }}
                    >
                      <div className="container-calendar pb-3">
                        <div className="container-horas row m-0">
                          <div className="col-2 p-0 d-flex justify-content-center align-items-center">
                            <div className="bg-primary-light imagen-titulo  d-flex justify-content-center align-items-center">
                              <img width="23" src={IconoHome} alt="icono" />
                            </div>
                          </div>
                          <div className="col-10 p-0 pl-2 d-flex align-items-center">
                            Intervalo de tiempo
                          </div>
                        </div>
                        <p>
                          {this.props.selectedHours.map((hora, i) => {
                            if (i === 1) {
                              return " - " + hora + ":00";
                            } else {
                              return hora + ":00";
                            }
                          })}
                        </p>
                        <div className="w-100 px-3">
                          <Slider
                            value={this.props.selectedHours}
                            onChange={this.props.handleHoursClick}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            getAriaValueText={this.valuetext}
                            valueLabelFormat={this.valuetext}
                            min={0}
                            step={1}
                            max={24}
                          />
                        </div>
                        <DayPicker
                          locale="es-CL"
                          firstDayOfWeek={1}
                          months={MONTHS}
                          weekdaysLong={WEEKDAYS_LONG}
                          weekdaysShort={WEEKDAYS_SHORT}
                          className="Selectable"
                          numberOfMonths={1}
                          selectedDays={[from, { from, to }]}
                          modifiers={modifiers}
                          onDayClick={this.props.handleDayClick}
                        />
                      </div>
                    </div>
                    <Button
                      className="back-dark-grey btn-right-calendar btn-drop-icon border-blue-icon"
                      style={{ width: "36px" }}
                      onClick={(e) => {
                        const clickX = 408;
                        this.props.handleState(
                          "openCalendar",
                          !this.props.openCalendar
                        );
                        this.props.handleState("positionCalendar", {
                          right: clickX,
                          top: 176,
                        });
                      }}
                      // data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      aria-controls="fade-menu"
                      id="button-calendar"
                    >
                      <img
                        className="icon-arrow"
                        src={IconoArrowBottom}
                        alt="Icono bajar"
                      />
                    </Button>
                  </Grid>
                  {this.props.vista === "dispatcher" ? (
                    <>
                      <Grid item sm={6} className="mb-2 pl-2">
                        <p className="mb-1">Seleccione Cliente</p>
                        <FormControl variant="filled" className="w-100">
                          <Select
                            className="select-crear select-modal color-mega-dark-grey  btn-csv"
                            labelId="demo-simple-select-filled-label"
                            id="cliente-simple-select-filled"
                            IconComponent={CustomExpandMore}
                            name="csvCliente"
                            onChange={(e) => this.props.handleChange(e)}
                            value={this.props.csvCliente}
                          >
                            <MenuItem value="0">
                              <em>Todos los Clientes</em>
                            </MenuItem>
                            {this.props.clientes
                              ? this.props.clientes.map((cliente, i) => (
                                  <MenuItem value={cliente._id} key={i}>
                                    {cliente.name_customer}
                                  </MenuItem>
                                ))
                              : false}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item sm={6} className="mb-2 pr-2"></Grid>
                      <Grid item sm={6} className="mb-2 pl-2">
                        <p className="mb-1">Seleccione Rayo</p>
                        <FormControl variant="filled" className="w-100">
                          <Select
                            className="select-crear select-modal color-mega-dark-grey  btn-csv"
                            labelId="demo-simple-select-filled-label"
                            id="rayo-simple-select-filled"
                            IconComponent={CustomExpandMore}
                            name="csvRayo"
                            onChange={(e) => this.props.handleChange(e)}
                            value={this.props.csvRayo}
                          >
                            <MenuItem value="0">
                              <em>Todos los Rayos</em>
                            </MenuItem>
                            {this.props.rayos
                              ? this.props.rayos.map((rayo, i) => (
                                  <MenuItem value={rayo._id} key={i}>
                                    {rayo.name_rayo}
                                  </MenuItem>
                                ))
                              : false}
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  ) : (
                    false
                  )}
                </Grid>
              </Box>
            ) : (
              false
            )}
            <div className="w-100 mt-3">
              <strong className="mb-1">Plantilla</strong>
              <p className="mb-1">
                Elija la plantilla de Tarea que desea ver en CSV.
              </p>
              <FormControl variant="filled" className="w-100">
                <Select
                  className="select-crear select-modal color-mega-dark-grey"
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  name="rayo_selected"
                  value="0"
                  IconComponent={CustomExpandMore}
                >
                  <MenuItem value="0">
                    <em>
                      Por el momento no se encuentran plantillas disponibles
                    </em>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="modal-footer row m-0">
            <div className="col-4 offset-4 d-flex justify-content-end">
              <button
                type="button"
                className="btn-secondary"
                onClick={() => $("#modalDescarga").modal("hide")}
              >
                Cancelar
              </button>
            </div>
            <div className="col-4 d-flex justify-content-end">
              <button
                type="button"
                className="btn-primary-rayo"
                onClick={() => this.props.descargar()}
              >
                Descargar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DescargarCSV;
