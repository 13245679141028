import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

const CantidadSeleccionados = ({ seleccionado, totalSeleccionado, cleanSelectedGT }) => {
    return seleccionado ? (
        <div className="w-100 m-0 back-light-grey-2 p-0">
            <div className="row m-0">
                <div className="col-3 p-0 d-flex align-items-center justify-content-center">
                    <Checkbox
                        value="checkedA"
                        color="primary"
                        checked={seleccionado}
                        onClick={() => cleanSelectedGT()}
                    />
                </div>
                <div className="col-9 p-0 d-flex align-items-center">
                    <p className="text-roboto-regular color-mega-dark-grey w-100 mb-0">
                        {'Se han seleccionado ' + totalSeleccionado + ' tareas'}
                    </p>
                </div>
            </div>
        </div>
    ) : (
        <div className="w-100 m-0 back-light-grey-2 p-25">
            <p className="text-roboto-regular color-light-grey w-100 text-center  mb-0">
                No se han seleccionado grupos de tareas
            </p>
        </div>
    );
};

export default CantidadSeleccionados;
