import React from "react";

import { THEME_METADATA } from "../../utils/Helpers";

import cloneDeep from "lodash/cloneDeep";
import JSONTree from "react-json-tree";

const getItemString = (type) => (
  <span style={{ color: "#bfbfbf" }}>{type}</span>
);

const MetadataJson = ({ metadata }) => {
  let arreglo = cloneDeep(metadata);
  delete arreglo.template_data;

  return (
    <JSONTree
      data={arreglo}
      theme={THEME_METADATA}
      invertTheme={false}
      hideRoot
      getItemString={getItemString}
      labelRenderer={([raw]) => <strong>{raw}:</strong>}
      shouldExpandNode={() => false}
    />
  );
};

export default MetadataJson;
