import React from "react";
import axios from "axios";
import $ from "jquery";
import moment from "moment";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import { geocodeByPlaceId, getLatLng } from "react-places-autocomplete";
import Tooltip from "@material-ui/core/Tooltip";
import "../../../css/home.css";
import url from "../../../config";
import Utils from "../../utils/Utils";
import {
  ID_NO_ASIGNADO,
  ID_ASIGNADO,
  ID_ACEPTADO,
  ID_INICIADO,
  ID_DEVUELTO,
} from "../../utils/Helpers";
import {
  ID_EN_PROGRESO,
  ID_EXITOSO,
  ID_FALLIDO,
  ID_RECHAZADO,
  ID_CANCELADO,
} from "../../utils/Helpers";

import MapaGoogle from "../mapas/MapaGoogle";
import CustomExpandMore from "../../components/custom/CustomExpandMore";
import FormularioTareas from "./FormularioTareas";
import * as templatesAccion from "../../../store/actions/Templates";
import * as actionClientes from "../../../store/actions/Clientes";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Cargando from "../cargando/Cargando";
import AsignarRayoColumn from "../home/AsignarRayoColumn";
import IngresarTemplate from "../../components/home/IngresarTemplate";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

import IconoTareas from "../../icons/icono-document-ready-black.svg";
import IconoFlecha from "../../icons/icono-down-white.svg";
import IconoFlechaBlue from "../../icons/icono-down-blue.svg";
import IconoPlus from "../../icons/icono-plus-black.svg";
import IconoRayo from "../../icons/icono-rayo-white.svg";
import IconoRayoBlack from "../../icons/icono-rayo-black.svg";
import IconoCliente from "../../icons/icono-user-tie-white.svg";

class CrearTarea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: null,
      formulario: true,
      order_id_taskgroup: "",
      form: {
        type_task: 1,
        buscarRayo1: "",
        buscarRayo2: "",
        rayoAsignado: "",
        cliente: 0,
      },

      rayo: null,
      rayos: [],
      alltemplates: [],
      tareas: [],
      task: null,
      tasksGroup: null,
      clientes: [],
      expanded: false,
      localizaciones: [],
      cargando: false,

      tareaCerrada: false,

      accion: this.props.data.match.params.accion,
      tipo: this.props.data.match.params.tipo,

      ingresarEvidencia: false,
      idTemplateSuccessful: null,
      idTemplateFailed: null,
      optionsDropdownsuccessful: [],
      optionsDropdownfailed: [],
      templates: {
        successful: null,
        failed: null,
      },
    };
  }

  componentDidMount = async () => {
    await this.handleCargando(true);
    let usuario = JSON.parse(sessionStorage.getItem("user"));
    this.setState({ usuario: usuario });
    this.getTemplates();
    this.getCustumers();
    const { dispatch } = this.props;
    let obtenerTemplates = templatesAccion.obtenerTemplates();

    try {
      dispatch(obtenerTemplates);
    } catch (err) {
      Utils.handleAlert(
        "Obtener data",
        "No se han podido cargar todos los elementos de la página",
        "Ok"
      );
    }

    this.getRayos();
    if (this.state.accion === "Editar") {
      await this.getTaskById(this.props.data.match.params.tipo);
    } else {
      if (!this.state.tipo) {
        // CREACION SEGÚN CLIENTE
        let filtrosGlobales = JSON.parse(sessionStorage.getItem("filtros"));
        if (filtrosGlobales) {
          filtrosGlobales[1].forEach((filtro, i) => {
            if (filtro.data !== null) {
              if (filtro.filter === "idCustomer") {
                let clientes = filtro.data.split(",");
                if (clientes.length === 1) {
                  this.getCustomerById(clientes[0]);
                }
              }
            }
          });
        }
      } else if (this.state.tipo !== "0") {
        await this.getTasksGroupById(this.props.data.match.params.tipo);
      }
    }

    await this.handleCargando(false);

    //funcionalidad para que comienze con un pickup y un delivery
    //y para setiar fecha de incio a la fecha actual
    let fecha = moment().add(1, "minute").format("YYYY-MM-DDTHH:mm");
    let fechaMasHora = moment().add(1, "hour").format("YYYY-MM-DDTHH:mm");

    this.agregarTareaArray(1, fecha);
    this.agregarTareaArray(2, fechaMasHora);
  };

  handleCargando(value) {
    this.setState({ cargando: value });
  }

  getCustomerById = async (id) => {
    try {
      let usuario = JSON.parse(sessionStorage.getItem("user"));
      let data = await axios({
        method: "get",
        url: url.host + "/Customers/customers/" + id,
        headers: { tenant: usuario.tenant },
      }).then(({ data }) => data);
      this.setState({
        form: {
          ...this.state.form,
          cliente: data._id,
        },
      });
    } catch (err) {}
  };

  getTemplates = async () => {
    try {
      let clientes = Utils.getCustomers();
      let data = await axios({
        method: "get",
        url: url.host + "/Templates/templates" + clientes,
        headers: { tenant: this.state.usuario.tenant },
      }).then(({ data }) => data);
      data.forEach((element, i) => {
        element.type_activity_template.forEach((e) => {
          e.name_type_activity = "";
        });
      });
      await this.setState({ alltemplates: data }, async () => {
        if (this.state.tareas && this.state.tareas.length !== 0) {
          if (this.state.alltemplates.length !== 0) {
            const f = this.state.alltemplates.findIndex(
              (template) =>
                template._id ===
                this.state.tareas[0].template_failed_task.idTemplate
            );

            const s = this.state.alltemplates.findIndex(
              (template) =>
                template._id ===
                this.state.tareas[0].template_successful_task.idTemplate
            );

            let failed = [];
            let failedDrop = [];

            let successful = [];
            let successfulDrop = [];

            if (f >= 0) {
              failed = Utils.createObjectActivities(
                this.state.alltemplates[f].type_activity_template
              );
              failedDrop = this.state.alltemplates[f].dropdown_options;
            }

            if (s >= 0) {
              successful = Utils.createObjectActivities(
                this.state.alltemplates[s].type_activity_template
              );
              successfulDrop = this.state.alltemplates[s].dropdown_options;
            }

            this.setState({
              optionsDropdownfailed: failedDrop,
              optionsDropdownsuccessful: successfulDrop,
              templates: {
                failed: failed,
                successful: successful,
              },
            });
          }
        }
      });
    } catch (err) {
      Utils.handleAlert(
        "Obtener templates",
        "No se han podido cargar los templates",
        "Ok"
      );
    }
  };

  getRayos = async () => {
    try {
      let clientes = Utils.getCustomers();
      let vigencia = clientes === "" ? "?vigencia=true" : "&vigencia=true";
      let usuario = JSON.parse(sessionStorage.getItem("user"));
      let data = await axios({
        method: "get",
        url: url.host + "/Rayos/rayosBy/reduced" + clientes + vigencia,
        headers: { tenant: usuario.tenant },
      }).then(({ data }) => data);
      data.forEach((element) => {
        element.selected = false;
      });

      await this.setState({ rayos: data }, () => {
        if (this.state.accion === "Editar" && this.state.tareas) {
          if (this.state.tareas.length !== 0) {
            this.handleCheck(this.state.tareas[0].idrayo_task);
          }
        }
      });
    } catch (err) {
      Utils.handleAlert(
        "Obtener data",
        "No se han podido cargar todos los elementos de la página",
        "Ok"
      );
    }
  };

  getCustumers = async () => {
    const { dispatch } = this.props;
    let obtenerClientes = actionClientes.obtenerClientes();

    try {
      dispatch(obtenerClientes);
    } catch (err) {}
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  agregarTareaArray = async (tipo, fechaSugerida) => {
    let array = cloneDeep(this.state.tareas);
    let obj = {
      order_id_task: "", // ORDEN ID
      type_task: tipo,
      description_task: "", // DESCRIPCION DE LA TAREA
      date_task:
        fechaSugerida == null || fechaSugerida === "" ? "" : fechaSugerida, // INICIO PROGRAMADO
      end_date_task: null, // FIN PROGRAMADO
      address_task: "", // DIRECCION
      latitude_task: "",
      longitude_task: "",
      contact_name_task: "", // NOMBRE DE CONTACTO
      contact_phone_task: "", // NUMERO DE CONTACTO
      idstatus_task: "",
      metadata_task: {},
      id_previous_task: "",
      id_next_task: "",
      template_successful_task: {
        idTemplate: "", // TEMPLATE SUCCESS
      },
      template_failed_task: {
        idTemplate: "", // TEMPLATE FAIL
      },
      idTasksGroup_task: "",
      orderNumber_task: "",
      idrayo_task: "",
      tags: "",
      address2: "",
      contact_email: "",
      idcountry_task: "5ef0e34ef49d6f00a4737b72",
    };

    if (this.state.tipo === "0" || typeof this.state.tipo === "undefined") {
      array.push(obj);
    } else {
      if (array.length === 0) {
        array = [obj];
      }
    }

    await this.setState({ tareas: array });
  };

  eliminarTareaArray = (i) => {
    let array = cloneDeep(this.state.tareas);
    array.splice(i, 1);
    this.setState(
      {
        tareas: array,
      },
      () => {
        this.cargarCoordenadas();
      }
    );
  };

  agregarDelivery = (tarea) => {
    let array = cloneDeep(this.state.tareas);
    let obj = {
      order_id_task: tarea.order_id_task, // ORDEN ID
      type_task: 2,
      description_task: "", // DESCRIPCION DE LA TAREA
      date_task: "", // INICIO PROGRAMADO
      end_date_task: null, // FIN PROGRAMADO
      address_task: "", // DIRECCION
      latitude_task: "",
      longitude_task: "",
      contact_name_task: "", // NOMBRE DE CONTACTO
      contact_phone_task: "", // NUMERO DE CONTACTO
      idstatus_task: "",
      metadata_task: {},
      id_previous_task: "",
      id_next_task: "",
      template_successful_task: {
        idTemplate: "", // TEMPLATE SUCCESS
      },
      template_failed_task: {
        idTemplate: "", // TEMPLATE FAIL
      },
      idTasksGroup_task: "",
      orderNumber_task: "",
      idrayo_task: "",
      tags: "",
      address2: "",
      contact_email: "",
      idcountry_task: "5ef0e34ef49d6f00a4737b72",
    };

    array.push(obj);
    this.setState({ tareas: array });
  };

  handleFormulario = (value) => {
    this.setState({ formulario: value });
  };

  handlePhone = (e, i) => {
    let name = "contact_phone_task";
    let array = this.state.tareas.slice();
    array[i][name] = "+ " + e.replace(/[^0-9.]/g, "");
    this.setState({ tareas: array });
  };

  handleForm = (e, i) => {
    const { name, value } = e.target;
    let array = this.state.tareas.slice();
    if (name === "contact_phone_task") {
      array[i][name] = value.replace(/[^0-9.]/g, "");
    } else if (name === "date_task") {
      let hoy = new Date(moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ"));
      let seleccionada = new Date(moment(value).format("YYYY-MM-DDTHH:mm:ssZ"));

      if (hoy > seleccionada) {
        array[i][name] = "";
        let x = document.getElementsByName("date_task");
        $(x).hide();
        Utils.handleAlert(
          "Fecha invalida",
          "Debe ingresar una fecha posterior a la actual, intente nuevamente",
          "Ok"
        );
        $(x).show();
      } else {
        array[i][name] = value;
      }
    } else {
      array[i][name] = value;
    }
    this.setState({ tareas: array });
  };

  handleFormSelect = (e, i, type) => {
    e.preventDefault();
    const { name, value } = e.target;
    let array = this.state.tareas.slice();
    array[i][name].idTemplate = value;
    this.setState({ tareas: array });
    if (this.state.accion === "Editar") this.setTemplateData(type, value);
  };

  handleCheck = (id) => {
    let rayo = null;
    let rayos = cloneDeep(this.state.rayos);
    rayos.forEach((element) => {
      if (element._id === id) {
        if (element.selected === true) {
          rayo = null;
          element.selected = false;
        } else {
          rayo = element;
          element.selected = true;
        }
      } else {
        element.selected = false;
      }
    });

    this.setState({
      rayo: rayo,
      rayos: rayos,
    });
  };

  handleAddress = (e, i) => {
    let array = this.state.tareas.slice();
    array[i].address_task = e;
    if (e === "") this.handleAddressSelect("", i);
    this.setState({ tareas: array });
  };

  handleAddressSelect = (e, i) => {
    let array = this.state.tareas.slice();
    if (e === null || typeof e === undefined || e === "") {
      array[i].latitude_task = "";
      array[i].longitude_task = "";
      this.setState({ tareas: array }, () => {
        this.cargarCoordenadas();
      });
    } else {
      let value = e.placeId;
      geocodeByPlaceId(value)
        .then((results) => {
          array[i].address_task = results[0].formatted_address;
          return results;
        })
        .then((results) => getLatLng(results[0]))
        .then((latLng) => {
          array[i].latitude_task = latLng.lat;
          array[i].longitude_task = latLng.lng;
          this.setState({ tareas: array }, () => {
            this.cargarCoordenadas();
          });
        })
        .catch((error) => {
          Utils.handleAlert(
            "Obtener ubicación",
            "Ha ocurrido un error al obtener datos de ubicación seleccionada, vuelva a intentar",
            "Ok"
          );
        });
    }
  };

  handleAlert = (title, text, confirmButtonText) => {
    Swal.fire({
      title: title,
      text: text,
      imageUrl:
        "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayoapp-red.svg",
      confirmButtonText: confirmButtonText,
      customClass: {
        image: "imageSweetAlert",
        title: "titleSweetAlert",
        content: "textSweetAlert",
        confirmButton: "buttonSweetAlert",
      },
    }).then((result) => {
      // window.location.href = "/Home/Mapa";
      this.props.data.history.goBack();
    });
  };

  getTasksGroupById = async (id) => {
    try {
      let data = await axios({
        method: "get",
        url: url.host + "/Task-Groups/tasks_group/" + id + "/",
        headers: { tenant: this.state.usuario.tenant },
      }).then(({ data }) => data);
      this.setState({
        tasksGroup: data,
        form: {
          ...this.state.form,
          cliente: data.idcustomer_taskgroup,
        },
        order_id_taskgroup: data.orden_id_taskgroup,
      });
    } catch (err) {
      Utils.handleAlert(
        "Obtener grupo de tareas",
        "No se ha podido cargar el grupo de tareas",
        "Ok"
      );
    }
  };

  getTaskById = async (id) => {
    try {
      let data = await axios({
        method: "get",
        url: url.host + "/Tasks/task/" + id + "/",
        headers: { tenant: this.state.usuario.tenant },
      }).then(({ data }) => data);

      console.log(data);
      if (!data.end_date_task) {
        data.end_date_task = "";
      } else {
        data.end_date_task = data.end_date_task.split("Z")[0];
      }

      if (data.idrayo_task) {
        this.handleCheck(data.idrayo_task);
      }
      if (!data.date_task) {
        data.date_task = "";
      } else {
        data.date_task = data.date_task.split("Z")[0];
      }

      if (!data.address2) data.address2 = "";
      if (!data.description_task) data.description_task = "";

      if (data.idcustomer_task != null) {
        await this.setState({
          form: {
            ...this.state.form,
            cliente: data.idcustomer_task,
          },
          order_id_taskgroup: data.order_id_task,
        });
      }

      if (!data.tags) {
        data.tags = "";
      }

      if (!data.address2) {
        data.address2 = "";
      }

      if (!data.contact_email) {
        data.contact_email = "";
      }

      let tareaCerrada = false;
      if (
        data.idstatus_task === ID_EXITOSO ||
        data.idstatus_task === ID_FALLIDO ||
        data.idstatus_task === ID_RECHAZADO ||
        data.idstatus_task === ID_CANCELADO
      ) {
        tareaCerrada = true;
      }

      this.setState(
        {
          tareas: [data],
          tareaCerrada: tareaCerrada,
        },
        () => {
          this.cargarCoordenadas();
        }
      );

      if (this.state.alltemplates.length !== 0) {
        const f = this.state.alltemplates.findIndex(
          (template) => template._id === data.template_failed_task.idTemplate
        );

        const s = this.state.alltemplates.findIndex(
          (template) =>
            template._id === data.template_successful_task.idTemplate
        );

        let failed = [];
        let failedDrop = [];

        let successful = [];
        let successfulDrop = [];

        if (f >= 0) {
          failed = Utils.createObjectActivities(
            this.state.alltemplates[f].type_activity_template
          );
          failedDrop = this.state.alltemplates[f].dropdown_options;
        }

        if (s >= 0) {
          successful = Utils.createObjectActivities(
            this.state.alltemplates[s].type_activity_template
          );
          successfulDrop = this.state.alltemplates[s].dropdown_options;
        }

        this.setState({
          optionsDropdownfailed: failedDrop,
          optionsDropdownsuccessful: successfulDrop,
          templates: {
            failed: failed,
            successful: successful,
          },
        });
      }
    } catch (err) {
      Utils.handleAlert(
        "Obtener tarea",
        "No se ha podido cargar la tarea",
        "Ok"
      );
    }
  };

  setTemplateData = async (type, id) => {
    if (this.state.alltemplates.length !== 0) {
      const i = this.state.alltemplates.findIndex(
        (template) => template._id === id
      );

      if (i >= 0) {
        let template = Utils.createObjectActivities(
          this.state.alltemplates[i].type_activity_template
        );

        await this.setState({
          ["optionsDropdown" + type]:
            this.state.alltemplates[i].dropdown_options,
          templates: {
            ...this.state.templates,
            [type]: template,
          },
        });
      }
    }
  };

  createTaskGroup = async () => {
    if (this.state.tareas.length === 0) {
      Utils.handleAlert(
        "Ingresar grupo de tareas",
        "Debe ingresar al menos una tarea para ingresar un grupo de tareas, vuelva a intentarlo",
        "Ok"
      );
    } else if (this.state.form.cliente === 0) {
      Utils.handleAlert(
        "Faltan datos",
        "Debe seleccionar un cliente para ingresar un grupo de tareas, vuelva a intentarlo",
        "Ok"
      );
    } else if (this.state.order_id_taskgroup === "") {
      Utils.handleAlert(
        "Faltan datos",
        "Debe ingresar el order ID del grupo de tareas para ingresar un grupo de tareas, vuelva a intentarlo",
        "Ok"
      );
    } else {
      await this.handleCargando(true);
      try {
        let arrayTareas = cloneDeep(this.state.tareas);
        let idRayo = null;
        let idEstado = "";

        this.state.rayos.forEach((element) => {
          if (element.selected) {
            idRayo = element._id;
          }
        });

        arrayTareas.forEach((tarea) => {
          tarea.idrayo_task = idRayo;
        });

        if (idRayo === null) {
          idEstado = ID_NO_ASIGNADO;
        } else {
          idEstado = ID_ASIGNADO;
        }

        let fechas = [];

        let sinCoordenadas = [];

        arrayTareas.forEach((tarea, index) => {
          if (tarea.latitude_task === "" || tarea.longitude_task === "") {
            let num = index + 1;
            let tipo =
              tarea.type_task === 1
                ? " tipo pick up"
                : tarea.type_task === 2
                ? " tipo delivery"
                : " tipo cita";

            sinCoordenadas.push(num.toString() + " " + tipo);
          }
          tarea.order_id_task = this.state.order_id_taskgroup;
          tarea.idrayo_task = idRayo;
          tarea.idstatus_task = idEstado;
          tarea.orderNumber_task = index;
          tarea.contact_phone_task = tarea.contact_phone_task.replace(" ", "");
          let fechaStart = new Date(Date.parse(tarea.date_task));
          fechas.push(fechaStart);
          tarea.date_task =
            moment(fechaStart).format("YYYY-MM-DDTHH:mm:ss") + "Z";
          tarea.modifier_user = {
            name: this.state.usuario.name,
            email: this.state.usuario.email,
          };
        });

        if (sinCoordenadas.length !== 0) {
          await this.handleCargando(false);
          Utils.handleAlert(
            "Dirección de tareas",
            "Debe seleccionar una dirección de las brindadas en el campo, las tareas número: " +
              sinCoordenadas.map((sin) => sin + " ") +
              " no estan ingresadas correctamente, vuelva a intentarlo",
            "Ok"
          );
        } else {
          const minDate = new Date(Math.min(...fechas));
          const maxDate = new Date(Math.max(...fechas));
          const fechaHoy = new Date();

          const GrupoDeTareas = {
            orden_id_taskgroup: this.state.order_id_taskgroup,
            idstatus_taskgroup: idEstado,
            idrayo_taskgroup: idRayo,
            start_date_taskgroup:
              moment(minDate).format("YYYY-MM-DDTHH:mm:ss") + "Z",
            end_date_taskgroup:
              moment(maxDate).format("YYYY-MM-DDTHH:mm:ss") + "Z",
            tasks: arrayTareas,
            creation_date_taskgroup:
              moment(fechaHoy).format("YYYY-MM-DDTHH:mm:ss") + "Z",
            idcustomer_taskgroup: this.state.form.cliente,
            address_taskgroup: arrayTareas[0].address_task,
            latitude_taskgroup: arrayTareas[0].latitude_task,
            longitude_taskgroup: arrayTareas[0].longitude_task,
            contact_name_taskgroup: arrayTareas[0].contact_name_task,
            type_taskgroup: arrayTareas[0].type_task,
            modifier_user: {
              name: this.state.usuario.name,
              email: this.state.usuario.email,
            },
          };

          let res = await url.api.post(
            "/Task-Groups/tasks_group/",
            GrupoDeTareas,
            {headers: { tenant: this.state.usuario.tenant }}
          );
          if (res.status === 201) {
            await this.handleCargando(false);
            this.handleAlert(
              "Grupo de tareas ingresado",
              "El grupo de tareas se ha ingresado exitosamente",
              "Ok"
            );
          } else {
            await this.handleCargando(false);
            Utils.handleAlert(
              "Error " + res.status,
              "Ha ocurrido un error al ingresar el grupo de tareas, vuelva a intentarlo",
              "Ok"
            );
          }
        }
      } catch (error) {
        await this.handleCargando(false);
        Utils.handleAlert("Error", error.message, "Ok");
      }
    }
  };

  createTask = async () => {
    await this.handleCargando(true);
    let tarea = cloneDeep(this.state.tareas[0]);
    let idRayo = null;
    let idEstado = "";

    this.state.rayos.forEach((element) => {
      if (element.selected) {
        idRayo = element._id;
      }
    });

    tarea.idrayo_task = idRayo;

    if (idRayo === null) {
      idEstado = ID_NO_ASIGNADO;
    } else {
      idEstado = ID_ASIGNADO;
    }

    tarea.contact_phone_task = tarea.contact_phone_task.replace(" ", "");
    tarea.order_id_task = this.state.order_id_taskgroup;
    tarea.idrayo_task = idRayo;
    tarea.idstatus_task = idEstado;
    tarea.orderNumber_task = this.state.tasksGroup.tasks.length + 1;
    tarea.date_task =
      moment(new Date(Date.parse(tarea.date_task))).format(
        "YYYY-MM-DDTHH:mm:ss"
      ) + "Z";
    tarea.idTasksGroup_task = this.state.tipo;
    tarea.modifier_user = {
      name: this.state.usuario.name,
      email: this.state.usuario.email,
    };
    tarea.idcustomer_task = this.state.form.cliente;

    try {
      let res = await url.api.post("/Tasks/task/", tarea, {headers: {tenant: this.state.usuario.tenant}});
      if (res.status === 201) {
        await this.handleCargando(false);
        this.handleAlert(
          "Tarea ingresada",
          "La tarea se ha ingresado exitosamente",
          "Ok"
        );
      } else {
        await this.handleCargando(false);
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al ingresar la tarea, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      await this.handleCargando(false);
      Utils.handleAlert("Error", error.message, "Ok");
    }
  };

  modifyTask = async () => {
    let usuario = JSON.parse(sessionStorage.getItem('user'));
    await this.handleCargando(true);
    let tarea = cloneDeep(this.state.tareas[0]);
    let idRayo = null;

    this.state.rayos.forEach((element) => {
      if (element.selected) {
        idRayo = element._id;
      }
    });

    if (
      (tarea.idstatus_task === "5f05f3d37f5164494c74b967" ||
        tarea.idstatus_task === "5f05f3dc7f5164494c74b968" ||
        tarea.idstatus_task === "5f05f3dc7f5164494c74b968" ||
        tarea.idstatus_task === "5f05f3eb7f5164494c74b969" ||
        tarea.idstatus_task === "5f05f3f77f5164494c74b96a" ||
        tarea.idstatus_task === "5f05f4077f5164494c74b96b" ||
        tarea.idstatus_task === "5f05f4147f5164494c74b96c" ||
        tarea.idstatus_task === "5f05f4217f5164494c74b96d" ||
        // tarea.idstatus_task === "60bfdb431b02612234560b16" ||
        tarea.idstatus_task === "5f05f42e7f5164494c74b96e") &&
      idRayo === null
    ) {
      await this.handleCargando(false);
      Utils.handleAlert(
        "Error de cambio de estado",
        "El estado al cual esta intentando editar la tarea necesita un Rayo, seleccione uno y vuelva a intentar",
        "Ok"
      );
    } else {
      tarea.idrayo_task = idRayo;
      tarea.date_task =
        moment(new Date(Date.parse(tarea.date_task))).format(
          "YYYY-MM-DDTHH:mm:ss"
        ) + "Z";

      if (tarea.end_date_task === "") {
        tarea.end_date_task = null;
      } else {
        tarea.end_date_task =
          moment(new Date(Date.parse(tarea.end_date_task))).format(
            "YYYY-MM-DDTHH:mm:ss"
          ) + "Z";
      }
      tarea.key = tarea._id;

      if (tarea.metadata_task === null) {
        tarea.metadata_task = {};
      }

      delete tarea.__v;
      delete tarea._id;
      delete tarea.id;
      delete tarea.code_task;
      tarea.modifier_user = {
        name: this.state.usuario.name,
        email: this.state.usuario.email,
      };

      try {
        if (this.state.ingresarEvidencia) {
          let data = {
            result: "",
            template_data: [],
            modifier_user: {
              name: this.state.usuario.name,
              email: this.state.usuario.email,
            },
          };

          if (tarea.idstatus_task === ID_EXITOSO) {
            data.result = "Exitoso";
            data.template_data = Utils.templateData(
              this.state.templates.successful
            );
          } else if (tarea.idstatus_task === ID_FALLIDO) {
            data.result = "Fallido";
            data.template_data = Utils.templateData(
              this.state.templates.failed
            );
          }

          await url.api
            .post("/Tasks/tasks/" + tarea.key + "/templateData", data, {headers: {tenant: usuario.tenant}})
            .then(async (res) => {
              // await url.api
              //   .put("/Tasks/task/", tarea)
              //   .then(async (res) => {
              await this.handleCargando(false);
              this.handleAlert(
                "Tarea modificada",
                "La tarea se ha modificado e ingresado evidencia exitosamente",
                "Ok"
              );
              // })
              // .catch((error) => {
              //   if (error.response) {
              //     let title = "Error en acción " + error.response.status;
              //     if (error.response.status === 412) {
              //       title = "Precondición fallida";
              //     }
              //     this.handleCargando(false);
              //     Utils.handleAlert(
              //       title,
              //       "Esta tarea se encuentra en un estado cerrado, por lo que no es posible modificarla",
              //       "Ok"
              //     );
              //   } else {
              //     this.handleCargando(false);
              //     Utils.handleAlert(
              //       "Error al realizar la peticion",
              //       error.message,
              //       "Ok"
              //     );
              //   }
              // });
            })
            .catch(() => {
              Utils.handleAlert(
                "Error",
                "La tarea a sido modificada, pero a ocurrido un error al ingresar las evidencias",
                "Ok"
              );
            });
        } else {
          await url.api
            .put("/Tasks/task/", tarea, {
              headers: {tenant: this.state.usuario.tenant}
            })
            .then(async (res) => {
              await this.handleCargando(false);
              this.handleAlert(
                "Tarea modificada",
                "La tarea se ha modificado exitosamente",
                "Ok"
              );
            })
            .catch((error) => {
              if (error.response) {
                let title = "Error en acción " + error.response.status;
                if (error.response.status === 412) {
                  title = "Precondición fallida";
                }
                this.handleCargando(false);
                Utils.handleAlert(
                  title,
                  "Esta tarea se encuentra en un estado cerrado, por lo que no es posible modificarla",
                  "Ok"
                );
              } else {
                this.handleCargando(false);
                Utils.handleAlert(
                  "Error al realizar la peticion",
                  error.message,
                  "Ok"
                );
              }
            });
        }

        // await url.api
        // .post("/Tasks/tasks/" + tarea._id + "/templateData", data)
        // .then((res) => {})
        // .catch(() => {});
      } catch (error) {
        console.log(error);
        await this.handleCargando(false);
        Utils.handleAlert(
          "Error",
          "Faltan campos para modificar un grupo de tareas, vuelva a intentarlo",
          "Ok"
        );
      }
    }
  };

  cargarCoordenadas = async () => {
    let data = this.state.tareas;
    let arreglo = Utils.localizacionesT([{ _id: "", tasks: data }]);
    await this.setState({
      localizaciones: arreglo,
    });
  };

  confirmLeave = (key) => {
    Swal.fire({
      title: "¿Desea salir del formulario?",
      text: "Si confirma, perderá la información ingresada permanentemente",
      imageUrl:
        "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayoapp-red.svg",
      showCancelButton: true,
      customClass: {
        image: "imageSweetAlert",
        title: "titleSweetAlert",
        content: "textSweetAlert",
        confirmButton: "buttonSweetAlert",
        cancelButton: "btn-secondary",
      },
      confirmButtonText: "Si, salir",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.props.data.history.goBack();
      }
    });
  };

  handleModal = (status) => {
    this.setState({ modal: status });
  };

  handleChangeEvidencia = (e, id, type) => {
    e.preventDefault();
    const { value } = e.target;
    let actividades = this.state.templates[type];
    const i = actividades.findIndex((act) => act._id === id);
    if (i >= 0) {
      actividades[i].data = value;
      this.setState({
        evidencia: {
          ...this.state.evidencia,
          [type]: actividades,
        },
      });
    }
  };

  handleChangeEvidenciaImg = (e, id, type) => {
    e.preventDefault();
    const { files } = e.target;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      let actividades = this.state.templates[type];
      const i = actividades.findIndex((act) => act._id === id);
      if (i >= 0) {
        actividades[i].data = reader.result;
        this.setState({
          evidencia: {
            ...this.state.evidencia,
            [type]: actividades,
          },
        });
      }
    };
  };

  handleChangeCheck = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const PickUp = () => {
      return (
        <div className="w-100">
          <div className="row m-0 back-light-grey-2 px-2 py-0 mb-0 border-bottom">
            <div className="col-1">
              <IconButton
                aria-label="agregar"
                onClick={() => this.agregarTareaArray(1)}
              >
                <img src={IconoPlus} width="15" alt="plus" />
              </IconButton>
            </div>
            <div className="col-11 d-flex align-items-center">
              <Typography className="text-roboto-regular mb-0">
                PickUp
              </Typography>
            </div>
          </div>
          <div className="row m-0 back-light-grey-2 px-2 py-0 mb-0 border-bottom">
            <div className="col-1">
              <IconButton
                aria-label="agregar"
                onClick={() => this.agregarTareaArray(2)}
              >
                <img src={IconoPlus} width="15" alt="plus" />
              </IconButton>
            </div>
            <div className="col-11 d-flex align-items-center">
              <Typography className="text-roboto-regular mb-0">
                Delivery
              </Typography>
            </div>
          </div>
        </div>
      );
    };

    const Cita = () => {
      return (
        <div className="w-100">
          <div className="row m-0 back-light-grey-2 px-2 py-0 mb-0 border-bottom">
            <div className="col-1">
              <IconButton
                aria-label="agregar"
                onClick={() => this.agregarTareaArray(3)}
              >
                <img src={IconoPlus} width="15" alt="plus" />
              </IconButton>
            </div>
            <div className="col-11 d-flex align-items-center">
              <Typography className="text-roboto-regular mb-0">Cita</Typography>
            </div>
          </div>
        </div>
      );
    };

    const Devolucion = () => {
      return (
        <div className="w-100">
          <div className="row m-0 back-light-grey-2 px-2 py-0 mb-2">
            <div className="col-1">
              <IconButton
                aria-label="agregar"
                onClick={() => this.agregarTareaArray(4)}
              >
                <img src={IconoPlus} width="15" alt="plus" />
              </IconButton>
            </div>
            <div className="col-11 d-flex align-items-center">
              <Typography className="text-roboto-regular mb-0">
                Devolución
              </Typography>
            </div>
          </div>
        </div>
      );
    };

    const SeleccionarEstado = () => {
      return (
        <FormControl variant="filled" className="col-6 pl-0 pr-3">
          <Select
            className="select-crear select-modal m-estados e-tarea color-mega-dark-grey p-0"
            labelId="demo-simple-select-filled"
            id="demo-simple-select"
            value={
              this.state.tareas.length === 0
                ? ""
                : this.state.tareas[0].idstatus_task
            }
            onChange={(e) => {
              e.stopPropagation();
              this.handleForm(e, 0);
              this.setState({ ingresarEvidencia: false });
            }}
            name="idstatus_task"
            IconComponent={CustomExpandMore}
          >
            <MenuItem className="row m-0" value={ID_NO_ASIGNADO}>
              <div className="col-md-3 d-flex align-items-center">
                <div className="dot back-medium-grey"></div>
              </div>
              <div className="col-md-9 pl-0 d-flex align-items-center">
                No asignado
              </div>
            </MenuItem>
            <MenuItem className="row m-0" value={ID_ASIGNADO}>
              <div className="col-md-3 d-flex align-items-center">
                <div className="dot dot-asignado"></div>
              </div>
              <div className="col-md-9 pl-0 d-flex align-items-center">
                Asignado
              </div>
            </MenuItem>
            <MenuItem className="row m-0" value={ID_ACEPTADO}>
              <div className="col-md-3 d-flex align-items-center">
                <div className="dot dot-aceptado"></div>
              </div>
              <div className="col-md-9 pl-0 d-flex align-items-center">
                Aceptado
              </div>
            </MenuItem>
            <MenuItem className="row m-0" value={ID_INICIADO}>
              <div className="col-md-3 d-flex align-items-center">
                <div className="dot dot-iniciado"></div>
              </div>
              <div className="col-md-9 pl-0 d-flex align-items-center">
                Iniciado
              </div>
            </MenuItem>
            <MenuItem className="row m-0" value={ID_EN_PROGRESO}>
              <div className="col-md-3 d-flex align-items-center">
                <div className="dot dot-en-progreso"></div>
              </div>
              <div className="col-md-9 pl-0 d-flex align-items-center">
                En Progreso
              </div>
            </MenuItem>
            <MenuItem className="row m-0" value={ID_EXITOSO}>
              <div className="col-md-3 d-flex align-items-center">
                <div className="dot dot-exitoso"></div>
              </div>
              <div className="col-md-9 pl-0 d-flex align-items-center">
                Exitoso
              </div>
            </MenuItem>
            <MenuItem className="row m-0" value={ID_FALLIDO}>
              <div className="col-md-3 d-flex align-items-center">
                <div className="dot dot-haFallado"></div>
              </div>
              <div className="col-md-9 pl-0 d-flex align-items-center">
                Fallido
              </div>
            </MenuItem>
            <MenuItem className="row m-0" value={ID_RECHAZADO}>
              <div className="col-md-3 d-flex align-items-center">
                <div className="dot dot-rechazado"></div>
              </div>
              <div className="col-md-9 pl-0 d-flex align-items-center">
                Rechazado
              </div>
            </MenuItem>
            <MenuItem className="row m-0" value={ID_CANCELADO}>
              <div className="col-md-3 d-flex align-items-center">
                <div className="dot dot-cancelado"></div>
              </div>
              <div className="col-md-9 pl-0 d-flex align-items-center">
                Cancelado
              </div>
            </MenuItem>
            <MenuItem className="row m-0" value={ID_DEVUELTO}>
              <div className="col-md-3 d-flex align-items-center">
                <div className="dot dot-devuelto"></div>
              </div>
              <div className="col-md-9 pl-0 d-flex align-items-center">
                Devuelto
              </div>
            </MenuItem>
          </Select>
        </FormControl>
      );
    };

    const Titulo = () => {
      if (this.state.accion === "Editar") {
        if (this.state.tareas.length === 0) {
          return (
            <label className="title-text h4 ml-2 mb-0">
              Tarea: ID orden...
            </label>
          );
        } else {
          return (
            <div className="w-100">
              <p className="title-text line-height-1 ml-2 mb-0 mt-0">
                Tarea: ID orden
              </p>
              <h5 className="title-text line-height-1 h5 ml-2 mb-0">
                {this.state.tareas[0].order_id_task}
              </h5>
            </div>
          );
        }
      } else {
        if (this.state.tipo === "0") {
          return <h4 className="title-text h4 ml-2 mb-0">Crear Tareas</h4>;
        } else {
          return <h4 className="title-text h4 ml-2 mb-0">Crear Tarea</h4>;
        }
      }
    };

    return (
      <div className="w-100 formulario-tareas">
        <Cargando cargando={this.state.cargando} />
        <div className="hide-navbar"></div>

        <div className="row m-0 home-screen">
          {this.state.formulario ? (
            <div className="col-md-6 p-0 home-screen plomo">
              <div className="row m-0 py-2 pl-4 pr-2 back-light-grey">
                <div className="col-1 p-0 d-flex justify-content-start align-items-center">
                  <img src={IconoTareas} alt="IconoAppRayo" height="35" />
                </div>
                <div className="col-10 p-0 d-flex justify-content-start align-items-center">
                  <Titulo />
                </div>
                <div className="col-1 p-0 d-flex justify-content-center align-items-center">
                  <IconButton
                    type="button"
                    aria-label="cerrar"
                    className="p-1"
                    onClick={() => this.confirmLeave()}
                  >
                    <CloseIcon color="primary" />
                  </IconButton>
                </div>
              </div>

              <div
                className={
                  (this.state.tipo === "0" ||
                    typeof this.state.tipo === "undefined") &&
                  this.state.accion === "Crear"
                    ? "container-footer-top"
                    : "container-footer-y"
                }
              >
                <div className="row m-0 px-3">
                  <div className="col-6 p-0 pr-3">
                    {this.state.accion === "Editar" ? (
                      <Button
                        disabled
                        type="button"
                        variant="contained"
                        color={
                          this.state.tipo === "0" ? "contained" : "default"
                        }
                        className="text-roboto-regular text-capitalize py-1 w-100"
                        startIcon={
                          this.state.form.cliente === 0 ? (
                            <img
                              height="18"
                              src={IconoCliente}
                              alt="Icono rayo"
                              className="ml-2 mr-0 mb-1"
                            />
                          ) : this.props.clientes.clientes ? (
                            this.props.clientes.clientes.map((cliente, index) =>
                              this.state.form.cliente === cliente._id ? (
                                <img
                                  key={index}
                                  src={cliente.image_customer}
                                  alt="icono"
                                  height="20"
                                  className="br-4"
                                  focusable="false"
                                />
                              ) : (
                                false
                              )
                            )
                          ) : (
                            false
                          )
                        }
                      >
                        <Select
                          className="text-white select-crear-cliente w-100"
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          name="cliente"
                          value={this.state.form.cliente}
                          onChange={this.handleChange}
                          IconComponent={() => (
                            <img
                              src={IconoFlecha}
                              alt="icono"
                              height="20"
                              className="mt-0 rotar-icono-180 MuiSvgIcon-root MuiSelect-icon MuiSelect-iconFilled "
                              focusable="false"
                            />
                          )}
                        >
                          <MenuItem value={0}>Asignar Cliente</MenuItem>
                          {this.props.clientes.clientes
                            ? this.props.clientes.clientes.map(
                                (cliente, index) => (
                                  <MenuItem value={cliente._id} key={index}>
                                    {cliente.name_customer}
                                  </MenuItem>
                                )
                              )
                            : false}
                        </Select>
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        className="text-roboto-regular text-capitalize py-1 w-100"
                        color={
                          this.state.tipo === "0" ||
                          typeof this.state.tipo === "undefined"
                            ? "primary"
                            : "default"
                        }
                        variant="contained"
                        startIcon={
                          this.state.form.cliente === 0 ? (
                            <img
                              height="18"
                              src={IconoCliente}
                              alt="Icono rayo"
                              className="ml-2 mr-0 mb-1"
                            />
                          ) : this.props.clientes.clientes ? (
                            this.props.clientes.clientes.map((cliente, index) =>
                              this.state.form.cliente === cliente._id ? (
                                <img
                                  key={index}
                                  src={cliente.image_customer}
                                  alt="icono"
                                  height="20"
                                  className="br-4"
                                  focusable="false"
                                />
                              ) : (
                                false
                              )
                            )
                          ) : (
                            false
                          )
                        }
                        disabled={
                          this.state.tipo === "0" ||
                          typeof this.state.tipo === "undefined"
                            ? false
                            : true
                        }
                      >
                        <Select
                          className="text-white select-crear-cliente w-100"
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          name="cliente"
                          value={this.state.form.cliente}
                          onChange={this.handleChange}
                          IconComponent={() => (
                            <img
                              src={IconoFlecha}
                              alt="icono"
                              height="20"
                              className="mt-0 rotar-icono-180 MuiSvgIcon-root MuiSelect-icon MuiSelect-iconFilled "
                              focusable="false"
                            />
                          )}
                        >
                          <MenuItem value={0}>Asignar Cliente</MenuItem>
                          {this.props.clientes.clientes
                            ? this.props.clientes.clientes.map(
                                (cliente, index) => (
                                  <MenuItem value={cliente._id} key={index}>
                                    {cliente.name_customer}
                                  </MenuItem>
                                )
                              )
                            : false}
                        </Select>
                      </Button>
                    )}
                  </div>
                  <div className="col-6 p-0 d-flex justify-content-start">
                    {typeof this.state.tipo === "undefined" ? (
                      false
                    ) : this.state.rayo === null ? (
                      <Tooltip title="Debe seleccionar un cliente para asignar un rayo">
                        {/* <span className="w-100"> */}
                        <Button
                          disabled={
                            this.state.form.cliente === 0 ? true : false
                          }
                          type="button"
                          className="end-start-icon text-roboto-regular text-capitalize py-1 w-100"
                          color="primary"
                          variant="contained"
                          onClick={() => this.handleFormulario(false)}
                          startIcon={
                            <img
                              height="22"
                              src={IconoRayo}
                              alt="Icono rayo"
                              className="mb-0 float-left"
                            />
                          }
                          endIcon={
                            <img
                              height="22"
                              src={IconoFlecha}
                              alt="Icono rayo"
                              className="rotar-icono-180 float-right"
                            />
                          }
                        >
                          Asignar Rayo
                        </Button>
                        {/* </span> */}
                      </Tooltip>
                    ) : (
                      <Button
                        type="button"
                        className="end-start-icon back-light-blue text-white text-roboto-regular text-capitalize py-1 w-100"
                        onClick={() => this.handleFormulario(false)}
                        startIcon={
                          <img
                            height="26"
                            src={this.state.rayo.image_rayo}
                            alt="Icono rayo"
                            className="mb-0 br-4"
                          />
                        }
                        endIcon={
                          <img
                            height="20"
                            src={IconoFlecha}
                            alt="Icono rayo"
                            className="rotar-icono-180"
                          />
                        }
                      >
                        {this.state.rayo.name_rayo}
                      </Button>
                    )}
                  </div>
                </div>
              </div>

              <form onSubmit={this.validarFormulario}>
                {(this.state.tipo === "0" ||
                  typeof this.state.tipo === "undefined") &&
                this.state.accion === "Crear" ? (
                  <div className="row m-0 px-3 pb-3">
                    <div className="col-12 p-0 mt-2">
                      <input
                        required={
                          (this.state.tipo === "0" ||
                            typeof this.state.tipo === "undefined") &&
                          this.state.accion === "Crear"
                            ? true
                            : false
                        }
                        className="form-control order-id-input"
                        style={{
                          backgroundColor: "#dad8d8",
                          border: "none",
                        }}
                        placeholder="Order ID grupo de tareas"
                        name="order_id_taskgroup"
                        value={this.state.order_id_taskgroup}
                        onChange={(e) =>
                          this.setState({ order_id_taskgroup: e.target.value })
                        }
                      />
                    </div>
                  </div>
                ) : (
                  false
                )}

                <div
                  className={
                    this.state.accion === "Editar"
                      ? "paper-editar w-100"
                      : (this.state.tipo === "0" ||
                          typeof this.state.tipo === "undefined") &&
                        this.state.accion === "Crear"
                      ? "paper-tarea w-100"
                      : "paper-editar w-100"
                  }
                >
                  {this.state.accion === "Editar" ? (
                    false
                  ) : (
                    <div>
                      <PickUp />
                      <Cita />
                      <Devolucion />
                      <hr />
                    </div>
                  )}
                  <div className="w-100">
                    {this.state.tareas.map((tarea, i) => (
                      <Accordion key={i} defaultExpanded={true}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          aria-label="Expand"
                          id="panel1a-header"
                          className="back-light-grey-2 accordionTareas"
                        >
                          <div
                            className={
                              this.state.accion === "Editar"
                                ? "col-6 d-flex align-items-center"
                                : "col-10 d-flex align-items-center"
                            }
                            //onClick={(event) => event.stopPropagation()}
                          >
                            <Typography className="text-roboto-regular mb-0">
                              {tarea.type_task === 1
                                ? "PickUp"
                                : tarea.type_task === 2
                                ? "Delivery"
                                : tarea.type_task === 3
                                ? "Cita"
                                : "Devolución"}
                            </Typography>
                          </div>
                          {this.state.accion === "Editar" ? (
                            <SeleccionarEstado />
                          ) : (
                            <div className="col-2 pr-0 d-flex justify-content-end align-items-center">
                              <IconButton
                                type="button"
                                aria-label="eliminar"
                                onClick={() => this.eliminarTareaArray(i)}
                              >
                                <CloseIcon color="primary" fontSize="small" />
                              </IconButton>
                            </div>
                          )}
                        </AccordionSummary>
                        <AccordionDetails>
                          <FormularioTareas
                            index={i}
                            tarea={tarea}
                            change={this.handleForm}
                            templates={this.state.alltemplates}
                            changeSelect={this.handleFormSelect}
                            changeAddress={this.handleAddress}
                            changePhone={this.handlePhone}
                            changeAddressSelect={this.handleAddressSelect}
                            templatesStore={this.props.templatesStore.templates}
                            agregarDelivery={this.agregarDelivery}
                            accion={this.state.accion}
                            tipo={this.state.tipo}
                            orderID={this.state.order_id_taskgroup}
                            cliente={this.state.form.cliente}
                            ingresarEvidencia={this.state.ingresarEvidencia}
                            handleChangeCheck={this.handleChangeCheck}
                          />
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </div>
                <div className="container-footer-crear">
                  <div className="row m-0 px-3">
                    <div className="col-5 p-0 pr-3 d-flex justify-content-start"></div>
                    <div className="col-5 p-0 pr-3 d-flex justify-content-start"></div>
                    <div className="col-2 p-0 d-flex justify-content-end">
                      {this.state.accion === "Editar" &&
                      this.state.tareas.length !== 0 ? (
                        this.state.tareaCerrada ? (
                          false
                        ) : (
                          <Button
                            type="submit"
                            className="text-capitalize py-1 w-100"
                            style={{ height: 36 }}
                            variant="contained"
                            color="secondary"
                          >
                            {this.state.accion === "Editar"
                              ? "Editar Tarea"
                              : this.state.tipo === "0"
                              ? "Crear Tareas"
                              : "Crear Tarea"}
                          </Button>
                        )
                      ) : (
                        <Button
                          type="submit"
                          className="text-capitalize py-1 w-100"
                          style={{ height: 36 }}
                          variant="contained"
                          color="secondary"
                        >
                          {this.state.accion === "Editar"
                            ? "Editar Tarea"
                            : this.state.tipo === "0"
                            ? "Crear Tareas"
                            : "Crear Tarea"}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className="col-md-6 p-0 home-screen plomo">
              <div className="row m-0 py-2 pl-4 pr-2 back-light-grey">
                <div className="col-1 p-0 d-flex justify-content-start align-items-center">
                  <img src={IconoRayoBlack} alt="IconoAppRayo" height="35" />
                </div>
                <div className="col-7 p-0 d-flex justify-content-start align-items-center">
                  <label className="title-text h3 ml-2 mb-0">
                    Asignar un Rayo
                  </label>
                </div>
                <div className="col-4 p-0 d-flex justify-content-end align-items-center">
                  <IconButton
                    aria-label="cerrar"
                    className="p-1"
                    onClick={() => this.handleFormulario(true)}
                  >
                    <img
                      alt="Cerrar"
                      src={IconoFlechaBlue}
                      height="25"
                      className="cursor-pointer"
                    />
                  </IconButton>
                </div>
              </div>
              <div className="row m-0">
                <div
                  className="col-6 p-0"
                  style={{ borderRight: "1px solid #e5e5e5" }}
                >
                  <div className="input-group">
                    <div className="input-group-append append-crear m-0 d-flex justify-content-center align-items-center">
                      <SearchIcon fontSize="small" color="primary" />
                    </div>
                    <input
                      className="form-control input-buscar"
                      placeholder="Buscar Rayos"
                      name="buscarRayo1"
                      onChange={this.handleChange}
                      value={this.state.form.buscarRayo}
                    />
                  </div>
                  <AsignarRayoColumn
                    buscarRayo={this.state.form.buscarRayo1}
                    buscarCliente={this.state.form.cliente}
                    rayos={this.state.rayos}
                    estado={1}
                    handleCheck={this.handleCheck}
                  />
                </div>

                <div className="col-6 p-0">
                  <div className="input-group">
                    <div className="input-group-append append-crear m-0 d-flex justify-content-center align-items-center">
                      <SearchIcon fontSize="small" color="primary" />
                    </div>
                    <input
                      className="form-control input-buscar"
                      placeholder="Buscar Rayos"
                      name="buscarRayo2"
                      onChange={this.handleChange}
                      value={this.state.form.buscarRayo2}
                    />
                  </div>
                  <AsignarRayoColumn
                    buscarRayo={this.state.form.buscarRayo2}
                    buscarCliente={this.state.form.cliente}
                    rayos={this.state.rayos}
                    estado={2}
                    handleCheck={this.handleCheck}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="col-md-6 p-0 home-screen">
            <MapaGoogle
              mapaId="googlemapsTask"
              vista="tarea"
              fitBounds={true}
              localizaciones={this.state.localizaciones}
            />
          </div>
        </div>

        <div
          className="modal fade"
          aria-labelledby="modal"
          data-backdrop="static"
          aria-hidden="true"
          scrollable="true"
          role="dialog"
          id="modal"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-white">
              <div className="modal-body">
                <h4 className="h4-modal text-center">Ingresar evidencias</h4>
                {this.state.tareas.length !== 0 ? (
                  this.state.tareas[0].idstatus_task === ID_EXITOSO ? (
                    <IngresarTemplate
                      ingresarEvidencia={true}
                      handleChangeCheck={() => {}}
                      template={this.state.templates.successful}
                      typeTemplate="successful"
                      optionsDropdown={this.state.optionsDropdownsuccessful}
                      handleChange={this.handleChangeEvidencia}
                      handleChangeImg={this.handleChangeEvidenciaImg}
                    />
                  ) : this.state.tareas[0].idstatus_task === ID_FALLIDO ? (
                    <IngresarTemplate
                      ingresarEvidencia={true}
                      handleChangeCheck={() => {}}
                      template={this.state.templates.failed}
                      typeTemplate="failed"
                      optionsDropdown={this.state.optionsDropdownfailed}
                      handleChange={this.handleChangeEvidencia}
                      handleChangeImg={this.handleChangeEvidenciaImg}
                    />
                  ) : (
                    false
                  )
                ) : (
                  false
                )}
              </div>
              <div className="modal-footer row m-0">
                <div className="col-6 d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn-secondary"
                    onClick={() => {
                      $("#modal").modal("hide");
                    }}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  validar = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  validarFormulario = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.validar(event);
    } else {
      this.validar(event);

      if (this.state.accion === "Editar") {
        this.modifyTask();
      } else {
        if (this.state.tipo === "0" || typeof this.state.tipo === "undefined") {
          this.createTaskGroup();
        } else {
          this.createTask();
        }
      }
    }
  };
}

const mapStateToProps = (store) => {
  return {
    templatesStore: store.templates,
    clientes: store.clientes,
  };
};

export default connect(mapStateToProps)(CrearTarea);
