import React from "react";

import Checkbox from "@material-ui/core/Checkbox";

const AsignarRayoColumn = ({
  buscarRayo,
  buscarCliente,
  rayos,
  estado,
  handleCheck,
}) => {
  return (
    <div className="paper-rayos-tasks w-100">
      {rayos
        .filter((ray) => {
          let bandera = false;

          if (
            buscarCliente !== 0 &&
            typeof buscarCliente === "string" &&
            buscarCliente !== "5f0c95b1495f530ee0dbe9ff"
          ) {
            if (ray.customer_rayo) {
              let i = ray.customer_rayo.findIndex(
                (cliente) => cliente.idCustomer === "5f0c95b1495f530ee0dbe9ff"
              );

              if (i >= 0) {
                ray.customer_rayo.forEach((cliente) => {
                  if (
                    ray.name_rayo
                      .toLowerCase()
                      .includes(buscarRayo.toLowerCase()) &&
                    ray.status_rayo === estado
                  ) {
                    bandera = true;
                  }
                });
              } else {
                ray.customer_rayo.forEach((cliente) => {
                  if (
                    ray.name_rayo
                      .toLowerCase()
                      .includes(buscarRayo.toLowerCase()) &&
                    cliente.idCustomer
                      .toLowerCase()
                      .includes(buscarCliente.toLowerCase()) &&
                    ray.status_rayo === estado
                  ) {
                    bandera = true;
                  }
                });
              }
            } else {
              if (
                ray.name_rayo
                  .toLowerCase()
                  .includes(buscarRayo.toLowerCase()) &&
                ray.status_rayo === estado
              ) {
                bandera = true;
              }
            }
          } else {
            if (
              ray.name_rayo.toLowerCase().includes(buscarRayo.toLowerCase()) &&
              ray.status_rayo === estado
            ) {
              bandera = true;
            }
          }
          return bandera;
        })
        .map((rayo, i) => (
          <div className="row m-0 p-2" key={i}>
            <div className="d-inline-block p-relative" style={{ width: 65 }}>
              <img
                src={rayo.image_rayo}
                height="50"
                width="50"
                alt="rayo"
                className="img-rayo"
              />
              <div
                className={
                  rayo.status_rayo === 1
                    ? "position-absolute dot activo"
                    : "position-absolute dot inactivo"
                }
                style={{ top: 44, left: 45 }}
              ></div>
            </div>
            <div
              className="d-inline-flex align-items-center"
              style={{ width: "calc(100% - 45px - 65px)" }}
            >
              <div className="w-100">
                <p className="text-exo-bold mb-0 txt-rayo-name color-dark-grey">
                  {rayo.name_rayo}
                </p>
                <p className="text-roboto-regular mb-0 txt-rayo-name color-dark-grey">
                  {"+ " + rayo.number_rayo}
                </p>
                <p className="text-roboto-regular mb-0 txt-rayo-name color-dark-grey">
                  {rayo.providers.length !== 0
                    ? rayo.providers[0].name_provider
                    : false}
                </p>
              </div>
            </div>
            <div
              className="d-inline-flex align-items-center justify-content-center"
              style={{ width: 45 }}
            >
              <Checkbox
                inputProps={{ "aria-label": "primary checkbox" }}
                checked={rayo.selected}
                onChange={(e) => handleCheck(rayo._id)}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default AsignarRayoColumn;
