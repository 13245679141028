import React from "react";
import $ from "jquery";

import { FormControl, MenuItem, Select, Button } from "@material-ui/core";
import CustomExpandMore from "../../components/custom/CustomExpandMore";

class CancelarTarea extends React.Component {
  render() {
    return (
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal-white">
          <div className="modal-body">
            <h4 className="h4-modal text-center">Cancelar tarea</h4>
            <h4 className="text-roboto-regular color-mega-dark-grey text-center">
              ID de Tarea
            </h4>
            <input
              defaultValue={
                this.props.data === null ? "" : this.props.data.code_task
              }
              disabled
              className="form-control input-id-grupo mb-3"
            />
            <div>
              <label className="text-roboto-regular color-mega-dark-grey mb-1 mt-3">
                Ingrese motivo
              </label>
              {/* <input
                placeholder="Motivo..."
                className="form-control txt-modal"
                name="motivoCancelar"
                value={this.props.motivoCancelar}
                onChange={this.props.handleChange}
                maxLength={80}
              /> */}
              <FormControl variant="filled" className="w-100">
                <Select
                  className="select-crear select-modal m-estados color-mega-dark-grey p-0"
                  id="motivoCancelar"
                  name="motivoCancelar"
                  value={this.props.motivoCancelar}
                  onChange={this.props.handleChange}
                  IconComponent={CustomExpandMore}
                >
                  {/* <MenuItem value="Segúnda Visita">Segúnda Visita</MenuItem> */}
                  <MenuItem value="Error de cierre">Error de cierre</MenuItem>
                  <MenuItem value="Fuera de cobertura">
                    Fuera de cobertura
                  </MenuItem>
                  <MenuItem value="Rechazo, ya recibió el producto">
                    Rechazo, ya recibió el producto
                  </MenuItem>
                  <MenuItem value="Rechazo, desiste de la solicitud">
                    Rechazo, desiste de la solicitud
                  </MenuItem>
                  <MenuItem value="Rechazo, retiró en sucursal">
                    Rechazo, retiró en sucursal
                  </MenuItem>
                  <MenuItem value="Rechazo, solicitó chip con equipo">
                    Rechazo, solicitó chip con equipo
                  </MenuItem>
                  <MenuItem value="Rechazo, solicitó sólo un chip o solicitó dos chip">
                    Rechazo, solicitó sólo un chip o solicitó dos chip
                  </MenuItem>
                  <MenuItem value="Rechazo, no solicitó el producto">
                    Rechazo, no solicitó el producto
                  </MenuItem>
                  <MenuItem value="Rechazo, producto no corresponde">
                    Rechazo, producto no corresponde
                  </MenuItem>
                  <MenuItem value="Rechazo, cliente desconoce pago del abono">
                    Rechazo, cliente desconoce pago del abono
                  </MenuItem>
                  <MenuItem value="Rechazo, solicitó recibir un tercero">
                    Rechazo, solicitó recibir un tercero
                  </MenuItem>
                  <MenuItem value="Cliente con deuda</">
                    Cliente con deuda
                  </MenuItem>
                  <MenuItem value="Cliente no puede esperar">
                    Cliente no puede esperar
                  </MenuItem>
                  <MenuItem value="Gestión rayo finalizada">
                    Gestión rayo finalizada
                  </MenuItem>
                  <MenuItem value="Agendamiento invalido">
                    Agendamiento invalido
                  </MenuItem>
                  <MenuItem value="Problema huella, problema cedula">
                    Problema huella, problema cedula
                  </MenuItem>
                  <MenuItem value="Demora retoma">Demora retoma</MenuItem>
                  <MenuItem value="Solicitado por Claro">
                    Solicitado por Claro
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="modal-footer row m-0">
            <div className="col-6 d-flex justify-content-center">
              <Button
                type="button"
                variant="text"
                color="primary"
                onClick={() => $("#modal").modal("hide")}
              >
                Cancelar
              </Button>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <Button
                type="button"
                variant="contained"
                color="secondary"
                onClick={this.props.cancelar}
              >
                Confirmar
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CancelarTarea;
