import { OBTENER_COMUNAS, MODIFICAR_ARRAY_COMUNAS, ERROR_COMUNAS, RES_ERROR } from '../actions/Comunas';

const estadoInicial = {
    comunas: null,
    error: null,
};

const INITIAL_STATE = {
    loggedIn: true,
};

const comunasReducer = (estado = estadoInicial, accion) => {
    switch (accion.type) {
        case OBTENER_COMUNAS:
            return {
                ...estado,
                comunas: accion.comunas,
            };
        case MODIFICAR_ARRAY_COMUNAS:
            return {
                ...estado,
                comunas: accion.comunas,
            };
        case ERROR_COMUNAS:
            return {
                ...estado,
                error: accion.error,
            };
        case RES_ERROR:
            return INITIAL_STATE;
        default:
            return estado;
    }
};

export default comunasReducer;
