import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import cloneDeep from "lodash/cloneDeep";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import url from "../../config";
import Utils from "../../assets/utils/Utils";
import Cargando from "../../assets/components/cargando/Cargando";
import CopyText from "../../assets/components/custom/CopyText";
import "../../css/global.css";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@material-ui/core";

import ActiveDot from "../../assets/components/mantenedores/ActiveDot";
import TextFieldOutlined from "../../assets/components/custom/TextFieldOutlined";

import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import Skeleton from "@material-ui/lab/Skeleton";
import { User } from "realm-web";

const IconoUsuario =
  "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/genericImage.png";
const columns = [
  {
    id: "copiar",
    label: "Copiar ID",
    align: "left",
  },
  //{ id: 'code_dispatcher', label: 'Código', class: 'th-left-borde', classtd: 'th-left-borde text-left' },
  // { id: '_id', label: 'Código', class: 'th-left-borde', classtd: 'th-left-borde text-left' },
  { id: "image_dispatcher", label: "Imagen", align: "left" },
  { id: "name_dispatcher", label: "Nombre", align: "left" },
  // { id: "dni_dispatcher", label: "DNI", class: "", classtd: "text-left" },
  { id: "contact", label: "Contacto", align: "left" },
  // {
  //   id: "number_dispatcher",
  //   label: "Teléfono",
  //   class: "",
  //   classtd: "text-left",
  // },
  { id: "role_dispatcher", label: "Role", align: "left" },
  {
    id: "customer_dispatcher",
    label: "Cliente",
    align: "left",
  },
  { id: "tenant", label: "Tenant", align: "left" },
  { id: "status_dispatcher", label: "Estado", align: "left" },
  {
    id: "accion",
    label: "Acción",
    align: "right",
  },
];

class Dispatchers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      tipoModal: 1,
      tenants: null,

      search: "",
      custumers: [],
      dispatchers: null,
      form: {
        dispatcher: "",
        code_dispatcher: "",
        customer_dispatcher: [],
        dni_dispatcher: "",
        image_dispatcher: IconoUsuario,
        mail_dispatcher: "",
        name_dispatcher: "",
        nationality_dispatcher: "",
        direction_dispatcher: "",
        number_dispatcher: "",
        status_dispatcher: "1",
        role_dispatcher: "",
        password_dispatcher: "",
        realm_id: "",
        tenants: [],
      },
      cargando: false,
    };
    this.getDispatchers();
    this.getTenants();
  }

  handleCargando(value) {
    this.setState({
      cargando: value,
    });
  }

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let valor = value;
    if (name === "number_dispatcher") {
      valor = value.replace(/[^0-9.]/g, "");
    } else {
      valor = value;
    }
    this.setState({
      form: {
        ...this.state.form,
        [name]: valor,
      },
    });
  };

  handleChangeButton = (value) => {
    this.setState({
      form: {
        ...this.state.form,
        status_dispatcher: value,
      },
    });
  };

  handlePhone = (e) => {
    let name = "number_dispatcher";
    let value = "+ " + e.replace(/[^0-9.]/g, "");
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  handleImage = (e) => {
    try {
      e.preventDefault();
      const estado = this;
      const { name } = e.target;
      const reader = new FileReader();

      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function () {
        estado.setState({
          form: {
            ...estado.state.form,
            [name]: reader.result,
          },
        });
      };

      document.getElementById("image_dispatcher").value = "";
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error al subir la imagen, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  handleClientes = (i) => {
    let newArray = [...this.state.form.customer_dispatcher];
    newArray[i] = {
      ...newArray[i],
      activo: !newArray[i].activo,
    };

    this.setState({
      form: {
        ...this.state.form,
        customer_dispatcher: newArray,
      },
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0,
    });
  };

  handleModal = async (tipo, dispatcher) => {
    this.setState({
      tipoModal: tipo,
    });
    if (tipo === 1) {
      $("#modalFormulario").modal("show");
    } else {
      let array = cloneDeep(this.state.custumers);
      array.forEach((v) => {
        dispatcher.customer_dispatcher.forEach((r) => {
          if (v._id === r.idCustomer) {
            v.activo = true;
          }
        });
      });
      await this.setState({
        form: {
          ...this.state.form,
          key: dispatcher._id,
          code_dispatcher: dispatcher.code_dispatcher,
          dni_dispatcher: dispatcher.dni_dispatcher,
          image_dispatcher: dispatcher.image_dispatcher,
          mail_dispatcher: dispatcher.mail_dispatcher,
          name_dispatcher: dispatcher.name_dispatcher,
          direction_dispatcher: dispatcher.direction_dispatcher,
          nationality_dispatcher: dispatcher.nationality_dispatcher,
          number_dispatcher: dispatcher.number_dispatcher.toString(),
          status_dispatcher: dispatcher.status_dispatcher,
          customer_dispatcher: array,
          role_dispatcher: dispatcher.role_dispatcher,
          realm_id: dispatcher.realm_id,
          tenant: dispatcher.tenant,
          tenant_name: dispatcher.tenant_name,
        },
      });
      $("#modalFormulario").modal("show");
    }
  };

  emptyForm = () => {
    this.setState({
      form: {
        ...this.state.form,
        code_dispatcher: "",
        customer_dispatcher: [...this.state.custumers],
        dni_dispatcher: "",
        image_dispatcher: IconoUsuario,
        mail_dispatcher: "",
        name_dispatcher: "",
        direction_dispatcher: "",
        nationality_dispatcher: "",
        number_dispatcher: "+56",
        status_dispatcher: "1",
        role_dispatcher: "",
        password_dispatcher: "",
        realm_id: "",
        tenants: [...this.state.tenants],
      },
    });
  };

  getTenants = async () => {
    try {
      let usuario = JSON.parse(sessionStorage.getItem("user"));
      let data = await axios({
        method: "get",
        url: url.host + "/tenant/tenant",
        headers: { tenant: usuario.tenant },
      }).then(({ data }) => data);
      let tenants = data.sort(function (a, b) {
        if (
          a.name.toLowerCase().trim() <
          b.name.toLowerCase().trim()
        )
          return -1;
        if (
          a.name.toLowerCase().trim() >
          b.name.toLowerCase().trim()
        )
          return 1;
        return 0;
      });

      this.setState({
        tenants: tenants,
        form: {
          ...this.state.form,
          tenants: tenants,
        },
      });
    } catch (err) {
      Utils.handleAlert(
        "Obtener tenants",
        "No se han podido cargar los tenants",
        "Ok"
      );
    }
  };
  getCustumers = async () => {
    try {
      let clientes = Utils.getCustomers();
      let usuario = JSON.parse(sessionStorage.getItem("user"));
      let data = await axios({
        method: "get",
        url: url.host + "/Customers/customers" + clientes,
        headers: { tenant: usuario.tenant },
      }).then(({ data }) => data);
      let customers = data.sort(function (a, b) {
        if (
          a.name_customer.toLowerCase().trim() <
          b.name_customer.toLowerCase().trim()
        )
          return -1;
        if (
          a.name_customer.toLowerCase().trim() >
          b.name_customer.toLowerCase().trim()
        )
          return 1;
        return 0;
      });

      customers.forEach((v) => {
        v.activo = false;
        return v;
      });

      this.setState({
        custumers: customers,
        form: {
          ...this.state.form,
          customer_dispatcher: customers,
        },
      });
    } catch (err) {
      Utils.handleAlert(
        "Obtener clientes",
        "No se han podido cargar los clientes",
        "Ok"
      );
    }
  };

  getDispatchers = async () => {
    try {
      let usuario = JSON.parse(sessionStorage.getItem("user"));
      let clientes = Utils.getCustomers();
      let data = await axios({
        method: "get",
        headers: { tenant: usuario.tenant },
        url: url.host + "/Dispatchers/dispatchers" + clientes,
      }).then(({ data }) => data);
      this.setState({ dispatchers: data });
      this.getCustumers();
      console.log(this.state)
    } catch (err) {
      Utils.handleAlert(
        "Obtener dispatchers",
        "No se han podido cargar los dispatchers",
        "Ok"
      );
    }
  };

  createDispatcher = async () => {
    try {
      let tiposCliente = cloneDeep(this.state.form.customer_dispatcher);
      let newTiposCliente = [];
      let seleccionCliente = false;
      tiposCliente.forEach((tipo) => {
        if (tipo.activo) {
          seleccionCliente = true;
          newTiposCliente.push({
            idCustomer: tipo._id,
          });
        }
      });

      if (seleccionCliente) {
        if (this.state.form.password_dispatcher === "") {
          this.handleCargando(false);
          Utils.handleAlert(
            "Campos requeridos",
            "Para crear un dispatcher, debe ingresar una contraseña",
            "Ok"
          );
        } else {
          let numero = this.state.form.number_dispatcher.split("+");
          let tenantname = $( "#tenant option:selected" ).text();
          const dispatcher = {
            code_dispatcher: this.state.form.code_dispatcher,
            dni_dispatcher: this.state.form.dni_dispatcher.trim(),
            image_dispatcher: this.state.form.image_dispatcher,
            mail_dispatcher: this.state.form.mail_dispatcher
              .toLowerCase()
              .replace(" ", ""),
            name_dispatcher: this.state.form.name_dispatcher.trim(),
            direction_dispatcher: this.state.form.direction_dispatcher.trim(),
            role_dispatcher: this.state.form.role_dispatcher,
            nationality_dispatcher: this.state.form.nationality_dispatcher,
            number_dispatcher: parseInt(
              numero.length === 1 ? numero[0] : numero[1]
            ),
            status_dispatcher: parseInt(this.state.form.status_dispatcher),
            customer_dispatcher: newTiposCliente,
            password_dispatcher: this.state.form.password_dispatcher,
            tenant: this.state.form.tenant,
            tenant_name: tenantname,
            realm_id: this.state.form.realm_id,
          };

          let usuario = JSON.parse(sessionStorage.getItem("user"));
          await url.api
            .post("/Dispatchers/dispatcher/", dispatcher, { headers: { tenant: usuario.tenant } })
            .then((response) => {
              if (response.status === 201 || response.status === 200) {
                $("#modalFormulario").modal("hide");
                this.getDispatchers();
                this.handleCargando(false);
                Utils.handleAlert(
                  "Dispatcher ingresado",
                  "El dispatcher se ha ingresado exitosamente",
                  "Ok"
                );
                this.emptyForm();
              } else {
                this.handleCargando(false);
                Utils.handleAlert(
                  "Error " + response.status,
                  "Ha ocurrido un error al ingresar el dispatcher, vuelva a intentarlo",
                  "Ok"
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                let title = "Error en acción " + error.response.status;
                if (error.response.status === 412) {
                  title = "Precondición fallida";
                }
                this.handleCargando(false);
                Utils.handleAlert(
                  title,
                  error.response.data.error.detail.error_message,
                  "Ok"
                );
              } else {
                this.handleCargando(false);
                Utils.handleAlert(
                  "Error al realizar la peticion",
                  error.message,
                  "Ok"
                );
              }
            });
        }
      } else {
        this.handleCargando(false);
        Utils.handleAlert(
          "Campos requeridos",
          "Debe ingresar al menos un cliente",
          "Ok"
        );
      }
    } catch (error) {
      this.handleCargando(false);
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al ingresar un dispatcher, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  confirmDelete = (key) => {
    Swal.fire({
      title: "¿Eliminar Dispatcher?",
      text: "Si confirma, no podrá deshacer la acción y el dispatcher será eliminado de forma permanente",
      imageUrl:
        "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayoapp-red.svg",
      showCancelButton: true,
      customClass: {
        image: "imageSweetAlert",
        title: "titleSweetAlert",
        content: "textSweetAlert",
        confirmButton: "buttonSweetAlert",
        cancelButton: "btn-secondary",
      },
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.deleteDispatcher(key);
      }
    });
  };

  deleteDispatcher = async (key) => {
    try {
      const dispatcher = {
        key: key,
        tenant: this.state.form.tenant,
        realm_id: this.state.form.realm_id,
      };
      let usuario = JSON.parse(sessionStorage.getItem("user"));
      let res = await url.api.delete("/Dispatchers/dispatcher/", {
        data: dispatcher,
        headers: { Authorization: "***", tenant: usuario.tenant },
      });
      if (res.status === 200) {
        this.getDispatchers();
        Utils.handleAlert(
          "Dispatcher eliminado",
          "El dispatcher se ha eliminado exitosamente",
          "Ok"
        );
      } else {
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al eliminar el dispatcher, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al eliminar el dispatcher, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  modifyDispatcher = async () => {
    try {
      if (
        this.state.form.password_dispatcher.length === 0 ||
        this.state.form.password_dispatcher.length >= 6
      ) {
        let tiposCliente = cloneDeep(this.state.form.customer_dispatcher);
        let newTiposCliente = [];

        tiposCliente.forEach((tipo) => {
          if (tipo.activo) {
            newTiposCliente.push({
              idCustomer: tipo._id,
            });
          }
        });

        let imagen = this.state.form.image_dispatcher;
        if (imagen === IconoUsuario) {
          imagen = "";
        }

        let numero = this.state.form.number_dispatcher.split("+");

        
      let tenantname = $( "#tenant option:selected" ).text();
        const dispatcher = {
          key: this.state.form.key,
          code_dispatcher: this.state.form.code_dispatcher,
          dni_dispatcher: this.state.form.dni_dispatcher,
          image_dispatcher: this.state.form.image_dispatcher,
          mail_dispatcher: this.state.form.mail_dispatcher,
          direction_dispatcher: this.state.form.direction_dispatcher,
          name_dispatcher: this.state.form.name_dispatcher,
          nationality_dispatcher: this.state.form.nationality_dispatcher,
          number_dispatcher: parseInt(
            numero.length === 1 ? numero[0] : numero[1]
          ),
          status_dispatcher: parseInt(this.state.form.status_dispatcher),
          customer_dispatcher: newTiposCliente,
          role_dispatcher: this.state.form.role_dispatcher,
          password_dispatcher: this.state.form.password_dispatcher,
          tenant: this.state.form.tenant,
          tenant_name: tenantname,
          realm_id: this.state.form.realm_id,
        };

        let usuario = JSON.parse(sessionStorage.getItem("user"));
        await url.api
          .put("/Dispatchers/dispatcher/", dispatcher, { headers: { tenant: usuario.tenant } })
          .then((res) => {
            if (res.status === 200) {
              this.getDispatchers();
              $("#modalFormulario").modal("hide");
              this.handleCargando(false);
              Utils.handleAlert(
                "Dispatcher modificado",
                "El dispatcher se ha modificado exitosamente",
                "Ok"
              );
              this.emptyForm();
            } else {
              this.handleCargando(false);
              Utils.handleAlert(
                "Error " + res.status,
                "Ha ocurrido un error al modificar el dispatcher, vuelva a intentarlo",
                "Ok"
              );
            }
          })
          .catch((error) => {
            if (error.response) {
              let title = "Error en acción " + error.response.status;
              if (error.response.status === 412) {
                title = "Precondición fallida";
              }
              this.handleCargando(false);
              Utils.handleAlert(
                title,
                error.response.data.error.detail.error_message,
                "Ok"
              );
            } else {
              this.handleCargando(false);
              Utils.handleAlert(
                "Error al realizar la peticion",
                error.message,
                "Ok"
              );
            }
          });
      } else {
        this.handleCargando(false);
        Utils.handleAlert(
          "Campos requeridos",
          "La contraseña debe tener un largo minimo de 6 caracteres",
          "Ok"
        );
      }
    } catch (error) {
      this.handleCargando(false);
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al modificar el dispatcher, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  render() {
    const { page, rowsPerPage, tipoModal } = this.state;

    const ImagenDispatcher = ({ imagen }) => {
      return <img src={imagen} height="35" className="icon-table" alt="rayo" />;
    };

    const SeleccionClientes = ({ clientes }) => {
      return clientes.map((client, index) => {
        return (
          <Button
            variant="contained"
            color={client.activo ? "primary" : "default"}
            className="text-capitalize mr-2 mb-2 text-roboto-regular"
            onClick={() => this.handleClientes(index)}
            key={index}
          >
            {client.name_customer}
          </Button>
        );
      });
    };

    const Clientes = ({ clientes }) => {
      let array = cloneDeep(this.state.custumers);
      array.forEach((v) => {
        clientes.forEach((r) => {
          if (v._id === r.idCustomer) {
            v.activo = true;
          }
        });
      });

      return array.map((client, index) => {
        if (client.activo) {
          return (
            <label className="mb-0 w-100" key={index}>
              {"- " + client.name_customer}
            </label>
          );
        } else {
          return "";
        }
      });
    };

    const Rol = () => {
      return (
        <select
          required
          className="custom-select"
          name="role_dispatcher"
          onChange={this.handleChange}
          value={this.state.form.role_dispatcher}
        >
          <option value="" disabled>
            - Seleccionar Rol -
          </option>
          <option value="Admin">Administrador</option>
          <option value="Observer">Observador</option>
          <option value="EPA">EPA</option>
        </select>
      );
    };

    const SeleccionTenants = ({ tenants }) => {
      return (
          <select
            required
            className="custom-select"
            name="tenant"
            id="tenant"
            onChange={this.handleChange}
            value={this.state.form.tenant}
          >
          <option value="" disabled>
          - Seleccionar tenant -
          </option>
          {tenants.map((tenant, index) => (
               <option value={tenant._id} key={index}>
                  {tenant.name}
              </option>
          ))}
      </select>
      );
  };
  const Tenants = ({ tenants }) => {
    
      return (
        <p className="mb-0" >
          {tenants}
        </p>
      );
  };
    return (
      <Box pt={4}>
        <Container>
          <Typography variant="h1" gutterBottom>
            Dispatchers
          </Typography>

          <Box width="100%" display="flex" mb={3}>
            <Box flexGrow="1">
              <TextFieldOutlined
                size="small"
                name="search"
                value={this.state.search}
                onChange={(e) => {
                  if (this.state.page !== 0) this.setState({ page: 0 });
                  this.setState({ search: e.target.value });
                }}
                variant="outlined"
                placeholder="Buscar..."
                style={{ minWidth: 250 }}
              />
            </Box>
            <Button
              color="secondary"
              variant="contained"
              data-toggle="modal"
              data-target="#crearModal"
              onClick={() => this.handleModal(1)}
            >
              Crear nuevo
            </Button>
          </Box>

          {/* <Dispatchers dispatchers={this.state.dispatchers} /> */}

          <TableContainer className="table-white">
            <Table aria-label="Dispatchers">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell align={column.align} key={index}>
                      <b>{column.label}</b>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.dispatchers ? (
                  this.state.dispatchers.length === 0 ? (
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell colSpan={columns.length} align="center">
                        <em>- No existen dispatchers creados -</em>
                      </TableCell>
                    </TableRow>
                  ) : (
                    this.state.dispatchers
                      .filter((dispatcher) => {
                        let bandera = false;
                        if (
                          dispatcher.name_dispatcher
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase()) ||
                          dispatcher.mail_dispatcher
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase()) ||
                          dispatcher.role_dispatcher
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase()) ||
                          dispatcher._id
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase())
                        ) {
                          bandera = true;
                        }
                        return bandera;
                      })
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, i) => (
                        <TableRow hover key={i}>
                          <TableCell align="left">
                            <CopyText text={row._id} />
                          </TableCell>
                          <TableCell align="left">
                            <ImagenDispatcher imagen={row.image_dispatcher} />
                          </TableCell>
                          <TableCell align="left">
                            {row.name_dispatcher}
                          </TableCell>
                          <TableCell align="left">
                            {row.mail_dispatcher}
                            <br />
                            {"+ " + row.number_dispatcher}
                          </TableCell>
                          <TableCell align="left">
                            {row.role_dispatcher}
                          </TableCell>
                          <TableCell align="left">
                            <Clientes clientes={row.customer_dispatcher} />
                          </TableCell>
                          
                          <TableCell align="left"><Tenants tenants={row.tenant_name} /></TableCell>
                          <TableCell align="left">
                            <ActiveDot status={row.status_dispatcher} />
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              aria-label="update"
                              onClick={() => this.handleModal(2, row)}
                            >
                              <CreateIcon color="primary" />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() => this.confirmDelete(row._id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                  )
                ) : (
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    {columns.map((e, i) => (
                      <TableCell key={i}>
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="w-100 mb-4">
            <TablePagination
              rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todas" }]}
              labelRowsPerPage="Filas por página"
              labelDisplayedRows={({ from, to, count, page }) => {
                return from + " - " + to + " de " + count + " dispatchers";
              }}
              component="div"
              count={
                this.state.dispatchers
                  ? this.state.dispatchers.filter((dispatcher) => {
                      let bandera = false;
                      if (
                        dispatcher.name_dispatcher
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase()) ||
                        dispatcher.mail_dispatcher
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase()) ||
                        dispatcher.role_dispatcher
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase()) ||
                        dispatcher._id
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase())
                      ) {
                        bandera = true;
                      }
                      return bandera;
                    }).length
                  : 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </div>

          <div
            className="modal fade"
            id="modalFormulario"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-xl"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <Typography
                    variant="h5"
                    className="text-white"
                    id="editarModalLabel"
                  >
                    {tipoModal === 1
                      ? "Crear Dispatcher"
                      : "Modificar Dispatcher"}
                  </Typography>

                  <IconButton
                    aria-label="close"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => {
                      $("#modalFormulario").modal("hide");
                      this.emptyForm();
                    }}
                  >
                    <CloseIcon htmlColor="#ffffff" fontSize="large" />
                  </IconButton>
                </div>
                <form onSubmit={this.validarDispatcher}>
                  <div className="modal-body">
                    <div className="w-100 mb-3">
                      <label className="filebutton2 d-flex justify-content-start">
                        <img
                          src={this.state.form.image_dispatcher}
                          width="100"
                          height="100"
                          alt="Cliente"
                          className="icon-table"
                        />
                        <span>
                          <input
                            type="file"
                            id="image_dispatcher"
                            accept="image/*"
                            name="image_dispatcher"
                            onChange={this.handleImage}
                            className="icon-table"
                          />
                        </span>
                      </label>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Código</label>
                        {tipoModal === 1 ? (
                          <label className="texto-form-red-light"> *</label>
                        ) : (
                          false
                        )}

                        <input
                          disabled={tipoModal === 1 ? false : true}
                          required={tipoModal === 1 ? true : false}
                          onChange={this.handleChange}
                          type="text"
                          name="code_dispatcher"
                          className="form-control"
                          placeholder="Código"
                          value={this.state.form.code_dispatcher}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Dirección</label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          name="direction_dispatcher"
                          className="form-control"
                          placeholder="Dirección"
                          value={this.state.form.direction_dispatcher}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">DNI</label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          name="dni_dispatcher"
                          className="form-control"
                          placeholder="DNI"
                          maxLength={13}
                          value={this.state.form.dni_dispatcher}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">
                          Nombre del dispatcher
                        </label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          name="name_dispatcher"
                          className="form-control"
                          placeholder="Nombre"
                          value={this.state.form.name_dispatcher}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Teléfono</label>
                        <label className="texto-form-red-light"> *</label>
                        <PhoneInput
                          preferredCountries={["cl", "mx"]}
                          country={"cl"}
                          inputProps={{
                            name: "contact_phone_task",
                            required: true,
                            type: "text",
                            minLength: 9,
                          }}
                          className="form-control"
                          placeholder="989828918"
                          name="number_dispatcher"
                          value={this.state.form.number_dispatcher}
                          onChange={this.handlePhone}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Estado</label>
                        <label className="texto-form-red-light"> *</label>
                        <div className="form-row">
                          <div className="form-group mb-0 col-md-6">
                            <Button
                              type="button"
                              onClick={() => this.handleChangeButton(1)}
                              variant="contained"
                              color={
                                parseInt(this.state.form.status_dispatcher) ===
                                1
                                  ? "primary"
                                  : "default"
                              }
                              className="w-100 text-roboto-regular"
                            >
                              <div className="row m-0 w-100">
                                <div className="col-sm-3 px-0 d-flex justify-content-center align-items-center">
                                  <div className="dot activo"></div>
                                </div>
                                <div className="col-sm-9 px-0">Activo</div>
                              </div>
                            </Button>
                          </div>
                          <div className="form-group mb-0 col-md-6">
                            <Button
                              type="button"
                              onClick={() => this.handleChangeButton(2)}
                              variant="contained"
                              color={
                                parseInt(this.state.form.status_dispatcher) ===
                                2
                                  ? "primary"
                                  : "default"
                              }
                              className="w-100 text-roboto-regular"
                            >
                              <div className="row m-0 w-100">
                                <div className="col-sm-3 px-0 d-flex justify-content-center align-items-center">
                                  <div className="dot inactivo"></div>
                                </div>
                                <div className="col-sm-9 px-0">Inactivo</div>
                              </div>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Nacionalidad</label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          name="nationality_dispatcher"
                          className="form-control"
                          placeholder="Nacionalidad"
                          value={this.state.form.nationality_dispatcher}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Correo</label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          disabled={tipoModal === 1 ? false : true}
                          onChange={this.handleChange}
                          type="email"
                          name="mail_dispatcher"
                          className="form-control"
                          placeholder="Mail"
                          value={this.state.form.mail_dispatcher}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Rol</label>
                        <label className="texto-form-red-light"> *</label>
                        <Rol />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Password</label>
                        <input
                          required={false}
                          onChange={this.handleChange}
                          type="text"
                          name="password_dispatcher"
                          className="form-control"
                          placeholder="Password"
                          value={this.state.form.password_dispatcher}
                        />
                        <label className="txt-carta mb-0 text-right w-100 text-white">
                          Ingresar al menos 6 caracteres
                        </label>
                      </div>

                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Usuario Realm</label>
                        <input
                          required={false}
                          onChange={this.handleChange}
                          disabled={tipoModal === 1 ? false : true}
                          type="text"
                          name="realm_id"
                          className="form-control"
                          placeholder="Usuario Realm"
                          value={this.state.form.realm_id}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        {/*
                        <label className="texto-form mb-1">Tenant</label>
                        
                        <SeleccionTenants
                          tenants={this.state.form.tenants}
                        /> */}
                      </div>
                    </div>


                    <div className="form-row">
                      <div className="form-group col-md-12 mb-0">
                        <label className="texto-form mb-1 w-100">
                          Clientes
                        </label>
                        <SeleccionClientes
                          clientes={this.state.form.customer_dispatcher}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <Button color="secondary" variant="contained" type="submit">
                      {tipoModal === 1 ? "Crear" : "Modificar"}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Cargando cargando={this.state.cargando} />
        </Container>
      </Box>
    );
  }

  validar = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  validarDispatcher = (event) => {
    this.handleCargando(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.validar(event);
      this.handleCargando(false);
    } else {
      this.validar(event);
      if (this.state.tipoModal === 1) {
        this.createDispatcher();
      } else {
        this.modifyDispatcher();
      }
    }
  };
}

export default Dispatchers;
