import React, { memo } from "react";
import memoize from "memoize-one";
import moment from "moment";
import clsx from "clsx";

import { withStyles } from "@material-ui/core/styles";
import { FixedSizeList as List, areEqual } from "react-window";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import IconButton from "@material-ui/core/IconButton";

import IconoFlechaAzul from "../../icons/icono-flecha-derecha-blue2.png";
import IconoAtrasado from "../../icons/clock.svg";
import IconoEnRiesgo from "../../icons/exclamation.svg";
import Utils from "../../utils/Utils";
import { ID_RECHAZADO, ID_CANCELADO, ID_EXITOSO } from "../../utils/Helpers";

import FileCopyIcon from "@material-ui/icons/FileCopy";

const createGrupoData = memoize(
  (items, toggleItemActive, skipedDimensions, tasksGroup, rayos, classes) => ({
    items,
    toggleItemActive,
    skipedDimensions,
    tasksGroup,
    rayos,
    classes,
  })
);

function ListaGrupoDeTareas({
  grupos,
  index,
  width,
  toggleItemActive,
  skipedDimensions,
  tasksGroup,
  rayos,
  filtro_tareas,
  seleccionDesdeMapa,
  classes,
}) {
  let filterGrupos = grupos.filter((grupo) => {
    let data = "true";
    let bandera = false;
    if (seleccionDesdeMapa) {
      if (
        grupo.selected.toString().toLowerCase().includes(data.toLowerCase()) &&
        (grupo.contact_name_taskgroup
          .toLowerCase()
          .includes(filtro_tareas.toLowerCase()) ||
          grupo._id.toLowerCase().includes(filtro_tareas.toLowerCase()) ||
          grupo.orden_id_taskgroup
            .toLowerCase()
            .includes(filtro_tareas.toLowerCase()))
      ) {
        return true;
      }
    } else {
      if (
        grupo.contact_name_taskgroup
          .toLowerCase()
          .includes(filtro_tareas.toLowerCase()) ||
        grupo._id.toLowerCase().includes(filtro_tareas.toLowerCase()) ||
        grupo.orden_id_taskgroup
          .toLowerCase()
          .includes(filtro_tareas.toLowerCase())
      ) {
        return true;
      }
    }

    grupo.tasks.forEach((tarea) => {
      if (seleccionDesdeMapa) {
        if (
          grupo.selected
            .toString()
            .toLowerCase()
            .includes(data.toLowerCase()) &&
          (tarea.order_id_task
            .toLowerCase()
            .includes(filtro_tareas.toLowerCase()) ||
            tarea.address_task
              .toLowerCase()
              .includes(filtro_tareas.toLowerCase()) ||
            tarea._id.toLowerCase().includes(filtro_tareas.toLowerCase()) ||
            tarea.contact_name_task
              .toLowerCase()
              .includes(filtro_tareas.toLowerCase()) ||
            grupo.contact_name_taskgroup
              .toLowerCase()
              .includes(filtro_tareas.toLowerCase()) ||
            grupo._id.toLowerCase().includes(filtro_tareas.toLowerCase()) ||
            grupo.orden_id_taskgroup
              .toLowerCase()
              .includes(filtro_tareas.toLowerCase()))
        ) {
          bandera = true;
          return;
        }
      } else {
        if (
          tarea.order_id_task
            .toLowerCase()
            .includes(filtro_tareas.toLowerCase()) ||
          tarea.address_task
            .toLowerCase()
            .includes(filtro_tareas.toLowerCase()) ||
          tarea._id.toLowerCase().includes(filtro_tareas.toLowerCase()) ||
          tarea.contact_name_task
            .toLowerCase()
            .includes(filtro_tareas.toLowerCase()) ||
          grupo.contact_name_taskgroup
            .toLowerCase()
            .includes(filtro_tareas.toLowerCase()) ||
          grupo._id.toLowerCase().includes(filtro_tareas.toLowerCase()) ||
          grupo.orden_id_taskgroup
            .toLowerCase()
            .includes(filtro_tareas.toLowerCase())
        ) {
          bandera = true;
          return;
        }
      }
    });

    return bandera;
  });

  const itemData = createGrupoData(
    filterGrupos,
    toggleItemActive,
    skipedDimensions,
    tasksGroup,
    rayos,
    classes
  );
  let height = window.innerHeight - 64 - 99 - 42;

  return (
    <List
      itemCount={filterGrupos.length}
      itemData={itemData}
      height={height}
      width={width}
      itemSize={180}
    >
      {ItemGrupoDeTarea}
    </List>
  );
}

const ItemGrupoDeTarea = memo(({ data, index, style }) => {
  const { items, toggleItemActive, tasksGroup, rayos, classes } = data;
  const item = items[index];
  let hoy = new Date();
  let fechaT = moment(item.start_date_taskgroup).add(3, "hour");
  let fechaR = moment(fechaT).subtract(10, "minutes");
  let fechaGT = new Date(Date.parse(fechaT));
  let fechaRetraso = new Date(Date.parse(fechaR));
  let atrasado = false;
  let enRiesgo = false;

  if (hoy > fechaGT) {
    atrasado = true;
  }
  if (hoy > fechaRetraso) {
    enRiesgo = true;
  }
  
  return (
    <div
      style={{ top: style.top, position: style.position }}
      key={item.id}
      className="w-100 tarjeta-gt-box"
    >
      <div className={clsx("row m-0 tarjeta-gt", classes.tarjetaGT)}>
        <div id={item.orden_id_taskgroup} className="ver-copiado-gt hidden">
          <label>¡Copiado!</label>
        </div>
        <div className="check-box d-flex align-items-center justify-content-center">
          <Checkbox
            color="primary"
            value="checkedA"
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            icon={
              <CheckBoxOutlineBlankIcon
                className={classes.checkBox}
                fontSize="small"
              />
            }
            className="p-0"
            checked={item.selected}
            onClick={(e) => toggleItemActive(e, item)}
          />
        </div>
        <div
          className={
            "rayo-box d-flex align-items-center justify-content-center"
          }
        >
          {item.idrayo_taskgroup === null ||
          rayos === undefined ||
          rayos === null ? (
            <div>
              <div className={classes.scuareRayo}></div>
              <p className="txt-small">GT sin</p>
              <p className="txt-small">Rayo</p>
            </div>
          ) : (
            rayos.map((rayo, i) =>
              rayo._id === item.idrayo_taskgroup ? (
                <div key={i}>
                  <img src={rayo.image_rayo} alt="rayo" />

                  <p className="txt-small">{rayo.name_rayo}</p>
                </div>
              ) : (
                false
              )
            )
          )}
        </div>

        {item.tasks.length === 1 ? (
          <div className="type-task-box">
            <IconButton
              aria-label="copy"
              size="small"
              style={{ marginLeft: 5, marginTop: 3 }}
              onClick={() => Utils.justCopyText(item.orden_id_taskgroup)}
            >
              <FileCopyIcon style={{ fontSize: 13 }} />
            </IconButton>
            <div className={clsx("line-type-task", classes.lineTypeTask)}></div>
            <div
              className={
                "dot-type-task-1 " +
                Utils.colorTipoTarea(item.tasks[0].type_task, true)
              }
            >
              {Utils.colorTipoTarea(item.tasks[0].type_task, false)}
            </div>
            <div
              className={clsx(
                "dot-type-task-bottom",
                classes.dotTypeTaskBottom
              )}
            ></div>
          </div>
        ) : item.tasks.length > 1 ? (
          <div className="type-task-box">
            <IconButton
              aria-label="copy"
              size="small"
              style={{ marginLeft: 5, marginTop: 3 }}
              onClick={() => Utils.justCopyText(item.orden_id_taskgroup)}
            >
              <FileCopyIcon style={{ fontSize: 13 }} />
            </IconButton>
            <div
              className={clsx("line-type-tasks", classes.lineTypeTasks)}
            ></div>
            {item.tasks.length > 2 ? (
              <div
                className={clsx(
                  "line-type-more-tasks",
                  classes.lineTypeMoreTasks
                )}
              ></div>
            ) : (
              false
            )}

            {item.tasks.map((task, i) => {
              if (i === 0) {
                return (
                  <div
                    className={
                      "dot-type-task-1 " +
                      Utils.colorTipoTarea(task.type_task, true)
                    }
                    key={i}
                  >
                    {Utils.colorTipoTarea(task.type_task, false)}
                  </div>
                );
              } else if (i === 1) {
                return (
                  <div
                    className={
                      "dot-type-task-2 " +
                      Utils.colorTipoTarea(task.type_task, true)
                    }
                    key={i}
                  >
                    {Utils.colorTipoTarea(task.type_task, false)}
                  </div>
                );
              } else {
                return false;
              }
            })}
          </div>
        ) : (
          <div className="type-task-box">
            <IconButton
              aria-label="copy"
              size="small"
              style={{ marginLeft: 5, marginTop: 3 }}
              onClick={() => Utils.justCopyText(item.orden_id_taskgroup)}
            >
              <FileCopyIcon style={{ fontSize: 13 }} />
            </IconButton>
          </div>
        )}

        <div className="info-box d-flex align-items-center">
          <p className="order-id-box text-ellipsis">
            {item.orden_id_taskgroup}
          </p>

          <div className="w-100">
            {item.tasks.map((task, i) => {
              if (i < 2) {
                return (
                  <div className={i === 0 ? "mb-3" : ""} key={i}>
                    <p className="text-exo-bold">
                      {moment(task.date_task)
                        .utcOffset("+00")
                        .format("DD-MM-YYYY HH:mm")}
                    </p>
                    <p>{task.contact_name_task}</p>
                    <p className="text-ellipsis color-light-grey">
                      {task.address_task}
                    </p>
                    {task.zone_name ? (
                      <p className="text-ellipsis color-light-grey">
                        <b>Zona:&nbsp;</b>
                        {task.zone_name}
                      </p>
                    ) : (
                      <p className="text-ellipsis color-light-grey ">
                        <b>Zona:&nbsp;</b>
                        <em>No definida</em>
                      </p>
                    )}
                  </div>
                );
              } else {
                return false;
              }
            })}
          </div>

          <div
            className={
              "btn-estado " + Utils.colorTarea(item.idstatus_taskgroup, true)
            }
          >
            {Utils.colorTarea(item.idstatus_taskgroup, false)}
          </div>

          {enRiesgo &&
          atrasado &&
          item.idstatus_taskgroup !== ID_CANCELADO &&
          item.idstatus_taskgroup !== ID_EXITOSO &&
          item.idstatus_taskgroup !== ID_RECHAZADO ? (
            <div className="atrasado">
              <img src={IconoAtrasado} alt="atrasado" />
              Atrasado
            </div>
          ) : enRiesgo &&
            item.idstatus_taskgroup !== ID_CANCELADO &&
            item.idstatus_taskgroup !== ID_EXITOSO &&
            item.idstatus_taskgroup !== ID_RECHAZADO ? (
            <div className="en-riesgo">
              <img src={IconoEnRiesgo} alt="en riesgo" />
              En Riesgo
            </div>
          ) : (
            false
          )}

         
          <p className="customer-box">
            {item.customer.length !== 0
              ? item.customer[0].name_customer
              : false}
            {item.tags ? " || #" + item.tags : false}<br/>
          </p>
        </div>
        <div className="detalle-box d-flex align-items-center">
          <img
            src={IconoFlechaAzul}
            onClick={() => tasksGroup(item._id)}
            alt="flecha"
          />
        </div>
      </div>
    </div>
  );
}, areEqual);

const styles = (theme) => ({
  tarjetaGT: {
    backgroundColor: theme.palette.success.main,
  },
  checkBox: {
    color: theme.palette.success.dark,
  },
  scuareRayo: {
    backgroundColor: theme.palette.success.dark,
    borderRadius: 5,
    height: 40,
    width: 40,
    marginBottom: 5,
  },
  dotTypeTaskBottom: {
    backgroundColor: theme.palette.success.dark,
  },
  lineTypeTask: {
    borderLeft: "2px solid " + theme.palette.success.dark,
  },
  lineTypeTasks: {
    borderLeft: "2px solid " + theme.palette.success.dark,
  },
  lineTypeMoreTasks: {
    borderLeft: "2px dotted " + theme.palette.success.dark,
  },
});

export default withStyles(styles, { withTheme: true })(ListaGrupoDeTareas);
