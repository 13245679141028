import React, { useEffect, useState } from "react";

import axios from "axios";
import MarkerClusterer from "@google/markerclustererplus";
import * as Realm from "realm-web";

import url from "../../../config";
import { MAP_CONFIG } from "../../utils/Helpers";
import Utils from "../../utils/Utils";

const APPCONFIG = { id: "triggers_realmapp-dqdra", timeout: 100000 };
const SERVICE_NAME = "Cluster0";

// Icono RAYO ------------------------------------------->
const iconRayo = {
  url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayo-verde.png",
  scaledSize: new window.google.maps.Size(20, 20),
  origin: new window.google.maps.Point(0, 0),
};

// Iconos TASK GROUPS ----------------------------------->
const iconPickUp = {
  url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-p.png",
  scaledSize: new window.google.maps.Size(23, 30),
  origin: new window.google.maps.Point(0, 0),
};
const iconDelivery = {
  url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-d.png",
  scaledSize: new window.google.maps.Size(23, 30),
  origin: new window.google.maps.Point(0, 0),
};
const iconCita = {
  url: "https://storage.cloud.google.com/fleet-manager-env.appspot.com/Assets/icono-c-2.png",
  scaledSize: new window.google.maps.Size(23, 30),
  origin: new window.google.maps.Point(0, 0),
};
const iconDevolucion = {
  url: "https://storage.cloud.google.com/fleet-manager-env.appspot.com/Assets/icono-dv.png",
  scaledSize: new window.google.maps.Size(23, 30),
  origin: new window.google.maps.Point(0, 0),
};

const RefactorGoogleMap = ({
  _handleClickCluster,
  _handleClickMarker,
  centerRayo,
  detalleRayo,
  center,
  rayo,
}) => {
  const [mapaRef, setMapaRef] = useState(undefined);
  const [rayosRef, setRayosRef] = useState(undefined);

  // CENTER RAYO LOCATION ---------------------------->
  useEffect(() => {
    if (centerRayo && detalleRayo) {
      handleCenter(center);
    }
  }, [centerRayo, detalleRayo]);

  const handleCenter = (center) => {
    let bounds = new window.google.maps.LatLngBounds();
    let myLatLng = new window.google.maps.LatLng(center.lat, center.lng);

    bounds.extend(myLatLng);
    if (mapaRef && rayosRef) {
      mapaRef.fitBounds(bounds);
      let index = rayosRef.findIndex(
        (element) => element.placeName === rayo._id
      );
      if (index >= 0) {
        window.google.maps.event.trigger(rayosRef[index], "click", {
          latLng: new window.google.maps.LatLng(0, 0),
        });
      }
    }
  };

  // INITIALIZE COMPONENT ----------------------------->
  useEffect(() => {
    initializeComponent();
  }, []);

  const initializeComponent = () => {
    let usuario = JSON.parse(sessionStorage.getItem("user"));
    axios
      .get(
        "https://voltbackendservices.rayoapp.com/Rayos/rayosBy/reduced?vigencia=true", {
          headers: { tenant: usuario.tenant }
        }
      )
      .then((resultRayos) => {
        let rayos = resultRayos.data;
        axios.get(urlTaskGroups(), { headers: { tenant: usuario.tenant } }).then((resultGt) => {
          let taskGroups = resultGt.data;
          instance_realm(rayos, taskGroups);
        });
      });
  };

  const instance_realm = async (rayos, taskGroups) => {
    try {
      // Instancia MAPA DE GOOGLE -----------------------------
      let mapa = new window.google.maps.Map(
        document.getElementById("googlemaps"),
        MAP_CONFIG
      );
      setMapaRef(mapa);

      // Instancia markers RAYOS ------------------------------>
      let bounds = new window.google.maps.LatLngBounds();
      let markers = [];
      rayos.forEach((marker, i) => {
        if (marker) {
          if (marker.status_rayo === 1 || marker.status_rayo === 3) {
            marker.map = mapa;
            let marca = {
              icon: iconRayo,
              position: {
                lat: parseFloat(marker.latitude_rayo),
                lng: parseFloat(marker.longitude_rayo),
              },
              map: marker.map,
              title: marker.name_rayo,
              placeName: marker._id,
            };
            const newMarker = new window.google.maps.Marker(marca);
            const infowindow = new window.google.maps.InfoWindow({
              closeBoxURL: marker._id,
              content: infoWindowRayo(marker),
            });

            newMarker.addListener("click", () => {
              infowindow.open(mapa, newMarker);
            });
            window.google.maps.event.addListener(mapa, "click", () => {
              infowindow.close();
            });

            newMarker.setMap(mapa);
            markers.push(newMarker);
          }
        }
      });
      setRayosRef(markers);

      // Instancia markers TASK GROUPS ------------------------>
      let markersTG = [];
      taskGroups.forEach((taskGroup) => {
        taskGroup.tasks.forEach((element) => {
          let lat = element.latitude_task;
          let lng = element.longitude_task;
          let marca = {
            icon: iconPickUp,
            position: { lat: parseFloat(lat), lng: parseFloat(lng) },
            map: mapa,
            title: element.order_id_task,
            placeName: taskGroup._id,
            task: element._id,
            type: element.type_task,
            contact: element.contact_name_task,
          };

          if (element.type_task === 2) {
            marca.icon = iconDelivery;
          } else if (element.type_task === 3) {
            marca.icon = iconCita;
          } else if (element.type_task === 4) {
            marca.icon = iconDevolucion;
          }
          if (lat && lng) {
            let myLatLng = new window.google.maps.LatLng(
              marca.position.lat,
              marca.position.lng
            );
            const newMarker = new window.google.maps.Marker(marca);
            newMarker.addListener("click", (e) =>
              _handleClickMarker(e, marca.placeName)
            );

            bounds.extend(myLatLng);
            newMarker.setMap(mapa);
            markersTG.push(newMarker);
          }
        });
      });

      let cluster = new MarkerClusterer(mapa, markersTG, {
        imagePath:
          "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        gridSize: 45,
      });
      window.google.maps.event.addListener(
        cluster,
        "clusterclick",
        _handleClickCluster
      );

      let app = new Realm.App(APPCONFIG);
      try {
        if (app.users.length > 0) {
          await Promise.all(
            app.users.map(async (user, index) => {
              await user.logOut();
              await app.removeUser(user);
            })
          );
        }
      } catch (error) {}

      app.logIn(Realm.Credentials.anonymous()).then((user) => {
        let options = {
          filter: {
            operationType: "update",
            $or: [
              {
                "updateDescription.updatedFields.status_rayo": {
                  $exists: true,
                },
              },
              {
                "updateDescription.updatedFields.vigencia": { $exists: true },
              },
              {
                "updateDescription.updatedFields.latitude_rayo": {
                  $exists: true,
                },
              },
              {
                "updateDescription.updatedFields.longitude_rayo": {
                  $exists: true,
                },
              },
            ],
          },
        };
        let watchRayos = app.currentUser
          .mongoClient(SERVICE_NAME)
          .db("fleet_manager")
          .collection("rayos")
          .watch(options);

        function nextWatch() {
          watchRayos.next().then((result) => {
            if (result.value.operationType === "update") {
              let idRayo = Utils.getBSONtoString(result.value.documentKey._id);
              let rayoWatch = result.value.fullDocument;
              markers.forEach((element) => {
                if (element.placeName === idRayo) {
                  
                  let ms_per_frame = 40;
                  let max_frames = 1250;
                  let from = { lat: element.getPosition().lat(), lng: element.getPosition().lng() }
                  let to = { lat: rayoWatch.latitude_rayo, lng: rayoWatch.longitude_rayo }

                  fetch("https://voltbackendservices.rayoapp.com/Rayos/route/frame", {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ from, to, max_frames })
                  })
                  .then(response => response.json())
                  .then((result) => {
                    if (result.status === 200) {
                      result.data.frames.map((f, i) => {
                        setTimeout(() => {
                          element.setPosition(new window.google.maps.LatLng(f.lat, f.lng))
                        }, i * ms_per_frame)
                      })
                    } else {
                      element.setPosition(
                        new window.google.maps.LatLng(
                          rayoWatch.latitude_rayo,
                          rayoWatch.longitude_rayo
                        )
                      );
                    }
                  })
                  .catch((error) => {
                    element.setPosition(
                      new window.google.maps.LatLng(
                        rayoWatch.latitude_rayo,
                        rayoWatch.longitude_rayo
                      )
                    );
                  })
                }
              });
            }
            nextWatch();
          });
        }

        nextWatch();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const infoWindowRayo = (rayo) => {
    const contentString =
      '<div id="content">' +
      '<div class="row m-0">' +
      '<div class="d-inline d-flex align-items-center mb-1 ">' +
      '<div class="m-0 ' +
      estadoRayo(rayo.status_rayo) +
      '"></div>' +
      "</div>" +
      '<label class="text-exo-bold mb-1 pl-2">' +
      rayo.name_rayo +
      "</label>" +
      "</div>" +
      '<div id="bodyContent">' +
      '<p class="text-roboto-regular mb-0"> ID: ' +
      rayo._id +
      "</p>" +
      '<p class="text-roboto-regular mb-0"> DNI: ' +
      rayo.dni_rayo +
      "</p>" +
      '<p class="text-roboto-regular mb-0"> Número de contacto: + ' +
      rayo.number_rayo +
      "</p>" +
      '<p class="text-roboto-regular mb-0"> Email: ' +
      rayo.email_rayo +
      "</p>" +
      "</div>" +
      "</div>";

    return contentString;
  };

  const estadoRayo = (status) => {
    if (status === 1) {
      return "dot activo";
    } else if (status === 3) {
      return "dot activo";
    } else {
      return "dot inactivo";
    }
  };

  const urlTaskGroups = () => {
    let filtrosGlobales = JSON.parse(sessionStorage.getItem("filtros"));
    let usuario = JSON.parse(sessionStorage.getItem("user"));

    let query = "/Task-Groups/tasks_group/reduced/by?";
    if (usuario)
      if (usuario.type === 2)
        query = "/Task-Groups/tasks_group/epa/reduced/by?";

    let stringifyFiltro = Utils.stringifyFilters(
      "/Home/Mapa",
      0,
      filtrosGlobales
    );

    if (usuario)
      if (usuario.type === 2)
        stringifyFiltro = stringifyFiltro + Utils.getFilterIdProviderEPA();

    return url.host + query + stringifyFiltro;
  };

  return <div className="home-screen w-100 h-100" id="googlemaps"></div>;
};

export default RefactorGoogleMap;
