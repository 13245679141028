export const GUARDAR_TIEMPO = 'GUARDAR_TIEMPO';
export const guardarTiempo = (tiempo) => {
    return async (dispatch) => {
        dispatch({ type: GUARDAR_TIEMPO, tiempo: tiempo });
    };
};

export const GUARDAR_RAYO = 'GUARDAR_RAYO';
export const guardarRayo = (rayo) => {
    return async (dispatch) => {
        dispatch({ type: GUARDAR_RAYO, rayo: rayo });
    };
};
