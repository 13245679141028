require("dotenv").config();
const axios = require("axios");

// AMBIENTE VOLT
const config = {
  api: axios.create({ baseURL: "https://voltbackendservices.rayoapp.com" }),
  //api: axios.create({ baseURL: "https://34.107.135.27" }),
  mode: "CORS",
  headers: { "Access-Control-Allow-Origin": "*", "Content-Type": "application/json" },
  host: "https://voltbackendservices.rayoapp.com",
  hostSocket: "https://voltbackendservices.rayoapp.com",
};


module.exports = config;
