import React from "react";
import $ from "jquery";

import Button from "@material-ui/core/Button";
import { FormControl, MenuItem, Select } from "@material-ui/core";

import {
  ID_NO_ASIGNADO,
  ID_ASIGNADO,
  ID_ACEPTADO,
  ID_INICIADO,
  ID_EN_PROGRESO,
} from "../../utils/Helpers";
import CustomExpandMore from "../../components/custom/CustomExpandMore";

class ReAbrirTarea extends React.Component {
  render() {
    return (
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal-white">
          <div className="modal-body">
            <h4 className="h4-modal text-center">Re abrir tarea</h4>
            <h4 className="text-roboto-regular color-mega-dark-grey text-center">
              ID de Tarea
            </h4>
            <input
              defaultValue={
                this.props.data === null ? "" : this.props.data.code_task
              }
              disabled
              className="form-control input-id-grupo mb-3"
            />

            <label className="text-roboto-regular color-mega-dark-grey mb-1">
              Selecciona estado
            </label>
            <FormControl variant="filled" className="w-100">
              <Select
                className="select-crear select-modal m-estados color-mega-dark-grey p-0"
                id="estadoReabrir"
                name="estadoReabrir"
                value={this.props.estadoReabrir}
                onChange={this.props.handleChange}
                IconComponent={CustomExpandMore}
              >
                <MenuItem className="row m-0" value={ID_NO_ASIGNADO}>
                  <div className="col-3 d-flex align-items-center">
                    <div className="dot back-medium-grey"></div>
                  </div>
                  <div className="col-9 pl-0 d-flex align-items-center">
                    No asignado
                  </div>
                </MenuItem>
                <MenuItem className="row m-0" value={ID_ASIGNADO}>
                  <div className="col-3 d-flex align-items-center">
                    <div className="dot dot-asignado"></div>
                  </div>
                  <div className="col-9 pl-0 d-flex align-items-center">
                    Asignado
                  </div>
                </MenuItem>
                <MenuItem className="row m-0" value={ID_ACEPTADO}>
                  <div className="col-3 d-flex align-items-center">
                    <div className="dot dot-aceptado"></div>
                  </div>
                  <div className="col-9 pl-0 d-flex align-items-center">
                    Aceptado
                  </div>
                </MenuItem>
                <MenuItem className="row m-0" value={ID_INICIADO}>
                  <div className="col-3 d-flex align-items-center">
                    <div className="dot dot-iniciado"></div>
                  </div>
                  <div className="col-9 pl-0 d-flex align-items-center">
                    Iniciado
                  </div>
                </MenuItem>
                <MenuItem className="row m-0" value={ID_EN_PROGRESO}>
                  <div className="col-3 d-flex align-items-center">
                    <div className="dot dot-en-progreso"></div>
                  </div>
                  <div className="col-9 pl-0 d-flex align-items-center">
                    En Progreso
                  </div>
                </MenuItem>
              </Select>
            </FormControl>

            <label className="text-roboto-regular color-mega-dark-grey mb-1 mt-2">
              Selecciona el motivo
            </label>
            <FormControl variant="filled" className="w-100">
              <Select
                className="select-crear select-modal m-estados color-mega-dark-grey p-0"
                id="motivoReabrir"
                name="motivoReabrir"
                value={this.props.motivoReabrir}
                onChange={this.props.handleChange}
                IconComponent={CustomExpandMore}
              >
                <MenuItem value="Error de cierre">Error de cierre</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="modal-footer row m-0">
            <div className="col-6 d-flex justify-content-center">
              <Button
                type="button"
                variant="text"
                color="primary"
                onClick={() => $("#modal").modal("hide")}
              >
                Cancelar
              </Button>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                onClick={this.props.reAbrir}
              >
                Confirmar
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReAbrirTarea;
