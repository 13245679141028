import React from "react";

import {
  ACT_CODIGO_BARRAS,
  ACT_FIRMA,
  ACT_FOTO,
  ACT_NOTA,
  ACT_CODIGO_QR,
  ACT_DROPDOWN,
  ACT_SECRETO,
} from "../../utils/Helpers";

import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PublishIcon from "@material-ui/icons/Publish";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  containAccordion: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: 5,
    marginTop: 10,
  },
  boxRadio: {
    backgroundColor: "#f2f2f2",
    padding: ".5rem 1rem",
    borderRadius: 4,
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
  },
}));

const AccordionTitle = ({ icon, text, description }) => {
  return (
    <>
      <Box
        height={30}
        width={35}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <img src={icon} height="30" alt="barcode icon" />
      </Box>
      <Box height={30} maxWidth={321} display="flex" alignItems="center" ml={1}>
        <div className="w-100">
          <Typography>{text}</Typography>
          <span className="sub-txt-carta text-ellipsis">{description}</span>
        </div>
      </Box>
    </>
  );
};

const IngresarTemplate = ({
  ingresarEvidencia,
  handleChangeCheck,
  template,
  optionsDropdown,
  typeTemplate,
  handleChange,
  handleChangeImg,
}) => {
  const classes = useStyles();
  return (
    <Box mt={3} id="ingresarTemplate">
      <FormControlLabel
        disabled={!template ? true : false}
        control={
          <Checkbox
            checked={ingresarEvidencia}
            onChange={handleChangeCheck}
            name="ingresarEvidencia"
          />
        }
        label="¿Ingresar evidencias?"
      />

      {!template ? (
        <Box width="100%" display="flex" justifyContent="center">
          <CircularProgress
            size="small"
            color="primary"
            style={{ height: 20, width: 20 }}
          />
        </Box>
      ) : template.length === 0 ? (
        <p className="color-grey text-center">
          <em>- No posee actividades para ingresar evidencias -</em>
        </p>
      ) : (
        <Box className={classes.containAccordion}>
          {template.map((activity, i) => {
            if (activity.idTypeActivity === ACT_CODIGO_BARRAS.id) {
              return (
                <Accordion disabled={!ingresarEvidencia} elevation={0} key={i}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="content-barcode"
                    id="header-barcode"
                  >
                    <AccordionTitle
                      icon={ACT_CODIGO_BARRAS.img}
                      text={activity.field}
                      description={activity.descriptionTypeActivity}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <TextField
                      name="codigoBarra"
                      placeholder="Código de barras"
                      variant="outlined"
                      size="small"
                      className="w-100 outline-primary"
                      value={activity.data}
                      onChange={(e) =>
                        handleChange(e, activity._id, typeTemplate)
                      }
                    />
                  </AccordionDetails>
                </Accordion>
              );
            } else if (activity.idTypeActivity === ACT_FIRMA.id) {
              return (
                <Accordion disabled={!ingresarEvidencia} elevation={0} key={i}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="content-signature"
                    id="header-signature"
                  >
                    <AccordionTitle
                      icon={ACT_FIRMA.img}
                      text={activity.field}
                      description={activity.descriptionTypeActivity}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    {activity.data ? (
                      <Button
                        variant="contained"
                        component="label"
                        className="w-100"
                      >
                        <img
                          src={activity.data}
                          className="w-100"
                          alt="firma"
                        />
                        <input
                          type="file"
                          hidden
                          accept="image/*"
                          name="firma"
                          onChange={(e) =>
                            handleChangeImg(e, activity._id, typeTemplate)
                          }
                        />
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        component="label"
                        className="w-100"
                      >
                        <Box height={30} display="flex" alignItems="center">
                          <PublishIcon />
                        </Box>
                        <Box
                          height={30}
                          display="flex"
                          alignItems="center"
                          ml={1}
                        >
                          <Typography>Subir firma</Typography>
                        </Box>
                        <input
                          type="file"
                          hidden
                          accept="image/*"
                          name="firma"
                          onChange={(e) =>
                            handleChangeImg(e, activity._id, typeTemplate)
                          }
                        />
                      </Button>
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            } else if (activity.idTypeActivity === ACT_FOTO.id) {
              return (
                <Accordion disabled={!ingresarEvidencia} elevation={0} key={i}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="content-photo"
                    id="header-photo"
                  >
                    <AccordionTitle
                      icon={ACT_FOTO.img}
                      text={activity.field}
                      description={activity.descriptionTypeActivity}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    {activity.data ? (
                      <Button
                        variant="contained"
                        component="label"
                        className="w-100"
                      >
                        <img src={activity.data} className="w-100" alt="foto" />
                        <input
                          type="file"
                          hidden
                          accept="image/*"
                          name="foto"
                          onChange={(e) =>
                            handleChangeImg(e, activity._id, typeTemplate)
                          }
                        />
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        component="label"
                        className="w-100"
                      >
                        <Box height={30} display="flex" alignItems="center">
                          <PublishIcon />
                        </Box>
                        <Box
                          height={30}
                          display="flex"
                          alignItems="center"
                          ml={1}
                        >
                          <Typography>Subir foto</Typography>
                        </Box>
                        <input
                          type="file"
                          hidden
                          accept="image/*"
                          name="foto"
                          onChange={(e) =>
                            handleChangeImg(e, activity._id, typeTemplate)
                          }
                        />
                      </Button>
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            } else if (activity.idTypeActivity === ACT_NOTA.id) {
              return (
                <Accordion disabled={!ingresarEvidencia} elevation={0} key={i}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="content-note"
                    id="header-note"
                  >
                    <AccordionTitle
                      icon={ACT_NOTA.img}
                      text={activity.field}
                      description={activity.descriptionTypeActivity}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <TextField
                      name="nota"
                      placeholder="Nota"
                      variant="outlined"
                      size="small"
                      className="w-100 outline-primary"
                      multiline
                      rows="3"
                      value={activity.data}
                      onChange={(e) =>
                        handleChange(e, activity._id, typeTemplate)
                      }
                    />
                  </AccordionDetails>
                </Accordion>
              );
            } else if (activity.idTypeActivity === ACT_CODIGO_QR.id) {
              return (
                <Accordion disabled={!ingresarEvidencia} elevation={0} key={i}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="content-qr"
                    id="header-qr"
                  >
                    <AccordionTitle
                      icon={ACT_CODIGO_QR.img}
                      text={activity.field}
                      description={activity.descriptionTypeActivity}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <TextField
                      name="codigoQR"
                      placeholder="Código de barras"
                      variant="outlined"
                      size="small"
                      className="w-100 outline-primary"
                      value={activity.data}
                      onChange={(e) =>
                        handleChange(e, activity._id, typeTemplate)
                      }
                    />
                  </AccordionDetails>
                </Accordion>
              );
            } else if (activity.idTypeActivity === ACT_DROPDOWN.id) {
              return (
                <Accordion disabled={!ingresarEvidencia} elevation={0} key={i}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="content-dropdown"
                    id="header-dropdown"
                  >
                    <AccordionTitle
                      icon={ACT_DROPDOWN.img}
                      text={activity.field}
                      description={activity.descriptionTypeActivity}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <RadioGroup
                      aria-label="options-dropdown"
                      name="dropdown"
                      className={classes.boxRadio}
                      value={activity.data}
                      onChange={(e) =>
                        handleChange(e, activity._id, typeTemplate)
                      }
                    >
                      {optionsDropdown.map((option, i) => (
                        <FormControlLabel
                          value={option}
                          label={option}
                          key={i}
                          control={<Radio />}
                        />
                      ))}
                    </RadioGroup>
                  </AccordionDetails>
                </Accordion>
              );
            } else if (activity.idTypeActivity === ACT_SECRETO.id) {
              return (
                <Accordion disabled={!ingresarEvidencia} elevation={0} key={i}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="dropdown-secret"
                    id="dropdown-secret"
                  >
                    <AccordionTitle
                      icon={ACT_SECRETO.img}
                      text={activity.field}
                      description={activity.descriptionTypeActivity}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <TextField
                      name="secreto"
                      placeholder="Secreto"
                      variant="outlined"
                      size="small"
                      className="w-100 outline-primary"
                      value={activity.data}
                      onChange={(e) =>
                        handleChange(e, activity._id, typeTemplate)
                      }
                    />
                  </AccordionDetails>
                </Accordion>
              );
            } else {
              return false;
            }
          })}
        </Box>
      )}
    </Box>
  );
};

export default IngresarTemplate;
