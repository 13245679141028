import React from "react";
import clsx from "clsx";
import moment from "moment";
import axios from "axios";
import { connect } from "react-redux";
import DayPicker from "react-day-picker";
import url from "../../../config";
import { Link } from "react-router-dom";

import * as rayosAccion from "../../../store/actions/Rayos";
import * as actionClientes from "../../../store/actions/Clientes";
import * as proveedoresAccion from "../../../store/actions/Proveedores";
import * as grupoTareasAccion from "../../../store/actions/GrupoTareas";

import Cargando from "../../../assets/components/cargando/Cargando";
import { AppBar, Button, CssBaseline, Drawer } from "@material-ui/core";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import {
  IconButton,
  Toolbar,
  // Typography,
  TextField,
  Collapse,
  Slider,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Utils from "../../../assets/utils/Utils";
import {
  WEEKDAYS_LONG,
  WEEKDAYS_SHORT,
  MONTHS,
  VERSION,
} from "../../../assets/utils/Helpers";

// import IconoTareas from '../../../assets/icons/icono-document-ready-white.svg';
import IconoArrowBottom from "../../../assets/icons/icono-down-white.svg";
import IconoCalendar from "../../../assets/icons/icono-calendar-white.png";
import IconoHome from "../../../assets/icons/icono-home-white.svg";
import IconoRayoWhite from "../../../assets/icons/icono-rayoapp-white.svg";
import IconoMenu from "../../../assets/icons/icono-menu.svg";
import IconoPC from "../../../assets/icons/icono-pc-white.svg";
// import IconoLink from "../../../assets/icons/bar-chart.svg";
// import IconoArrowUp from "../../../assets/icons/icono-up-white.png";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openList: false,
      openUsuario: false,
      openCalendar: false,
      positionCalendar: {},

      selectedHours: [1, 19],
      selectedDays: [],
      selectedDaysOrder: [],
      selectedProveedor: "",
      selectedCliente: "",
      selectedRayo: "",
      cargando: false,

      usuario: null,
      from: undefined,
      to: undefined,
    };
  }

  componentDidMount = () => {
    let user = JSON.parse(sessionStorage.getItem("user"));
    this.setState({
      usuario: user,
    });

    this.cargaInicial();
    this.renderFiltros();
  };

  handleDrawerList = (value) => {
    this.setState({
      openList: value,
    });
  };

  renderFiltros = () => {
    let filtrosGlobales = JSON.parse(sessionStorage.getItem("filtros"));
    if (filtrosGlobales === null) {
      let hoy = new Date();
      let hoyFin = new Date().setHours(23, 59, 59, 999);
      hoyFin = moment(hoyFin).format();
      hoy = moment(hoy).subtract(1, "hours").format();

      let fechaFinSplit = hoyFin.split("T");
      let fechaSplit = hoy.split("T");

      let horaFinSplit = fechaFinSplit[1].split(":");
      let horaSplit = fechaSplit[1].split(":");
      this.setState({
        selectedDays: [
          new Date(fechaSplit[0] + "T00:00:00"),
          new Date(fechaFinSplit[0] + "T00:00:00"),
        ],
        selectedDaysOrder: [
          new Date(fechaSplit[0] + "T00:00:00"),
          new Date(fechaFinSplit[0] + "T00:00:00"),
        ],
        selectedHours: [parseInt(horaSplit[0]), parseInt(horaFinSplit[0])],
        from: new Date(fechaSplit[0] + "T00:00:00"),
        to: new Date(fechaFinSplit[0] + "T00:00:00"),
      });
    } else {
      let selectedDays = ["", ""];
      let selectedHours = [0, 0];
      filtrosGlobales[1].forEach((filtro, i) => {
        if (filtro.data !== null) {
          if (filtro.filter === "initDate") {
            selectedDays[0] = new Date(filtro.data.split("T")[0] + "T00:00:00");
            selectedHours[0] = parseInt(
              filtro.data.split("T")[1].split(":")[0]
            );
            this.setState({
              selectedDays: selectedDays,
              selectedDaysOrder: selectedDays,
              selectedHours: selectedHours,
              from: selectedDays[0],
              to: selectedDays[1],
            });
          } else if (filtro.filter === "endDate") {
            selectedDays[1] = new Date(filtro.data.split("T")[0] + "T00:00:00");
            if (filtro.data.split("T")[1] === "23:59:59Z") {
              selectedHours[1] = 24;
            } else {
              selectedHours[1] = parseInt(
                filtro.data.split("T")[1].split(":")[0]
              );
            }
            this.setState({
              selectedDays: selectedDays,
              selectedDaysOrder: selectedDays,
              selectedHours: selectedHours,
              from: selectedDays[0],
              to: selectedDays[1],
            });
          } else if (filtro.filter === "idRayo") {
            this.setState({
              selectedRayo: filtro.data,
            });
          } else if (filtro.filter === "idCustomer") {
            let clientes = filtro.data.split(",");
            if (clientes.length === 1) {
              this.getCustomerById(clientes[0]);
            }
          }
        }
      });
    }
  };

  getCustomerById = async (id) => {
    try {
      let usuario = JSON.parse(sessionStorage.getItem("user"));
      let data = await axios({
        method: "get",
        url: url.host + "/Customers/customers/" + id,
        headers: { tenant: usuario.tenant },
      }).then(({ data }) => data);
      this.setState({
        selectedCliente: data,
      });
    } catch (err) {
      this.setState({
        selectedCliente: "",
      });
    }
  };

  handleDrawerUser = (value) => {
    this.setState({
      openUsuario: value,
    });
  };

  handleHoursClick = async (event, newValue) => {
    await this.setState({
      selectedHours: newValue,
    });
  };

  handleDayClick = async (day, { selected }) => {
    const { selectedDays } = this.state;

    if (selected) {
      // const selectedIndex = selectedDays.findIndex((selectedDay) => DateUtils.isSameDay(selectedDay, day));
      const selectedIndex = selectedDays.findIndex(
        (selectedDay) =>
          moment(selectedDay).utcOffset("+00").format("DD-MM-YYYY") ===
          moment(day).utcOffset("+00").format("DD-MM-YYYY")
      );
      if (selectedIndex >= 0) {
        await selectedDays.splice(selectedIndex, 1);
        selectedDays.push(selectedDays[0]);
      } else {
        selectedDays.splice(0, 1);
        selectedDays.push(day);
      }
    } else {
      if (selectedDays.length === 2) {
        selectedDays.splice(0, 1);
      }
      selectedDays.push(day);
    }

    let newFechas = [];
    newFechas.push(new Date(Math.min(...selectedDays)));
    newFechas.push(new Date(Math.max(...selectedDays)));

    let totalDiasSeleccionados =
      moment(newFechas[1]).diff(moment(newFechas[0]), "days") + 1;

    if (totalDiasSeleccionados <= 91) {
      if (selectedDays.length === 2) {
        this.setState({
          selectedDays: selectedDays,
          selectedDaysOrder: newFechas,
          from: newFechas[0],
          to: newFechas[1],
        });
      }
    }
  };

  juntarFechas = async () => {
    const selectedDays = this.state.selectedDaysOrder;
    let filtros = [];

    if (selectedDays.length !== 0) {
      this.setState({
        openCalendar: false,
      });
      const selectedDays = this.state.selectedDaysOrder;
      const selectedHours = this.state.selectedHours;
      let newSelectedHours = [];
      let initDate = "";
      let endDate = "";

      selectedHours.forEach((hour) => {
        let hourStr = hour.toString();
        if (hour < 10) {
          hourStr = "0" + hourStr + ":00:00";
        } else if (hour === 24) {
          hourStr = "23:59:59";
        } else {
          hourStr = hourStr + ":00:00";
        }
        newSelectedHours.push(hourStr);
      });

      if (selectedDays.length === 1 || selectedDays.length === 2) {
        initDate =
          moment(selectedDays[0]).format("YYYY-MM-DD") +
          "T" +
          newSelectedHours[0] +
          "Z";

        filtros.push({
          filter: "initDate",
          data: initDate,
        });
        if (selectedDays.length === 2) {
          endDate =
            moment(selectedDays[1]).format("YYYY-MM-DD") +
            "T" +
            newSelectedHours[1] +
            "Z";
        } else {
          endDate =
            moment(selectedDays[0]).format("YYYY-MM-DD") +
            "T" +
            newSelectedHours[1] +
            "Z";
        }
        filtros.push({
          filter: "endDate",
          data: endDate,
        });
      }
    } else {
      this.setState({
        openCalendar: false,
      });

      filtros = [
        {
          filter: "initDate",
          data: null,
        },
        {
          filter: "endDate",
          data: null,
        },
      ];
    }

    this.filtrar(filtros);
  };

  handleCliente = async (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });

    let filtros = [];
    if (value === null || value === "null") {
      filtros.push({
        filter: "idCustomer",
        data: null,
      });
    } else {
      filtros.push({
        filter: "idCustomer",
        data: value._id,
      });
    }

    this.filtrar(filtros);
    this.filtrarRayos(filtros);
  };

  handleRayo = async (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });

    let filtros = [];
    if (value === null || value === "") {
      filtros.push({
        filter: "idRayo",
        data: null,
      });
    } else {
      filtros.push({
        filter: "idRayo",
        data: value._id,
      });
    }

    this.filtrar(filtros);
  };

  handleProveedor = async (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });

    let filtros = [];
    if (value === null || value === "") {
      filtros.push({
        filter: "idProvider",
        data: null,
      });
    } else {
      filtros.push({
        filter: "idProvider",
        data: value._id,
      });
    }

    this.filtrarRayos(filtros);
  };

  filtrarRayos = async (filtros) => {
    const { dispatch } = this.props;
    if (
      filtros[0].filter === "idCustomer" &&
      (filtros[0].data === null || filtros[0].data === "null")
    ) {
      let customer = Utils.getCustomerString();
      filtros[0].data = customer;
    }
    let actualizarFiltros = rayosAccion.obtenerRayos(filtros, false);
    try {
      await dispatch(actualizarFiltros);
    } catch (err) {
      console.log(err);
    }
  };

  filtrar = async (filtros) => {
    await this.setState({
      cargando: true,
    });

    if (
      filtros[0].filter === "idCustomer" &&
      (filtros[0].data === null || filtros[0].data === "null")
    ) {
      let customer = Utils.getCustomerString();
      filtros[0].data = customer;
    }
    const { dispatch } = this.props;
    let actualizarFiltros = grupoTareasAccion.obtenerTareas(filtros, false);

    try {
      await dispatch(actualizarFiltros);
    } catch (err) {
      console.log(err);
    }

    await this.setState({
      cargando: false,
    });
  };

  cerrarSesion = () => {
    sessionStorage.setItem("user", null);
    // this.props.data.history.push('/Login');
    window.location.href = "/Login";
  };

  cargaInicial = async () => {
    const { dispatch } = this.props;
    let obtenerRayos = rayosAccion.obtenerRayos([], true);
    let obtenerClientes = actionClientes.obtenerClientes();
    let obtenerProveedores = proveedoresAccion.obtenerProveedores();
    try {
      dispatch(obtenerRayos);
      dispatch(obtenerProveedores);
      dispatch(obtenerClientes);
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { classes } = this.props;
    const { usuario } = this.state;
    const mobileMenuId = "primary-search-account-menu-mobile";
    // const proveedores = this.props.proveedores.proveedores;
    // const rayos = this.props.rayos.rayos;
    const clientes = this.props.clientes.clientes;

    const { from, to } = this.state;
    const modifiers = { start: from, end: to };

    const Usuario = () => {
      return (
        <div
          className={classes.list}
          role="presentation"
          onClick={() => this.handleDrawerUser(true)}
          onKeyDown={() => this.handleDrawerUser(false)}
        >
          <div className="w-100 paper-perfil">
            <div className="row m-0 px-4 pt-4">
              <div className="col-6 p-0">
                <img
                  src={usuario.photoURL}
                  className="icon-user-right"
                  alt="Usuario"
                />
              </div>
              <div className="col-6 p-0"></div>
            </div>
            <div className="row m-0 px-4 pt-4">
              <label className="text-layout">Nombre y apellido</label>
              <input
                disabled
                defaultValue={usuario.name}
                className="form-control input-layout"
              />
            </div>
            <div className="row m-0 px-4 pt-3">
              <label className="text-layout">Correo</label>
              <input
                disabled
                defaultValue={usuario.email}
                className="form-control input-layout"
              />
            </div>
            <div className="row m-0 px-4 pt-3">
              <label className="text-layout">Número de celular</label>
              <input
                disabled
                defaultValue={
                  usuario.phoneNumber === null
                    ? "No ingresado"
                    : usuario.phoneNumber
                }
                className="form-control input-layout"
              />
            </div>
          </div>
          <div className="row m-0 px-4 pt-4 d-flex justify-content-center">
            <button
              type="button"
              className="btn-secondary-white"
              onClick={() => this.cerrarSesion()}
            >
              Cerrar Sesión
            </button>
          </div>

          <p className="w-100 text-center txt-carta text-white mt-1">
            {"v " + VERSION}
          </p>
        </div>
      );
    };

    const Lista = ({ anchor, usuario }) => {
      return (
        <div
          className={classes.list}
          role="presentation"
          onClick={() => this.handleDrawerList(true)}
          onKeyDown={() => this.handleDrawerList(false)}
        >
          <div className={classes.toolbar}>
            {/* <IconButton onClick={this.handleDrawerClose}>
                            {theme.direction === 'rtl' ? (
                                <ChevronRightIcon className="text-white" />
                            ) : (
                                <ChevronLeftIcon />
                            )}
                        </IconButton> */}
          </div>
          <div className="row m-0 mb-3 d-flex align-items-center justify-content-center">
            <img src={usuario.photoURL} className="icon-user" alt="Usuario" />
          </div>
          <div className="row m-0 mb-3 px-3 d-flex align-items-center justify-content-center">
            <div>
              <p className="mb-0 text-layout text-center">Administración</p>
              <h4 className="text-center text-white">{usuario.name}</h4>
            </div>
          </div>
          <div className="row m-0 mb-3 d-flex align-items-center justify-content-center">
            <List className={classes.last}>
              <Link to="/Home/Mapa">
                <ListItem button>
                  <ListItemIcon>
                    <img
                      src={IconoPC}
                      className="icon-other mr-3"
                      alt="Panel de operaciónes"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Panel de operaciones"
                    className="text-layout hover-layout"
                  />
                </ListItem>
              </Link>

              <Collapse in={true} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/tasks">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon className="d-flex align-items-center">
                        <div className="sub-admin"></div>
                      </ListItemIcon>
                      <ListItemText primary="Seguimiento tareas" />
                    </ListItem>
                  </Link>

                  <Link to="/retiros-en-tienda">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon className="d-flex align-items-center">
                        <div className="sub-admin"></div>
                      </ListItemIcon>
                      <ListItemText primary="Retiros en tienda" />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>

            </List>
          </div>
        </div>
      );
    };

    const StringTiempo = () => {
      return this.state.selectedDaysOrder.length === 0 ? (
        <img width="16" src={IconoCalendar} alt="Icono calendario" />
      ) : this.state.selectedDaysOrder.length === 1 ? (
        moment(this.state.selectedDaysOrder[0]).format("DD-MM-YYYY") +
        " " +
        this.state.selectedHours[0] +
        ":00" +
        " - " +
        moment(this.state.selectedDaysOrder[0]).format("DD-MM-YYYY") +
        " " +
        this.state.selectedHours[1] +
        ":00"
      ) : (
        this.state.selectedDaysOrder.map((day, i) => {
          if (i === 1) {
            return (
              " - " +
              moment(day).format("DD-MM-YYYY") +
              " " +
              this.state.selectedHours[i] +
              ":00"
            );
          } else {
            return (
              moment(day).format("DD-MM-YYYY") +
              " " +
              this.state.selectedHours[i] +
              ":00"
            );
          }
        })
      );
    };

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          style={{ height: "64px" }}
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => this.handleDrawerList(true)}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: this.state.open,
              })}
            >
              <img
                src={IconoMenu}
                className="icon-button-menu"
                alt="IconoMenu"
              />
            </IconButton>

            {/* <Typography variant="h6" noWrap>
              <img
                src={
                  usuario == null
                    ? IconoRayoWhite
                    : usuario.photoURL ===
                      "https://storage.googleapis.com/fleet-manager-env.appspot.com/Dispatchers/genericImage.png"
                    ? IconoRayoWhite
                    : usuario.photoURL
                }
                height="40"
                className="br-5"
                alt="logo"
              />
            </Typography> */}

            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <div className="row m-0">
                <Button
                  className={
                    this.props.data.match.path === "/tasks"
                      ? "bg-primary-light mr-0 btn-navbar px-2-5 py-1 text-white"
                      : "d-none"
                  }
                  onClick={(e) => {
                    const clickX = 235;
                    this.setState({
                      openCalendar: !this.state.openCalendar,
                      positionCalendar: { right: clickX, top: 44 },
                    });
                  }}
                  aria-haspopup="true"
                  aria-expanded="false"
                  aria-controls="fade-menu"
                >
                  <StringTiempo />
                </Button>
                <div
                  className={
                    this.state.openCalendar
                      ? "dropdown-menu p-0 calendario-drop show show-drop"
                      : "dropdown-menu p-0 calendario-drop"
                  }
                  style={this.state.positionCalendar}
                  ref={(ref) => {
                    this.calendar = ref;
                  }}
                >
                  <div className="container-calendar">
                    <div className="container-horas row m-0">
                      <div className="col-2 p-0 d-flex justify-content-center align-items-center">
                        <div className="bg-primary-light imagen-titulo  d-flex justify-content-center align-items-center">
                          <img width="23" src={IconoHome} alt="icono" />
                        </div>
                      </div>
                      <div className="col-10 p-0 pl-2 d-flex align-items-center">
                        Intervalo de tiempo
                      </div>
                    </div>
                    <p>
                      {this.state.selectedHours.map((hora, i) => {
                        if (i === 1) {
                          return " - " + hora + ":00";
                        } else {
                          return hora + ":00";
                        }
                      })}
                    </p>
                    <div className="w-100 px-3">
                      <Slider
                        value={this.state.selectedHours}
                        onChange={this.handleHoursClick}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        getAriaValueText={this.valuetext}
                        valueLabelFormat={this.valuetext}
                        min={0}
                        step={1}
                        max={24}
                      />
                    </div>
                    <DayPicker
                      locale="es-CL"
                      firstDayOfWeek={1}
                      months={MONTHS}
                      weekdaysLong={WEEKDAYS_LONG}
                      weekdaysShort={WEEKDAYS_SHORT}
                      className="Selectable"
                      numberOfMonths={1}
                      selectedDays={[from, { from, to }]}
                      modifiers={modifiers}
                      onDayClick={this.handleDayClick}
                    />

                    <div className="w-100 d-flex justify-content-end px-3">
                      <Button
                        className="button-filtrar"
                        onClick={() => this.juntarFechas()}
                      >
                        Filtrar
                      </Button>
                    </div>
                  </div>
                </div>

                <Button
                  className={
                    this.props.data.match.path === "/tasks"
                      ? "back-dark-grey btn-navbar btn-right-calendar btn-drop-icon px-1 py-1 mr-3"
                      : "d-none"
                  }
                  onClick={(e) => {
                    const clickX = 235;
                    this.setState({
                      openCalendar: !this.state.openCalendar,
                      positionCalendar: { right: clickX, top: 44 },
                    });
                  }}
                  // data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  aria-controls="fade-menu"
                  id="button-calendar"
                >
                  <img
                    className="icon-arrow"
                    src={IconoArrowBottom}
                    alt="Icono bajar"
                  />
                </Button>

                {clientes === null ? (
                  false
                ) : (
                  <Autocomplete
                    id="combo-box-clientes"
                    className="autocomplete-layout-proveedores autocomplete-layout-clientes mr-3"
                    name="selectedCliente"
                    noOptionsText={"No se encuentran opciones"}
                    value={this.state.selectedCliente}
                    options={clientes}
                    getOptionSelected={(option, value) =>
                      value.value === option.value
                    }
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name_customer
                    }
                    onChange={(e, newValue) => {
                      let valor = {
                        target: { name: "selectedCliente", value: newValue },
                      };
                      this.handleCliente(valor);
                    }}
                    popupIcon={
                      <img
                        className="icon-arrow"
                        src={IconoArrowBottom}
                        alt="Icono bajar"
                      />
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="selectedCliente"
                        variant="outlined"
                        placeholder="&#xf007;  TODOS LOS CLIENTES"
                      />
                    )}
                  />
                )}

                {/* <Button
                                    className="back-sky-blue mr-2 btn-navbar px-2-5 py-1"
                                    startIcon={<img height="16" src={IconoTareas} alt="Icono tareas" />}
                                    onClick={() => this.props.data.history.push('/Home/Tarea/Crear/0')}
                                >
                                    CREAR TAREAS
                                </Button> */}

                <Button
                  className="back-transparent mr-0 btn-navbar p-0 mr-2"
                  onClick={() => this.handleDrawerUser(true)}
                >
                  <img
                    src={
                      usuario == null
                        ? IconoRayoWhite
                        : usuario.photoURL ===
                          "https://storage.googleapis.com/fleet-manager-env.appspot.com/Dispatchers/genericImage.png"
                        ? IconoRayoWhite
                        : usuario.photoURL
                    }
                    width="30"
                    className="br-5"
                    alt="Usuario"
                  />
                </Button>
              </div>
            </div>

            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                color="inherit"
              >
                <img
                  className="icon-arrow"
                  src={IconoArrowBottom}
                  alt="Icono bajar"
                />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>

        <main className="Layout-content-8">
          <div className="Layout-toolbar-7" />
          {this.props.contenido}
        </main>

        <Drawer
          anchor={"right"}
          open={this.state.openUsuario}
          onClose={() => this.handleDrawerUser(false)}
        >
          <Usuario />
        </Drawer>

        <Drawer
          anchor={"left"}
          open={this.state.openList}
          onClose={() => this.handleDrawerList(false)}
        >
          {usuario === "null" || !usuario ? (
            false
          ) : (
            <Lista anchor={"left"} usuario={usuario} />
          )}
        </Drawer>

        <Cargando cargando={this.state.cargando} />
      </div>
    );
  }
}
const styles = (theme) => ({
  appBar: {
    backgroundColor: "#311def",
  },
  logoMenu: {
    height: "64px",
  },
  list: {
    width: 300,
  },
  root: {
    display: "flex",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  last: {
    marginBottom: 64,
  },
  hide: {
    display: "none",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    height: 35,
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: "-5px",
  },
});

const mapStateToProps = (store) => {
  return {
    rayos: store.rayos,
    clientes: store.clientes,
    grupoTareas: store.grupoTareas,
    proveedores: store.proveedores,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(Layout)
);
