import React from 'react';
import {Container, Grid, Paper, Input, TextField, Box, Typography, AppBar, Toolbar} from "@material-ui/core";
import {Alert, Form} from "react-bootstrap";
import {useState} from 'react';
import CheckIcon from '@material-ui/icons/Check';
import {makeStyles} from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PhoneInput from "react-phone-input-2";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import pepasConductor from '../assets/image/pepas-conductor.png'
import rayoLogo from '../assets/icons/logo-rayo-white.svg';
import pepasBeneficios from '../assets/image/pepa-beneficios.png'
import defaultImage from '../assets/icons/logo-rayo-blue-2.png';
import axios from "axios";
import Swal from "sweetalert2";

export default function RegisterPepa() {
  const registered = JSON.parse(localStorage.getItem('isRegistered'));
  const history = useHistory();
  const classes = useStyles();
  const [tenantImage, setTenantImage] = useState('');
  const [imageName, setImageName] = useState('');
  const [formValues, setFormValues] = useState({
    name: '',
    lastName: '',
    password: '',
    isCompany: '',
    email: '',
    phone: '',
    rut: '',
    address: '',
    dni: '',
    nationality: '',
    vehicles: [
      {
        type: '',
        amount: null,
        dailyTravelsAmount: null,
        payloadType: '',
      }
    ],
    travels: [
      {
        region: '',
        city: '',
        sector: ''
      }
    ],
  })

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://southamerica-east1-volt-327617.cloudfunctions.net/Pepas/Pepas/pepas',
        {
          ...formValues,
          tenant_image: tenantImage
        }
      );
      if (response.status === 200) {
        Swal.fire(
          'Enhorabuena',
          response.data.message,
          'success'
        ).then(() => {
          localStorage.setItem('isRegistered', 'true')
          // history.push('/')
        })
      }
      console.log(response);
    } catch (e) {
      Swal.fire(
        'Ocurrio un error...',
        'Intentalo en unos minutos.',
        'error'
      )
      console.log('error', e)
    }
  }


  function handleChange(value, index) {
    setFormValues(prevState => ({...prevState, [index]: value}))
  }

  function addFloatData() {
    setFormValues(prevState => ({
      ...prevState,
      vehicles: [...prevState.vehicles, {type: '', amount: null, dailyTravelsAmount: null, payloadType: ''}]
    }))
  }

  function handleFloatData(value, name, index) {
    let currentFloatData = [...formValues.vehicles];
    currentFloatData[index][name] = value;
    setFormValues(prevState => ({...prevState, vehicles: currentFloatData}))
  }

  function removeFloatData(index) {
    let currentFloatData = [...formValues.vehicles];
    currentFloatData.splice(index, 1);
    setFormValues(prevState => ({...prevState, vehicles: currentFloatData}))
  }

  function addTravelsData() {
    setFormValues(prevState => ({
      ...prevState, travels: [...prevState.travels, {region: '', city: '', sector: ''}]
    }))
  }

  function handleTravelsData(value, name, index) {
    let currentTravelsData = [...formValues.travels];
    currentTravelsData[index][name] = value;
    setFormValues(prevState => ({...prevState, travels: currentTravelsData}))
  }

  function removeTravelsData(index) {
    let currentTravelsData = [...formValues.travels];
    currentTravelsData.splice(index, 1);

    setFormValues(prevState => ({...prevState, travels: currentTravelsData}))
  }

  function onChangeImg(e) {
    e.preventDefault();
    const { files } = e.target;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      setImageName(files[0].name)
      setTenantImage(reader.result)
    }
  }

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <Box className={classes.title}>
            <img src={rayoLogo} width={100} alt="rayo logos"/>
          </Box>
          <Button variant='contained' style={{ color: '#311DEF', backgroundColor: '#fff' }}>Quiero ser parte!</Button>
        </Toolbar>
      </AppBar>
      {
        registered &&
        <Alert variant="info" style={{ marginTop:'4rem' }}>
          <Alert.Heading>Felicidades!</Alert.Heading>
          Completaste el proceso de de registro, enviaremos mas informacion a tu correo!
        </Alert>
      }
      <Container style={{marginTop: '8rem'}}>
        <Grid container spacing={2} alignItems='center' style={{ marginBottom: '4rem' }}>
          <Grid item xs={12} md={6} >
            <Typography variant='h1'>Subete al lado Tecnológico</Typography>
            <Typography style={{ fontSize: '1.15rem', marginTop: '1rem' }}>
              Hola, solicita tu usuario y unete a la nueva app Rayo para disfrutar de los beneficios en logística para
              pymes:
            </Typography>
            <Box style={{ margin: '2.5rem 0' }}>
              <Box display='flex'>
                <CheckIcon style={{ marginRight: '1rem' }} />
                <Typography style={{ fontSize: '1.15rem' }}>Seguimiento online</Typography>
              </Box>
              <Box display='flex'>
                <CheckIcon style={{ marginRight: '1rem' }} />
                <Typography style={{ fontSize: '1.15rem' }}> Comunicación con flota</Typography>
              </Box>
              <Box display='flex'>
                <CheckIcon style={{ marginRight: '1rem' }} />
                <Typography style={{ fontSize: '1.15rem' }}>Contrato con empresas</Typography>
              </Box>
            </Box>
            <Button variant='contained' color='primary'>Quiero ser parte!</Button>
          </Grid>
          <Grid item xs={12} md={6} align='center'>
            <img src={pepasConductor} className={classes.img} alt="pepas conductor"/>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems='center' style={{ marginBottom: '4rem' }}>
          <Grid item xs={12} md={6}>
            <Typography variant='h1'>Beneficios</Typography>
            <Typography style={{ fontSize: '2rem', marginTop: '2.5rem', color: '#15579A', fontFamily: 'Exo2-Bold' }}>
              Seguimiento online
            </Typography>
            <Typography style={{ fontSize: '1.25rem', marginTop: '1rem' }}>Sube tu cantidad de flota y administra tus cargas directamente de la app.</Typography>
          </Grid>
          <Grid item xs={12} md={6} align='center'>
            <img src={pepasBeneficios} className={classes.img} alt="pepas conductor"/>
          </Grid>
        </Grid>
      </Container>
      <Box className={classes.formContainer}>
        <Box display='flex' justifyContent='center' className={classes.titleBox}>
          <Box>
            <Typography className={classes.formTitle}>Quiero ser
              parte!</Typography>
            <Typography className={classes.formSubTitle}>
              Por favor completa los siguientes datos y podras unirte a nuestra comunidad, te estaremos informando via
              mail del estado de tu solicitud!
            </Typography>
          </Box>
        </Box>
        <Box className={classes.insideFormContainer}>
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant='h3'>Datos personales</Typography>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    my: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 1.5
                  }}
                >
                  <img src={tenantImage ? tenantImage : defaultImage} width={100} alt='img preview' style={{ marginBottom: '1rem' }}/>
                  <Button
                    variant="text"
                    component="label"
                  >
                    {imageName ? imageName : 'Cargar logo de la empresa'}
                    <input
                      type="file"
                      onChange={(e) => onChangeImg(e)}
                      hidden
                    />
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <label className=" mb-1">Nombre</label>
                <Input
                  required
                  type="text"
                  className="form-control form-control-login"
                  placeholder="Ingresar texto"
                  name="username"
                  value={formValues.name}
                  onChange={e => handleChange(e.target.value, 'name')}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <label className=" mb-1">Apellido</label>
                <Input
                  required
                  type="text"
                  className="form-control form-control-login"
                  placeholder="Ingresar texto"
                  name="username"
                  value={formValues.lastName}
                  onChange={e => handleChange(e.target.value, 'lastName')}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box display='flex' flexDirection='column'>
                  <label className=" mb-1">¿Eres particular o empresa?</label>
                  <select
                    required
                    style={{
                      padding: '5px',
                      borderRadius: '5px'
                    }}
                    id="company-select"
                    value={formValues.isCompany}
                    name="idstatus_task"
                    onChange={e => handleChange(e.target.value, 'isCompany')}
                  >
                    <option value='' disabled>Seleccionar</option>
                    <option value='empresa'>Soy empresa</option>
                    <option value={'particular'}>Soy particular</option>
                  </select>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <label className=" mb-1">Email</label>
                <Input
                  required
                  type="email"
                  className="form-control form-control-login"
                  placeholder="mail@ejemplo.cl"
                  name="username"
                  value={formValues.email}
                  onChange={e => handleChange(e.target.value, 'email')}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <label className=" mb-1">Numero de telefono</label>
                <PhoneInput
                  preferredCountries={["cl", "mx"]}
                  country={"cl"}
                  inputProps={{
                    name: "contact_phone_task",
                    required: true,
                    type: "text",
                    minLength: 9,
                  }}
                  className="form-control"
                  placeholder="989828918"
                  name="contact_phone_pepa"
                  value={formValues.phone}
                  onChange={(e) => handleChange(e, 'phone')}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <label className=" mb-1">Rut personal o empresa</label>
                <Input
                  required
                  type="text"
                  className="form-control form-control-login"
                  placeholder="00.000.000-0"
                  name="username"
                  value={formValues.rut}
                  onChange={e => handleChange(e.target.value, 'rut')}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <label className=" mb-1">DNI</label>
                <Input
                  required
                  type="text"
                  className="form-control form-control-login"
                  placeholder="Ingresar texto"
                  name="username"
                  value={formValues.dni}
                  onChange={e => handleChange(e.target.value, 'dni')}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <label className=" mb-1">Nacionalidad</label>
                <Input
                  required
                  type="text"
                  className="form-control form-control-login"
                  placeholder="Ingresar texto"
                  name="username"
                  value={formValues.nationality}
                  onChange={e => handleChange(e.target.value, 'nationality')}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <label className=" mb-1">Direccion</label>
                <Input
                  required
                  type="text"
                  className="form-control form-control-login"
                  placeholder="Ingresar texto"
                  name="username"
                  value={formValues.address}
                  onChange={e => handleChange(e.target.value, 'address')}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <label className=" mb-1">Contrasena</label>
                <Input
                  required
                  type="password"
                  className="form-control form-control-login"
                  placeholder="Ingresar texto"
                  name="username"
                  value={formValues.password}
                  onChange={e => handleChange(e.target.value, 'password')}
                />
              </Grid>
              <Grid item xs={12} style={{marginTop: '2rem'}}>
                <Typography variant='h3'>Datos de flota</Typography>
              </Grid>
              {
                formValues.vehicles.map((form, index) => (
                  <>
                    <Grid item xs={12} md={4}>
                      <Box display='flex' flexDirection='column'>
                        <label className=" mb-1">Tipo de vehiculos</label>
                        <select
                          style={{
                            padding: '5px',
                            borderRadius: '5px'
                          }}
                          id="company-select"
                          value={formValues.vehicles[index].type}
                          name="idstatus_task"
                          onChange={e => handleFloatData(e.target.value, 'type', index)}
                        >
                          <option value='' disabled>Seleccionar</option>
                          <option value='motocicletas'>Motocicletas</option>
                          <option value={'autos'}>Autos</option>
                          <option value={'camiones'}>Camiones</option>
                        </select>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <label className=" mb-1">Cantidad de vehiculos</label>
                      <Input
                        type="number"
                        className="form-control form-control-login"
                        placeholder="00"
                        name="username"
                        value={formValues.vehicles[index].amount}
                        onChange={e => handleFloatData(e.target.value, 'amount', index)}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <label className=" mb-1">Cantidad de repartos diarios</label>
                      <Input
                        type="number"
                        className="form-control form-control-login"
                        placeholder="00"
                        name="username"
                        value={formValues.vehicles[index].dailyTravelsAmount}
                        onChange={e => handleFloatData(e.target.value, 'dailyTravelsAmount', index)}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box display='flex' flexDirection='column'>
                        <label className=" mb-1">Tipo de carga que suele llevar</label>
                        <select
                          style={{
                            padding: '5px',
                            borderRadius: '5px'
                          }}
                          id="company-select"
                          value={formValues.vehicles[index].payloadType}
                          name="idstatus_task"
                          onChange={e => handleFloatData(e.target.value, 'payloadType', index)}
                        >
                          <option value='' disabled>Seleccionar</option>
                          <option value='motocicletas'>tipo 001</option>
                          <option value={'autos'}>tipo 002</option>
                          <option value={'camiones'}>tipo 003</option>
                        </select>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box display='flex' padding='1rem'>
                        <IconButton onClick={() => removeFloatData(index)}>
                          <DeleteForeverIcon style={{color: 'red'}}/>
                        </IconButton>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider style={{marginBottom: '1rem', marginTop: '1rem'}}/>
                    </Grid>
                  </>
                ))
              }
              <Grid item xs={12}>
                <Box display='flex' alignItems='center'>
                  <Button onClick={() => addFloatData()}>
                    <AddIcon/>
                    <Typography variant='h5'>
                      Agregar otro tipo de flota
                    </Typography>
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} style={{marginTop: '2rem'}}>
                <Typography variant='h3'>Datos de recorridos</Typography>
              </Grid>
              {
                formValues.travels.map((form, index) => (
                  <>
                    <Grid item xs={12} md={3}>
                      <Box display='flex' flexDirection='column'>
                        <label className=" mb-1">Region</label>
                        <select
                          style={{
                            padding: '5px',
                            borderRadius: '5px'
                          }}
                          id="company-select"
                          value={formValues.travels[index].region}
                          name="idstatus_task"
                          onChange={e => handleTravelsData(e.target.value, 'region', index)}
                        >
                          <option value='' disabled>Seleccionar</option>
                          <option value='region 1'>region 1</option>
                          <option value={'region 2'}>region 2</option>
                          <option value={'region 3'}>region 3</option>
                        </select>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Box display='flex' flexDirection='column'>
                        <label className=" mb-1">Ciudad</label>
                        <select
                          style={{
                            padding: '5px',
                            borderRadius: '5px'
                          }}
                          id="company-select"
                          value={formValues.travels[index].city}
                          name="idstatus_task"
                          onChange={e => handleTravelsData(e.target.value, 'city', index)}
                        >
                          <option value='' disabled>Seleccionar</option>
                          <option value='ciudad 1'>ciudad 1</option>
                          <option value={'ciudad 2'}>ciudad 2</option>
                          <option value={'ciudad 3'}>ciudad 3</option>
                        </select>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Box display='flex' flexDirection='column'>

                        <label className=" mb-1">Sector</label>
                        <select
                          style={{
                            padding: '5px',
                            borderRadius: '5px'
                          }}
                          id="company-select"
                          value={formValues.travels[index].sector}
                          name="idstatus_task"
                          onChange={e => handleTravelsData(e.target.value, 'sector', index)}
                        >
                          <option value='' disabled>Seleccionar</option>
                          <option value='sector 1'>sector 1</option>
                          <option value={'sector 2'}>sector 2</option>
                          <option value={'sector 3'}>sector 3</option>
                        </select>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <Box display='flex' padding='1rem'>
                        <IconButton onClick={() => removeTravelsData(index)}>
                          <DeleteForeverIcon style={{color: 'red'}}/>
                        </IconButton>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider style={{marginBottom: '1rem', marginTop: '1rem'}}/>
                    </Grid>
                  </>
                ))
              }
              <Grid item xs={12}>
                <Box display='flex' alignItems='center'>
                  <Button onClick={() => addTravelsData()}>
                    <AddIcon/>
                    <Typography variant='h5'>
                      Agregar otro recorrido
                    </Typography>
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} align='center'>
                <Button type='submit' variant='contained' color='primary'>Enviar</Button>
              </Grid>
            </Grid>
          </Form>
        </Box>
      </Box>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: 'white'
  },
  titleBox: {
    [theme.breakpoints.down("md")]: {
      textAlign: 'center', padding: 0
    },
    [theme.breakpoints.up("md")]: {
      textAlign: 'center', padding: '6rem'
    },
  },
  formContainer: {
    [theme.breakpoints.down('md')]: {
      backgroundColor: '#311DEF', padding: '1rem'
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: '#311DEF', padding: '1rem 6rem'
    },
  },
  insideFormContainer: {
    [theme.breakpoints.down('md')]: {
      padding: '2rem', borderRadius: '15px', border: '1px solid lightgray', backgroundColor: 'white'
    },
    [theme.breakpoints.up('md')]: {
      padding: '4rem', borderRadius: '15px', border: '1px solid lightgray', backgroundColor: 'white'
    },
  },
  formTitle: {
    [theme.breakpoints.down('md')]: {
      color: 'white', fontFamily: 'Exo2-Bold', fontSize: '2rem',
      marginBottom: '2rem'
    },
    [theme.breakpoints.up('md')]: {
      color: 'white', fontFamily: 'Exo2-Bold', fontSize: '2.75rem',
      marginBottom: '2rem'
    },
  },
  formSubTitle: {
    [theme.breakpoints.down('md')]: {
      color: 'white', fontFamily: 'Exo2-Bold', fontSize: '1rem',
      marginBottom: '1.5rem'
    },
    [theme.breakpoints.up('md')]: {
      color: 'white', fontFamily: 'Exo2-Bold', fontSize: '1.25rem',
      marginBottom: '1rem'
    },
  },
  img: {
    [theme.breakpoints.down('md')]: {
      maxWidth: 350
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 500
    },
  }
}));
