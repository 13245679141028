// ambiente de testing
// const APPCONFIG = { id: "thor-test-lcfli", timeout: 100000 };
// const SERVICE_NAME = "mongodb-atlas";
// const VERSION = "1.0.76";
// const AMBIENTE = "testing";
// 15-10-2021 09:30

// ambiente productivo
const APPCONFIG = { id: "volt-sdwun", timeout: 100000 };
const SERVICE_NAME = "VOLT";
const VERSION = "1.0.2";
const AMBIENTE = "productivo";
// 28-01-2022 09:00

// tracking link
const N_TAREA = 0;
const N_GRUPOTAREA = 1;

// PERFILES
const ADMIN = 0;
const OBSERVER = 1;
const EPA = 2;

// status
const ID_NO_ASIGNADO = "5f05f3607f5164494c74b966";
const ID_ASIGNADO = "5f05f3d37f5164494c74b967";
const ID_ACEPTADO = "5f05f3dc7f5164494c74b968";
const ID_INICIADO = "5f05f3eb7f5164494c74b969";
const ID_EN_PROGRESO = "5f05f3f77f5164494c74b96a";
const ID_EXITOSO = "5f05f4077f5164494c74b96b";
const ID_FALLIDO = "5f05f4147f5164494c74b96c";
const ID_RECHAZADO = "5f05f4217f5164494c74b96d";
const ID_CANCELADO = "5f05f42e7f5164494c74b96e";
const ID_DEVUELTO = "60bfdb431b02612234560b16";
const ID_INICIAL_PERSONALIZADO =
  "5f05f3d37f5164494c74b967,5f05f3dc7f5164494c74b968,5f05f3eb7f5164494c74b969,5f05f3f77f5164494c74b96a,5f05f4077f5164494c74b96b,5f05f4147f5164494c74b96c,5f05f4217f5164494c74b96d,5f05f42e7f5164494c74b96e,60bfdb431b02612234560b16";

let LIGHT_MODE_GOOGLE = [
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#fff1b3" }],
  },
];

let DARK_MODE_GOOGLE = [
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  },
  { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#263c3f" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#6b9a76" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#38414e" }],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ color: "#212a37" }],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9ca5b3" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#746855" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#1f2835" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#f3d19c" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#2f3948" }],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#17263c" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#515c6d" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [{ color: "#17263c" }],
  },
];

let NEW_GOOGLE_THEME = [
  {
    featureType: "all",
    elementType: "labels.text.fill",
    stylers: [{ color: "#778ba8" }],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "all",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "landscape.man_made",
    elementType: "geometry.fill",
    stylers: [{ color: "#e9eaf0" }],
  },
  {
    featureType: "landscape.natural",
    elementType: "geometry.fill",
    stylers: [{ color: "#e9eaf0" }],
  },
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [{ lightness: "0" }, { color: "#c6f0c8" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi",
    elementType: "labels.icon",
    stylers: [{ hue: "#1f00ff" }, { saturation: "-72" }, { lightness: "0" }],
  },
  {
    featureType: "poi.attraction",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }, { saturation: "0" }, { lightness: "0" }],
  },
  {
    featureType: "poi.business",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi.government",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi.medical",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi.place_of_worship",
    elementType: "labels.icon",
    stylers: [{ saturation: -70 }, { lightness: -65 }, { visibility: "off" }],
  },
  {
    featureType: "poi.school",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi.sports_complex",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [{ color: "#ffffff" }],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ saturation: "14" }, { color: "#e4e5eb" }],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [{ color: "#caccd9" }, { lightness: "32" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ visibility: "off" }, { lightness: "-31" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels",
    stylers: [{ visibility: "on" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.icon",
    stylers: [
      { visibility: "on" },
      { hue: "#007cff" },
      { saturation: "-51" },
      { lightness: "12" },
      { gamma: "1.47" },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry.fill",
    stylers: [{ lightness: "-8" }],
  },
  {
    featureType: "road.arterial",
    elementType: "labels",
    stylers: [{ visibility: "on" }],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "road.local",
    elementType: "all",
    stylers: [{ visibility: "on" }],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [{ color: "#778ba8" }],
  },
  {
    featureType: "transit.station.airport",
    elementType: "geometry.fill",
    stylers: [
      { hue: "#1f00ff" },
      { saturation: "12" },
      { gamma: "1" },
      { lightness: "-4" },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [{ color: "#b6e0f8" }],
  },
  {
    featureType: "water",
    elementType: "labels.text",
    stylers: [{ visibility: "off" }],
  },
];

const MAP_CONFIG = {
  center: { lat: -33.4073585, lng: -70.55869349999999 },
  zoom: 10,
  maxZoom: 18,
  styles: NEW_GOOGLE_THEME,
};

const THEME_METADATA = {
  scheme: "monokai",
  author: "wimer hazenberg (http://www.monokai.nl)",
  base00: "#ffffff",
  base01: "#383830",
  base02: "#49483e",
  base03: "#75715e",
  base04: "#a59f85",
  base05: "#f8f8f2",
  base06: "#f5f4f1",
  base07: "#f9f8f5",
  base08: "#f92672",
  base09: "#fd971f",
  base0A: "#f4bf75",
  base0B: "#343434",
  base0C: "#a1efe4",
  base0D: "#343434",
  base0E: "#ae81ff",
  base0F: "#cc6633",
};

const DATA_EDITAR = [
  {
    label: "Nombre",
    nombre: "contact_name_task",
    data: "",
  },
  {
    label: "Teléfono",
    nombre: "contact_phone_task",
    data: "",
  },
  {
    label: "Descripción",
    nombre: "description_task",
    data: "",
  },
  {
    label: "Fecha de inicio",
    nombre: "date_task",
    data: "",
  },
  {
    label: "Dirección",
    nombre: "address_task",
    data: "",
    lat: null,
    lng: null,
  },
];

const WEEKDAYS_LONG = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];
const WEEKDAYS_SHORT = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
const MONTHS = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const ACT_CODIGO_BARRAS = {
  id: "5f0cb7c81faff000127011d9",
  name: "Código Barras",
  code: "codigoBarras",
  img: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Type_Activities/aea7d847-2309-406a-ae78-0fab3faf0a81.svg",
};
const ACT_FIRMA = {
  id: "5f0cb1776da3005bf4aa353e",
  name: "Firma",
  code: "firma",
  img: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Type_Activities/90bfc245-7a6b-40c7-b3ed-8e19450d5d95.svg",
};
const ACT_FOTO = {
  id: "5f0cb1a26da3005bf4aa353f",
  name: "Foto",
  code: "foto",
  img: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Type_Activities/54034e36-3e17-4586-a0b5-13c23af04a39.svg",
};
const ACT_NOTA = {
  id: "5f0cb1c16da3005bf4aa3540",
  name: "Nota",
  code: "nota",
  img: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Type_Activities/7c226c91-bf0e-459a-8ea9-f0651ae9bd1b.svg",
};
const ACT_CODIGO_QR = {
  id: "5f0cb67abf990f5a28ed8343",
  name: "Código QR",
  code: "codigoQR",
  img: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Type_Activities/75fa0366-7dac-431e-8fbf-7b49bb2adcb8.svg",
};
const ACT_MULTISCAN = {
  id: "6089c57fde84b40450406555",
  name: "MultiScan",
  code: "multiscan",
  img: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Type_Activities/e63e2222-63ad-41b5-8326-9d4cf77c463a.svg",
};
const ACT_DROPDOWN = {
  id: "6089c67fde84b40450406556",
  name: "Dropdown",
  code: "dropdown",
  img: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Type_Activities/142dbca8-5459-4de6-9afd-a5fdf2888e65.svg",
};
const ACT_SECRETO = {
  id: "60d33b0ea526ddebd46f2d6d",
  name: "Secreto",
  code: "secreto",
  img: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Type_Activities/fadf9c99-f8c4-4084-9976-a389e5293864.svg",
};



const TESTING_RAYOS = [
  {
    icon: {
      url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-p.png",
      scaledSize: { width: 20, height: 20 },
      origin: { x: 0, y: 0 },
    },
    position: { lat: -33.5156683, lng: -70.5975767 },
    map: null,
    title: "Adler Smith Vega Moreno",
    placeName: "60395ffee3a9c5001907633a",
    dataRayo: {
      id: "60395ffee3a9c5001907633a",
      name: "Adler Smith Vega Moreno",
      dni: "27068151-4",
      phone: 56971238850,
      email: "adlervega@gmail.com",
      status: 1,
    },
  },
  {
    icon: {
      url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayo-verde.png",
      scaledSize: { width: 20, height: 20 },
      origin: { x: 0, y: 0 },
    },
    position: { lat: -33.5035625, lng: -70.7580939 },
    map: null,
    title: "Alexis Jose Rangel pineda",
    placeName: "614cc365ccaa7e667b01ce18",
    dataRayo: {
      id: "614cc365ccaa7e667b01ce18",
      name: "Alexis Jose Rangel pineda",
      dni: "26049058-3",
      phone: 56932458231,
      email: "alexis.rangelp@gmail.com",
      status: 1,
    },
  },
  {
    icon: {
      url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayo-verde.png",
      scaledSize: { width: 20, height: 20 },
      origin: { x: 0, y: 0 },
    },
    position: { lat: -33.450999, lng: -70.6465995 },
    map: null,
    title: "Andrés Felipe Torres Montoya",
    placeName: "60cb71708aaf0fc152c0baed",
    dataRayo: {
      id: "60cb71708aaf0fc152c0baed",
      name: "Andrés Felipe Torres Montoya",
      dni: "25533589-8",
      phone: 56976927844,
      email: "andrestorres135@gmail.com",
      status: 1,
    },
  },
  {
    icon: {
      url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayo-verde.png",
      scaledSize: { width: 20, height: 20 },
      origin: { x: 0, y: 0 },
    },
    position: { lat: -33.448552, lng: -70.6318193 },
    map: null,
    title: "Cristhian Alexander Gonzalez Hernandez",
    placeName: "605b74bc51e8da09995e22b7",
    dataRayo: {
      id: "605b74bc51e8da09995e22b7",
      name: "Cristhian Alexander Gonzalez Hernandez",
      dni: "26696416-1",
      phone: 56999479279,
      email: "castillodejesus2425@gmail.com",
      status: 1,
    },
  },
  {
    icon: {
      url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayo-verde.png",
      scaledSize: { width: 20, height: 20 },
      origin: { x: 0, y: 0 },
    },
    position: { lat: -29.9925101, lng: -71.3193695 },
    map: null,
    title: "GERALDINE PARADA",
    placeName: "60394304e3a9c50019075fa5",
    dataRayo: {
      id: "60394304e3a9c50019075fa5",
      name: "GERALDINE PARADA",
      dni: "16911875-2",
      phone: 56979282047,
      email: "geparada89@gmail.com",
      status: 1,
    },
  },
  {
    icon: {
      url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayo-verde.png",
      scaledSize: { width: 20, height: 20 },
      origin: { x: 0, y: 0 },
    },
    position: { lat: -33.4641143, lng: -70.6186963 },
    map: null,
    title: "Humberto Rangel",
    placeName: "603940e0e3a9c50019075f56",
    dataRayo: {
      id: "603940e0e3a9c50019075f56",
      name: "Humberto Rangel",
      dni: "149249595-0",
      phone: 56979528767,
      email: "h3rc110@gmail.com",
      status: 1,
    },
  },
  {
    icon: {
      url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayo-verde.png",
      scaledSize: { width: 20, height: 20 },
      origin: { x: 0, y: 0 },
    },
    position: { lat: -33.498606933558484, lng: -70.65937737539353 },
    map: null,
    title: "Jeisson Uribe Vargas ",
    placeName: "60abe4bc7caa311f98989664",
    dataRayo: {
      id: "60abe4bc7caa311f98989664",
      name: "Jeisson Uribe Vargas ",
      dni: "26549772-1",
      phone: 56922340406,
      email: "jeissonuribev@gmail.com",
      status: 1,
    },
  },
  {
    icon: {
      url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayo-verde.png",
      scaledSize: { width: 20, height: 20 },
      origin: { x: 0, y: 0 },
    },
    position: { lat: -33.0330373, lng: -71.6306558 },
    map: null,
    title: "Jesus Javier Cañas Sanabria",
    placeName: "60fb17d1f1ef3e8872418add",
    dataRayo: {
      id: "60fb17d1f1ef3e8872418add",
      name: "Jesus Javier Cañas Sanabria",
      dni: "26122487-9",
      phone: 56954097950,
      email: "jesusjcs96@gmail.com",
      status: 1,
    },
  },
  {
    icon: {
      url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayo-verde.png",
      scaledSize: { width: 20, height: 20 },
      origin: { x: 0, y: 0 },
    },
    position: { lat: 10.3818197, lng: -75.4975846 },
    map: null,
    title: "Katy Apoyo",
    placeName: "605a5c88b123c45e85b4825f",
    dataRayo: {
      id: "605a5c88b123c45e85b4825f",
      name: "Katy Apoyo",
      dni: "123456",
      phone: 56222231112,
      email: "katyapoyo@rayoapp.com",
      status: 1,
    },
  },
  {
    icon: {
      url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayo-verde.png",
      scaledSize: { width: 20, height: 20 },
      origin: { x: 0, y: 0 },
    },
    position: { lat: -33.6197004, lng: -70.5992447 },
    map: null,
    title: "Luis Jesus Granda Canizalez",
    placeName: "60397948e3a9c5001907663a",
    dataRayo: {
      id: "60397948e3a9c5001907663a",
      name: "Luis Jesus Granda Canizalez",
      dni: "26345283-6",
      phone: 56956821639,
      email: "grandaluis2108@gmail.com",
      status: 1,
    },
  },
  {
    icon: {
      url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayo-verde.png",
      scaledSize: { width: 20, height: 20 },
      origin: { x: 0, y: 0 },
    },
    position: { lat: -33.4398855, lng: -70.6721485 },
    map: null,
    title: "Mariangeles Salas",
    placeName: "6048dd0e469309b6218b8ab6",
    dataRayo: {
      id: "6048dd0e469309b6218b8ab6",
      name: "Mariangeles Salas",
      dni: "123456",
      phone: 56920663149,
      email: "mariangeles@rayoapp.com",
      status: 1,
    },
  },
  {
    icon: {
      url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayo-verde.png",
      scaledSize: { width: 20, height: 20 },
      origin: { x: 0, y: 0 },
    },
    position: { lat: -33.4719383, lng: -70.6475424 },
    map: null,
    title: "Raniel Jesús Navas Rojas",
    placeName: "60c8d5f646263d3cfff36918",
    dataRayo: {
      id: "60c8d5f646263d3cfff36918",
      name: "Raniel Jesús Navas Rojas",
      dni: "26796489-0",
      phone: 56976986643,
      email: "caraderani@gmail.com",
      status: 1,
    },
  },
  {
    icon: {
      url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayo-verde.png",
      scaledSize: { width: 20, height: 20 },
      origin: { x: 0, y: 0 },
    },
    position: { lat: -33.4988274, lng: -70.6642851 },
    map: null,
    title: "Sheyla Massiel Castrillo Castro ",
    placeName: "60b77361bb1096ce91486621",
    dataRayo: {
      id: "60b77361bb1096ce91486621",
      name: "Sheyla Massiel Castrillo Castro ",
      dni: "25346674-0",
      phone: 56973115664,
      email: "sheylacastrillo@hotmail.com",
      status: 1,
    },
  },
];

export {
  APPCONFIG,
  SERVICE_NAME,
  N_TAREA,
  N_GRUPOTAREA,
  ID_NO_ASIGNADO,
  ID_ASIGNADO,
  ID_ACEPTADO,
  ID_INICIADO,
  ID_EN_PROGRESO,
  ID_EXITOSO,
  ID_FALLIDO,
  ID_RECHAZADO,
  ID_CANCELADO,
  ID_DEVUELTO,
  ID_INICIAL_PERSONALIZADO,
  NEW_GOOGLE_THEME,
  LIGHT_MODE_GOOGLE,
  DARK_MODE_GOOGLE,
  THEME_METADATA,
  DATA_EDITAR,
  WEEKDAYS_LONG,
  WEEKDAYS_SHORT,
  MONTHS,
  MAP_CONFIG,
  VERSION,
  ADMIN,
  OBSERVER,
  EPA,
  AMBIENTE,
  ACT_CODIGO_BARRAS,
  ACT_FIRMA,
  ACT_FOTO,
  ACT_NOTA,
  ACT_CODIGO_QR,
  ACT_MULTISCAN,
  ACT_DROPDOWN,
  ACT_SECRETO,
  TESTING_RAYOS,
};
