import React from "react";
import moment from "moment";
import Utils from "../../../assets/utils/Utils";
import DayPicker from "react-day-picker";
import "../../../css/global.css";
import { connect } from "react-redux";
import * as grupoTareasAccion from "../../../store/actions/GrupoTareas";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
// import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";

import IconoArrowBottom from "../../../assets/icons/icono-down-white.svg";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import IconoCalendar from "../../../assets/icons/icono-calendar-white.png";
import IconoHome from "../../../assets/icons/icono-home-white.svg";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

import Cargando from "../../../assets/components/cargando/Cargando";
import Skeleton from "@material-ui/lab/Skeleton";

import {
  WEEKDAYS_LONG,
  WEEKDAYS_SHORT,
  MONTHS,
} from "../../../assets/utils/Helpers";

const columns = [
  {
    id: "store_id",
    label: "ID Tienda",
    align: "left",
  },
  {
    id: "contact_name_task",
    label: "Nombre de la Tienda",
    align: "left",
  },
  {
    id: "date_task",
    label: "Fecha",
    align: "left",
  },
  {
    id: "order_id_task",
    label: "Order Id",
    align: "left",
  },
  {
    id: "idstatus_task",
    label: "Estado",
    align: "center",
  },
  {
    id: "tracking_link",
    label: "Tracking Link",
    align: "center",
  },
  {
    id: "link_seguimiento",
    label: "Seguimiento",
    align: "center",
  },
];

class VistaRecepcion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 1000,
      openCalendar: false,
      positionCalendar: {},
      selectedHours: [1, 19],
      selectedDays: [],
      selectedDaysOrder: [],
      from: undefined,
      to: undefined,

      cargando: false,
    };
  }

  componentDidMount = async () => {
    await this.fechaFiltro();
    this.filterTasks();
  };

  handleState = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleChangePage = async (event, newPage) => {
    this.setState({ page: newPage }, () => this.filterTasks());
  };

  handleChangeRowsPerPage = async (event) => {
    await this.setState(
      {
        rowsPerPage: +event.target.value,
        page: 0,
      },
      () => this.filterTasks()
    );
  };

  handleHoursClick = async (event, newValue) => {
    await this.setState({
      selectedHours: newValue,
    });
  };

  handleDayClick = async (day, { selected }) => {
    const { selectedDays } = this.state;
    let days = [];
    days.push(day);
    if (selected) {
      // const selectedIndex = selectedDays.findIndex((selectedDay) => DateUtils.isSameDay(selectedDay, day));
      const selectedIndex = selectedDays.findIndex(
        (selectedDay) =>
          moment(selectedDay).utcOffset("+00").format("DD-MM-YYYY") ===
          moment(day).utcOffset("+00").format("DD-MM-YYYY")
      );
      if (selectedIndex >= 0) {
        await selectedDays.splice(selectedIndex, 1);
        selectedDays.push(selectedDays[0]);
      } else {
        selectedDays.splice(0, 1);
        selectedDays.push(day);
      }
    } else {
      if (selectedDays.length === 2) {
        selectedDays.splice(0, 1);
      }
      selectedDays.push(day);
    }

    let newFechas = [];
    newFechas.push(new Date(Math.min(...selectedDays)));
    newFechas.push(new Date(Math.max(...selectedDays)));

    if (selectedDays.length === 2) {
      this.setState({
        selectedDays: days,
        selectedDaysOrder: days,
        from: days[0],
        to: days[0],
      });
    }
  };

  fechaFiltro = () => {
    let hoy = new Date();
    let hoyFin = new Date().setHours(23, 59, 59, 999);
    hoyFin = moment(hoyFin).format();
    hoy = moment(hoy).subtract(1, "hours").format();

    let fechaFinSplit = hoyFin.split("T");
    let fechaSplit = hoy.split("T");

    this.setState({
      selectedDays: [
        new Date(fechaSplit[0] + "T00:00:00"),
        new Date(fechaFinSplit[0] + "T00:00:00"),
      ],
      selectedDaysOrder: [
        new Date(fechaSplit[0] + "T00:00:00"),
        new Date(fechaFinSplit[0] + "T00:00:00"),
      ],
      selectedHours: [0, 24],
      from: new Date(fechaSplit[0] + "T00:00:00"),
      to: new Date(fechaFinSplit[0] + "T00:00:00"),
    });
  };

  fechasCalendario = () => {
    this.setState({
      openCalendar: false,
    });
    const selectedDays = this.state.selectedDaysOrder;
    const selectedHours = this.state.selectedHours;
    let newSelectedHours = [];
    let initDate = "";
    let endDate = "";

    selectedHours.forEach((hour) => {
      let hourStr = hour.toString();
      if (hour < 10) {
        hourStr = "0" + hourStr + ":00:00";
      } else if (hour === 24) {
        hourStr = "23:59:59";
      } else {
        hourStr = hourStr + ":00:00";
      }
      newSelectedHours.push(hourStr);
    });

    if (selectedDays.length === 1 || selectedDays.length === 2) {
      initDate =
        moment(selectedDays[0]).format("YYYY-MM-DD") +
        "T" +
        newSelectedHours[0] +
        "Z";

      if (selectedDays.length === 2) {
        endDate =
          moment(selectedDays[1]).format("YYYY-MM-DD") +
          "T" +
          newSelectedHours[1] +
          "Z";
      } else {
        endDate =
          moment(selectedDays[0]).format("YYYY-MM-DD") +
          "T" +
          newSelectedHours[1] +
          "Z";
      }
    }

    return [initDate, endDate];
  };

  juntarFechas = () => {
    this.filterTasks();
  };

  filterTasks = async () => {
    const { dispatch } = this.props;
    await this.setState({ cargando: true });
    let fechasCalendario = this.fechasCalendario();
    let initDate = fechasCalendario[0];
    let endDate = fechasCalendario[1];
    // let skip = this.state.page * this.state.rowsPerPage;

    let stringFilter = "initDate=" + initDate + "&endDate=" + endDate;
    // "&skip=" +
    // skip +
    // "&limit=" +
    // this.state.rowsPerPage;

    let obtenerTareas = grupoTareasAccion.obtenerTareasCliente(stringFilter);
    try {
      await dispatch(obtenerTareas);
    } catch (err) {
      Utils.handleAlert(
        "Obtener data",
        "No se han podido cargar todos los elementos de la página",
        "Ok"
      );
    }

    this.setState({ cargando: false });
  };

  linkSeguimiento = (task) => {
    if (task.customer.length !== 0) {
      let nameCustomer = "";
      task.customer.forEach((element) => {
        nameCustomer = element.name_customer;
      });
      let newName = Utils.nameCustomerSeguimiento(nameCustomer);
      let link =
        "https://seguimiento.thor.rayoapp.com/" +
        newName +
        "/?id=" +
        task.order_id_task;
      link.replace(/\s/g, "");
      window.open(link);
    } else {
      Utils.handleAlert(
        "Link de seguimiento",
        "Esta tarea no tiene la información suficiente para generar el link de seguimiento, contactenos para más información",
        "Ok"
      );
    }
  };

  render() {
    let tasks = this.props.grupoTareas.tareasRecepcion;
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    // const { page, rowsPerPage } = this.state;

    let totalTareas = tasks ? tasks.length : 0;
    let totalTareasExitosas = tasks ? Utils.tareasExitosas(tasks) : 0;

    const BoxEstado = ({ id }) => {
      return (
        <Button
          className={
            Utils.colorTarea(id, true) +
            " pt-0 pb-0 py-1 w-90-button txt-carta text-capitalize text-roboto-regular button-state"
          }
          disabled
        >
          {Utils.colorTarea(id, false)}
        </Button>
      );
    };

    const StringTiempo = ({ selectedDaysOrder, selectedHours }) => {
      return selectedDaysOrder.length === 0 ? (
        <img width="16" src={IconoCalendar} alt="Icono calendario" />
      ) : selectedDaysOrder.length === 1 ? (
        moment(selectedDaysOrder[0]).format("DD-MM-YYYY")
      ) : (
        // " " +
        // selectedHours[0] +
        // ":00" +
        // " - " +
        // moment(selectedDaysOrder[0]).format("DD-MM-YYYY") +
        // " " +
        // selectedHours[1] +
        // ":00"
        selectedDaysOrder.map((day, i) => {
          if (i === 1) {
            return moment(day).format("DD-MM-YYYY");
          } else {
            return false;
          }
        })
      );
    };

    return (
      <Box pt={4}>
        <Cargando cargando={this.state.cargando} />
        <Container className="p-relative">
          <Typography variant="h1" gutterBottom>
            Retiros en tienda
          </Typography>

          <Box maxWidth="200px" mb={4}>
            <Button
              className="border-blue-btn mr-0"
              style={{ width: "calc(100% - 36px)" }}
              onClick={(e) => {
                const clickX = 1050;
                this.handleState("openCalendar", !this.state.openCalendar);
                this.handleState("positionCalendar", {
                  right: clickX,
                  top: 100,
                });
              }}
              aria-haspopup="true"
              aria-expanded="false"
              aria-controls="fade-menu"
            >
              <StringTiempo
                selectedDaysOrder={this.state.selectedDaysOrder}
                selectedHours={this.state.selectedHours}
              />
            </Button>
            <div
              className={
                this.state.openCalendar
                  ? "dropdown-menu p-0 calendario-drop show show-drop"
                  : "dropdown-menu p-0 calendario-drop"
              }
              style={this.state.positionCalendar}
              ref={(ref) => {
                this.calendar = ref;
              }}
            >
              <div className="container-calendar pb-3">
                <div className="container-horas row m-0">
                  <div className="col-2 p-0 d-flex justify-content-center align-items-center">
                    <div className="bg-primary-light imagen-titulo  d-flex justify-content-center align-items-center">
                      <img width="23" src={IconoHome} alt="icono" />
                    </div>
                  </div>
                  <div className="col-10 p-0 pl-2 d-flex align-items-center">
                    Intervalo de tiempo
                  </div>
                </div>
                <p>
                  {this.state.selectedHours.map((hora, i) => {
                    if (i === 1) {
                      return " - " + hora + ":00";
                    } else {
                      return hora + ":00";
                    }
                  })}
                </p>
                <div className="w-100 px-3">
                  <Slider
                    value={this.state.selectedHours}
                    onChange={this.state.handleHoursClick}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    getAriaValueText={this.valuetext}
                    valueLabelFormat={this.valuetext}
                    min={0}
                    step={1}
                    max={24}
                  />
                </div>
                <DayPicker
                  locale="es-CL"
                  firstDayOfWeek={1}
                  months={MONTHS}
                  weekdaysLong={WEEKDAYS_LONG}
                  weekdaysShort={WEEKDAYS_SHORT}
                  className="Selectable"
                  numberOfMonths={1}
                  selectedDays={[from, { from, to }]}
                  modifiers={modifiers}
                  onDayClick={this.handleDayClick}
                />

                <div className="w-100 d-flex justify-content-end px-3">
                  <Button
                    className="button-filtrar"
                    onClick={() => this.juntarFechas()}
                  >
                    Filtrar
                  </Button>
                </div>
              </div>
            </div>
            <Button
              className="back-dark-grey btn-right-calendar btn-drop-icon border-blue-icon"
              style={{ width: "36px" }}
              onClick={(e) => {
                const clickX = 1050;
                this.handleState("openCalendar", !this.state.openCalendar);
                this.handleState("positionCalendar", {
                  right: clickX,
                  top: 100,
                });
              }}
              // data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              aria-controls="fade-menu"
              id="button-calendar"
            >
              <img
                className="icon-arrow"
                src={IconoArrowBottom}
                alt="Icono bajar"
              />
            </Button>
          </Box>

          <Box mt={5}>
            <Typography variant="h5" gutterBottom>
              {"Tiendas (" + totalTareasExitosas + "/" + totalTareas + ")"}
            </Typography>
          </Box>
          {/* <Divider /> */}

          <TableContainer className="table-white small-font mb-5">
            <Table aria-label="Type activities">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell align={column.align} key={index}>
                      <b>{column.label}</b>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tasks ? (
                  tasks.length === 0 ? (
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell colSpan={columns.length} align="center">
                        <em>
                          - No existen tareas según los parametros ingresados -
                        </em>
                      </TableCell>
                    </TableRow>
                  ) : (
                    tasks.map((row, i) => (
                      <TableRow hover key={i}>
                        <TableCell align="left">{row.store_id}</TableCell>
                        <TableCell align="left">
                          {row.contact_name_task}
                        </TableCell>
                        <TableCell align="left">
                          {moment(row.date_task)
                            .utcOffset("+00")
                            .format("DD-MM-YYYY HH:mm")}
                        </TableCell>
                        <TableCell align="left">{row.order_id_task}</TableCell>
                        <TableCell align="left">
                          <BoxEstado id={row.idstatus_task} />
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            aria-label="tracking"
                            onClick={() =>
                              window.open("/Tracking/0/" + row._id, "_blank")
                            }
                          >
                            <LocationOnIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            aria-label="history"
                            onClick={() => this.linkSeguimiento(row)}
                          >
                            <LibraryBooksIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  )
                ) : (
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    {columns.map((e, i) => (
                      <TableCell key={i}>
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            labelDisplayedRows={({ from, to, count }) => {
              return from + " - " + to + " de " + count + " elementos";
            }}
            component="div"
            // count={this.props.grupoTareas.countTasks}
            count={10000}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          /> */}
        </Container>
      </Box>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    rayos: store.rayos,
    grupoTareas: store.grupoTareas,
  };
};

export default connect(mapStateToProps)(VistaRecepcion);
