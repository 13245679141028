import React from 'react';

import L from 'leaflet';
import 'leaflet-routing-machine';
import Utils from '../../utils/Utils';
import polyUtil from "polyline-encoded";

class MapaRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mapa: {},
            geometry: props.geometry
        };
    }

    componentDidMount() {
        this.cargarMapa();
    }

    cargarMapa = async () => {
        try {
            let map = L.map('mapaRouta');
            L.tileLayer(
                'https://api.mapbox.com/styles/v1/rayoapp/ckhfarifi0bfq19t72qem6bh3/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicmF5b2FwcCIsImEiOiJjanA0aW52aXowbjNvM3BzYW1tZDVyeGxzIn0.BHRdulbBe-zysvzUJwVvQw',
                {
                    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                }
            ).addTo(map);

            if (this.state.geometry && this.state.geometry.length > 0) {
                L.polyline(
                    polyUtil.decode(this.state.geometry)
                ).addTo(map);
            }
            
            let icon = L.icon({
                iconUrl: this.props.rayo.icon.url,
                iconRetinaUrl: this.props.rayo.icon.url,
                iconAnchor: [20, 20],
                popupAnchor: [10, -44],
                iconSize: [20, 20],
            });

            new L.Marker(new L.LatLng(this.props.rayo.position.lat, this.props.rayo.position.lng), {
                icon: icon,
            }).addTo(map);

            //let array = [];
            this.props.wayPoints.forEach((point, j) => {
                //array.push(L.latLng(point.location.lat, point.location.lng));
                L.marker(L.latLng(point.location.lat, point.location.lng), {
                    icon: L.divIcon({
                        html:
                            '<div class="container-icon"><div class="circle d-flex justify-content-center align-items-center"><p class="p-0">' +
                            (j + 1) +
                            '</p></div><div class="triangle"></div></div>',
                        iconAnchor: [16, 46],
                        draggable: false,
                    }),
                    draggable: false,
                }).addTo(map);
            });

            const position = [this.props.rayo.position.lat, this.props.rayo.position.lng];
            map.setView(position, 16, { animation: true });
            /*
            this.route = L.Routing.control({
                waypoints: array,
                routeWhileDragging: true,
                lineOptions: {
                    styles: [{ color: 'rgba(102, 173, 255, 0.8)', opacity: 1, weight: 5 }],
                },
                createMarker: function (i, waypoint, n) {
                    let j = i + 1;
                    let iconoDiv = L.divIcon({
                        html:
                            '<div class="container-icon"><div class="circle d-flex justify-content-center align-items-center"><p class="p-0">' +
                            j +
                            '</p></div><div class="triangle"></div></div>',
                        iconAnchor: [16, 46],
                        draggable: false,
                    });
                    return L.marker(waypoint.latLng, {
                        icon: iconoDiv,
                        draggable: false,
                    });
                },
            }).addTo(map);*/
        } catch (error) {
            Utils.handleAlert('Error OpenStreetMaps', error.message, 'Ok');
        }
    };

    componentDidUpdate = (prevProps) => {
        try {
            /*
            prevProps.wayPoints.forEach((prev, i) => {
                this.props.wayPoints.forEach((prop, j) => {
                    if (i === j) {
                        if (prev._id !== prop._id) {
                            let array = [];
                            this.props.wayPoints.forEach((point) => {
                                array.push(L.latLng(point.location.lat, point.location.lng));
                            });

                            this.route.setWaypoints(array);
                        }
                    }
                });
            });

            if (prevProps.wayPoints.length !== this.props.wayPoints.length) {
                let array = [];
                this.props.wayPoints.forEach((point) => {
                    array.push(L.latLng(point.location.lat, point.location.lng));
                });

                this.route.setWaypoints(array);
            }*/
        } catch (error) {
            Utils.handleAlert('Error OpenStreetMaps', error.message, 'Ok');
        }
    };

    render() {
        return <div id="mapaRouta" className="map"></div>;
    }
}

export default MapaRoute;
