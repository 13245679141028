import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Utils from '../../utils/Utils';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

const CopyText = ({ text }) => {
    return (
        <>
            <IconButton onClick={() => Utils.copyText(text)}>
                <FileCopyOutlinedIcon />
            </IconButton>
            <div id={text} className="ver-copiado hidden">
                <label>¡Copiado!</label>
            </div>
        </>
    );
};

export default CopyText;
