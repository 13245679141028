import React from 'react';
import { connect } from 'react-redux';

import * as grupoTareasAccion from '../store/actions/GrupoTareas';
import * as rayosAccion from '../store/actions/Rayos';

import MapaGoogle from '../assets/components/mapas/MapaGoogle';

import CircularProgress from '@material-ui/core/CircularProgress';
import ListaGrupoDeTareas from '../assets/components/lists/ListaGrupoDeTareas';
import ListaRayos from '../assets/components/lists/ListaRayos';
import Utils from '../assets/utils/Utils';
import { ID_NO_ASIGNADO, ID_INICIAL_PERSONALIZADO } from '../assets/utils/Helpers';

const TAREA = {
    history_taskgroup: [
        {
            date: '2021-01-14T20:45:17.000Z',
            description: 'Jose Valero ha finalizado con éxito el grupo de tareas',
            email: 'valero@rayoapp.com',
        },
        {
            date: '2021-01-14T20:44:23.000Z',
            description: 'Jose Valero está procesando el grupo de tareas',
            email: 'valero@rayoapp.com',
        },
        {
            date: '2021-01-14T19:31:20.000Z',
            description: 'Jose Valero ha aceptado el grupo de tareas',
            email: 'valero@rayoapp.com',
        },
        {
            date: '2021-01-14T19:30:17.000Z',
            description: ' ha asignado el grupo de tareas a Jose Valero',
            email: '',
        },
        { date: '2021-01-14T19:30:16.000Z', description: 'Se ha creado el grupo de tareas', email: '' },
    ],
    _id: '6000c5f9b81b83001020206be',
    orden_id_taskgroup: '6535943',
    idstatus_taskgroup: '5f05f3607f5164494c74b966',
    idrayo_taskgroup: '5fedf013e1d53c0012fa8362',
    idcustomer_taskgroup: '5fc4f240f84049f04c713509',
    start_date_taskgroup: '2021-01-14T19:43:00.000Z',
    end_date_taskgroup: '2021-01-14T20:45:17.000Z',
    creation_date_taskgroup: '2021-01-14T19:30:16.000Z',
    type_taskgroup: 1,
    contact_name_taskgroup: 'TESTING PRUEBA',
    address_taskgroup: 'Av. Apoquindo 6888, Las Condes, Santiago, RM, Chile',
    latitude_taskgroup: -33.4073585,
    longitude_taskgroup: -70.55869349999999,
    prioritized_EPA: '',
    autoassign_code_taskgroup: 'FROWRRDP',
    code_taskgroup: 29557,
    __v: 0,
    tasks: [
        {
            template_successful_task: { idTemplate: '5fc4f397f8404939ae713ece' },
            template_failed_task: { idTemplate: '5fc4f3c3f8404906c8714021' },
            history_task: [
                { date: '2021-01-14T19:30:16.000Z', description: 'Se ha creado la tarea', email: '' },
                { date: '2021-01-14T19:30:17.000Z', description: ' ha asignado la tarea a Jose Valero', email: '' },
                {
                    date: '2021-01-14T19:31:20.000Z',
                    description: 'Jose Valero ha aceptado la tarea',
                    email: 'valero@rayoapp.com',
                },
                {
                    date: '2021-01-14T20:06:30.000Z',
                    description: 'Jose Valero ha iniciado la tarea',
                    email: 'valero@rayoapp.com',
                },
                {
                    date: '2021-01-14T20:06:40.000Z',
                    description: 'Jose Valero está procesando la tarea',
                    email: 'valero@rayoapp.com',
                },
                {
                    date: '2021-01-14T20:07:04.000Z',
                    description: 'Jose Valero ha finalizado con éxito la tarea',
                    email: 'valero@rayoapp.com',
                },
            ],
            skipped_task: 0,
            _id: '6000c5f8b81b8300122c66ba',
            order_id_task: '6535943',
            type_task: 1,
            description_task: 'Recoger Orden #6535943',
            date_task: '2021-01-14T19:43:00.000Z',
            end_date_task: '2021-01-14T20:07:04.000Z',
            idrayo_task: '5fedf013e1d53c0012fa8362',
            address_task: 'Av. Apoquindo 6888, Las Condes, Santiago, RM, Chile',
            latitude_task: -34.3972355,
            longitude_task: -72.02627,
            contact_name_task: 'BURGER KING Parque Italiano',
            contact_phone_task: '56223076626',
            idstatus_task: '5f05f4077f5164494c74b96b',
            metadata_task: {
                business_id: '16849',
                business_email: 'bk-parqueitaliano@burgerking.cl',
                marca: 'Chilis',
                client_email: 'silv.caceres.contreras@gmail.com',
                id: '4444',
                template_data: [
                    {
                        foto:
                            'https://storage.googleapis.com/fleet-manager-env.appspot.com/TemplatesData/dad28142-06df-497e-9443-fdbcffbe384e.png',
                        nota: null,
                        firma: null,
                        codigoBarras: null,
                        codigoQR: null,
                    },
                ],
                result: 'Exitoso',
            },
            id_previous_task: '',
            id_next_task: '',
            idcustomer_task: '5fc4f240f84049f04c713509',
            orderNumber_task: 1,
            idTasksGroup_task: '6000c5f9b81b8300122c66be',
            code_task: 51124,
            __v: 0,
            id: '6000c5f8b81b8300122c66ba',
        },
        {
            template_successful_task: { idTemplate: '5fc4f3eaf84049bad671412c' },
            template_failed_task: { idTemplate: '5fc4f413f84049683b714263' },
            history_task: [
                { date: '2021-01-14T19:30:16.000Z', description: 'Se ha creado la tarea', email: '' },
                { date: '2021-01-14T19:30:17.000Z', description: ' ha asignado la tarea a Jose Valero', email: '' },
                {
                    date: '2021-01-14T19:31:20.000Z',
                    description: 'Jose Valero ha aceptado la tarea',
                    email: 'valero@rayoapp.com',
                },
                {
                    date: '2021-01-14T20:44:10.000Z',
                    description: 'Jose Valero ha iniciado la tarea',
                    email: 'valero@rayoapp.com',
                },
                {
                    date: '2021-01-14T20:44:23.000Z',
                    description: 'Jose Valero está procesando la tarea',
                    email: 'valero@rayoapp.com',
                },
                {
                    date: '2021-01-14T20:45:17.000Z',
                    description: 'Jose Valero ha finalizado con éxito la tarea',
                    email: 'valero@rayoapp.com',
                },
            ],
            skipped_task: 0,
            _id: '6000c5f9b81b8300122c66bb',
            order_id_task: '6535943',
            type_task: 2,
            description_task: 'Entregar Orden #6535943',
            date_task: '2021-01-14T19:58:00.000Z',
            end_date_task: '2021-01-14T20:45:17.000Z',
            idrayo_task: '5fedf013e1d53c0012fa8362',
            address_task: 'Viejos Estandartes 388, Las Condes, Chile Dpto/Oficina: 388',
            latitude_task: -35.3321393,
            longitude_task: -72.4324079,
            contact_name_task: 'silvana caceres',
            contact_phone_task: '56454567654',
            idstatus_task: '5f05f4077f5164494c74b96b',
            metadata_task: {
                business_id: '16849',
                business_email: 'bk-parqueitaliano@burgerking.cl',
                marca: 'Chilis',
                client_email: 'silv.caceres.contreras@gmail.com',
                id: '4444',
                template_data: [
                    {
                        foto:
                            'https://storage.googleapis.com/fleet-manager-env.appspot.com/TemplatesData/cee520a1-f314-45ab-ad5d-e29304b47ec2.png',
                        nota: 'se entrega al cliente',
                        firma: null,
                        codigoBarras: null,
                        codigoQR: null,
                    },
                ],
                result: 'Exitoso',
            },
            id_previous_task: '',
            id_next_task: '',
            idcustomer_task: '5fc4f240f84049f04c713509',
            orderNumber_task: 2,
            idTasksGroup_task: '6000c5f9b81b8300122c66be',
            code_task: 51125,
            __v: 0,
            id: '6000c5f9b81b8300122c66bb',
        },
    ],
    customer: [{ _id: '5fc4f240f84049f04c713509', name_customer: 'Alsea Chile' }],
    id: '6000c5f9b81b8300122c66be',
    selected: false,
};

const RAYO = {
    uuid_rayo: '',
    tasks_in_progress_rayo: 2,
    auto_assign_rayo: true,
    _id: '5f3efc0489ee800012a5TEST',
    code_rayo: '00020',
    name_rayo: 'Prueba Testing',
    dni_rayo: '19.928.919-1',
    nationality_rayo: 'Chilena',
    number_license_rayo: 'UU88LL',
    type_vehicles_rayo: [{ _id: '5fc6d3b898228037f4415595', idTypeVehicle: '5ef4bfcc82bea400128d86ca' }],
    number_rayo: 56987654321,
    email_rayo: 'maria@gmail.com',
    address_rayo: 'Maipú, Región Metropolitana, Chile',
    status_rayo: 1,
    image_rayo:
        'https://storage.googleapis.com/fleet-manager-env.appspot.com/Rayos/79a511ae-ca4d-4cae-8566-397dcbe7eb6d.jpeg',
    zones_rayo: { communes: [] },
    latitude_rayo: -33.6526056,
    longitude_rayo: -71.1601325,
    fcm_token_rayo: '',
    provider_rayo: '5f0ca1103440d746502939dd',
    customer_rayo: [{ _id: '5fc6d3b898228005e6415594', idCustomer: '5f0c954e495f530ee0dbe9fe' }],
    providers: [{ _id: '5f0ca1103440d746502939dd', name_provider: 'Alan Perez', id: '5f0ca1103440d746502939dd' }],
    id: '5f3efc0489ee800012a5TEST',
};

const RAYO_MODIFICADO = {
    uuid_rayo: '',
    tasks_in_progress_rayo: 2,
    auto_assign_rayo: true,
    _id: '5f3efc0489ee800012a5TEST',
    code_rayo: '00020',
    name_rayo: 'Prueba Testing',
    dni_rayo: '19.928.919-1',
    nationality_rayo: 'Chilena',
    number_license_rayo: 'UU88LL',
    type_vehicles_rayo: [{ _id: '5fc6d3b898228037f4415595', idTypeVehicle: '5ef4bfcc82bea400128d86ca' }],
    number_rayo: 56987654321,
    email_rayo: 'maria@gmail.com',
    address_rayo: 'Maipú, Región Metropolitana, Chile',
    status_rayo: 1,
    image_rayo:
        'https://storage.googleapis.com/fleet-manager-env.appspot.com/Rayos/79a511ae-ca4d-4cae-8566-397dcbe7eb6d.jpeg',
    zones_rayo: { communes: [] },
    latitude_rayo: -33.6891097,
    longitude_rayo: -71.2389077,
    fcm_token_rayo: '',
    provider_rayo: '5f0ca1103440d746502939dd',
    customer_rayo: [{ _id: '5fc6d3b898228005e6415594', idCustomer: '5f0c954e495f530ee0dbe9fe' }],
    providers: [{ _id: '5f0ca1103440d746502939dd', name_provider: 'Alan Perez', id: '5f0ca1103440d746502939dd' }],
    id: '5f3efc0489ee800012a5TEST',
};

class Testing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filtro_tareas: '',
            filtro_rayos: '',
            filtrosIniciales: [
                [{ filter: 'idStatusTaskgroup', data: ID_NO_ASIGNADO }],
                [{ filter: 'idStatusTaskgroup', data: ID_INICIAL_PERSONALIZADO }],
            ],
            estados: [],
        };
    }

    componentDidMount = async () => {
        const { dispatch } = this.props;
        let obtenerGTEstado2 = grupoTareasAccion.obtenerGTFiltro(this.state.filtrosIniciales[0], 0, '/Home/Mapa', true);

        try {
            dispatch(obtenerGTEstado2);
        } catch (err) {
            Utils.handleAlert('Obtener data', 'No se han podido cargar todos los elementos de la página', 'Ok');
        }
    };

    handleCheck = (e, grupo) => {
        e.preventDefault();
        let id = grupo._id;
        let wayPoints = [];

        let esta = false;
        let i = 0;

        if (esta) {
            wayPoints.splice(i, 1);
        } else {
            let puntos = { location: { lat: grupo.latitude_taskgroup, lng: grupo.longitude_taskgroup } };
            wayPoints.push(puntos);
        }

        const { dispatch } = this.props;
        let modificarGT = grupoTareasAccion.modificarGT(this.props.grupoTareas.grupoTareas0, '0', [id], false);

        dispatch(modificarGT);
    };

    colorTarea = (n, tipo) => {
        let numero = 0;
        this.state.estados.forEach((estado) => {
            if (estado._id === n) {
                numero = estado.number_status;
            }
        });
        let color = '';
        let texto = '';
        switch (numero) {
            case 1:
                color = 'back-medium-grey';
                texto = 'No asignado';
                break;
            case 2:
                color = 'dot-asignado';
                texto = 'Asignado';
                break;
            case 3:
                color = 'dot-aceptado';
                texto = 'Aceptado';
                break;
            case 4:
                color = 'dot-iniciado';
                texto = 'Iniciado';
                break;
            case 5:
                color = 'dot-en-progreso';
                texto = 'En Progreso';
                break;
            case 6:
                color = 'dot-exitoso';
                texto = 'Exitoso';
                break;
            case 7:
                color = 'dot-haFallado';
                texto = 'Fallido';
                break;
            case 8:
                color = 'dot-rechazado';
                texto = 'Rechazado';
                break;
            default:
                color = 'dot-cancelado';
                texto = 'Cancelado';
                break;
        }

        if (tipo) {
            return color;
        } else {
            return texto;
        }
    };

    agregarTarea = () => {
        const { dispatch } = this.props;
        let createGT = grupoTareasAccion.createGT(
            this.props.grupoTareas.grupoTareas0,
            this.props.grupoTareas.grupoTareas1,
            TAREA
        );
        dispatch(createGT);
    };

    agregarRayo = () => {
        const { dispatch } = this.props;
        let create = rayosAccion.addRayo(this.props.rayos.rayos, RAYO);
        dispatch(create);
    };

    borrarTarea = () => {
        const { dispatch } = this.props;
        const i1 = this.props.grupoTareas.grupoTareas0.findIndex((element) => element._id === TAREA.id);
        let deleteGT = grupoTareasAccion.deleteGT(this.props.grupoTareas.grupoTareas0, 0, i1);
        dispatch(deleteGT);
    };

    borrarRayo = () => {
        const { dispatch } = this.props;
        let deleteR = rayosAccion.deleteRayo(this.props.rayos.rayos, RAYO._id);
        dispatch(deleteR);
    };

    moverRayo = (rayomodificado) => {
        const { dispatch } = this.props;
        let updateStateRayos = rayosAccion.updateStateRayos(this.props.rayos.rayos, rayomodificado);
        dispatch(updateStateRayos);
    };

    abrirDetalleRayo = () => {};

    render() {
        const CargandoPanel = () => {
            return (
                <div className="cargando-paneles d-flex justify-content-center align-items-center">
                    <CircularProgress />
                </div>
            );
        };

        const SinGT = ({ name }) => {
            return (
                <div className="cargando-paneles d-flex justify-content-center align-items-center">
                    <div className="w-100">
                        <p className="w-100 text-center text-exo-light color-light-grey mb-0">
                            No se encuentran Grupos de Tareas en estado
                        </p>
                        <p className="w-100 text-center text-exo-light color-light-grey">
                            <strong>{name}</strong>
                        </p>
                    </div>
                </div>
            );
        };

        return (
            <div className="row m-0">
                <div className="col-md-3 p-0">
                    {this.props.grupoTareas.grupoTareas0 === null ? (
                        <CargandoPanel />
                    ) : this.props.grupoTareas.grupoTareas0.length === 0 ? (
                        <SinGT name={this.props.grupoTareas.grupoTareasNombre0} />
                    ) : (
                        <ListaGrupoDeTareas
                            grupos={this.props.grupoTareas.grupoTareas0}
                            index={'1'}
                            toggleItemActive={this.handleCheck}
                            skipedDimensions={this.skipedDimensions}
                            tasksGroup={this.tasksGroup}
                            colorTarea={this.colorTarea}
                            width={'100%'}
                            rayos={this.props.rayos.rayos}
                            filtro_tareas={this.state.filtro_tareas}
                            seleccionDesdeMapa={this.props.grupoTareas.seleccionDesdeMapa}
                        />
                    )}

                    <button onClick={this.agregarTarea}>agregar</button>
                    <button onClick={this.borrarTarea}>borrar</button>
                    {Utils.filterTaskGroups(
                        this.props.grupoTareas.grupoTareas0,
                        this.props.grupoTareas.seleccionDesdeMapa,
                        this.state.filtro_tareas
                    )}
                </div>
                <div className="col-md-6">
                    {this.props.grupoTareas.localidades0 ? (
                        <MapaGoogle
                            options={{
                                center: { lat: -33.4073585, lng: -70.55869349999999 },
                                zoom: 10,
                                maxZoom: 18,
                            }}
                            tab={'0'}
                            fitBounds={false}
                            dispatch={this.props.dispatch}
                            _handleClickCluster={this._handleClickCluster}
                            _handleClickMarker={this._handleClickMarker}
                            localizaciones={this.props.grupoTareas.localidades0}
                            gruposTareas={this.props.grupoTareas.grupoTareas0}
                            localizacionesRayos={this.props.rayos.localidades}
                        />
                    ) : (
                        <div style={{ position: 'relative', minHeight: '100%' }}>
                            <div
                                style={{
                                    width: '100%',
                                    minHeight: '100%',
                                    position: 'absolute',
                                    backgroundColor: '#000000',
                                }}
                                className="d-flex justify-content-center align-items-center"
                            >
                                <CircularProgress />
                            </div>
                        </div>
                    )}
                </div>
                <div className="col-md-3 p-0">
                    {this.props.rayos.rayos === null ? (
                        <CargandoPanel />
                    ) : (
                        <ListaRayos
                            rayos={this.props.rayos.rayos}
                            width={'100%'}
                            rayo={this.abrirDetalleRayo}
                            tipo={1}
                            filtro_rayos={this.state.filtro_rayos}
                        />
                    )}
                    <button onClick={this.agregarRayo}>agregar</button>
                    <button onClick={() => this.moverRayo(RAYO)}>mover pomaire</button>
                    <button onClick={() => this.moverRayo(RAYO_MODIFICADO)}>mover melipilla</button>
                    <button onClick={this.borrarRayo}>borrar</button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        grupoTareas: store.grupoTareas,
        rayos: store.rayos,
    };
};

export default connect(mapStateToProps)(Testing);
