import React from "react";
import $ from "jquery";
import moment from "moment";
import clsx from "clsx";

import Utils from "../../utils/Utils";
import { N_GRUPOTAREA } from "../../utils/Helpers";
import { withStyles } from "@material-ui/core/styles";

import IconoTarea from "../../icons/icono-document-download-white.svg";
import IconoSeleccionar from "../../icons/icono-accion-white.png";
import IconoEditar from "../../icons/icono-pencil-black.svg";
import IconoDuplicar from "../../icons/icono-duplicar-black.svg";
import IconoReasignar from "../../icons/icono-user-black.svg";
// import IconoCambiar from '../../icons/icono-cambiar-black.svg';
import IconoEliminar from "../../icons/icono-trash-black.svg";
import IconoReloj from "../../icons/historia.svg";
import IconoFlechaAzul from "../../icons/icono-flecha-derecha-blue2.png";
import IconoRoute from "../../icons/gps-route.svg";
import CloseIcon from "@material-ui/icons/Close";

import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Historial from "./Historial";

class DetalleGrupoTareas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openFiltros: false,
      anchorElFiltros: null,
    };
  }

  render() {
    const grupoTareas = this.props.grupoTareas;
    let rayos = this.props.rayos;
    const { classes } = this.props;
    if (rayos === null) {
      rayos = [];
    }

    let template = this.props.template.template_data;
    if (template === null || typeof template === "undefined") template = [];

    const openFiltros = Boolean(this.state.anchorElFiltros);
    const idFiltros = openFiltros ? "simple-popover" : undefined;

    return (
      <div>
        <div className="w-100 back-light-grey alto-dark d-flex align-items-center">
          <div className="row m-0 w-100">
            <div className="col-11 pr-0 d-flex align-items-center">
              <h6 className="mb-0 text-roboto-regular text-ellipsis">
                {"Order ID GT: " + grupoTareas.orden_id_taskgroup}
              </h6>
            </div>
            <div className="col-1 pl-0 pr-1 d-flex align-items- center justify-content-end">
              <IconButton
                aria-label="cerrar"
                onClick={() => this.props.cerrarDetalle(false)}
              >
                <CloseIcon color="primary" />
              </IconButton>
            </div>
          </div>
        </div>
        <div className="w-100 back-medium-grey alto-grey d-flex align-items-center">
          <div className="row m-0 w-100">
            <div className="col-10 pr-0 d-flex align-items-center">
              <div className="row m-0 w-100 h-100">
                <div className="col-5 p-0 d-flex align-items-center">
                  <label className="mb-0 text-roboto-regular text-white">
                    Estado del GT:
                  </label>
                </div>
                <div className="col-7 pr-0 pl-2 d-flex align-items-center">
                  <div className="carta-orden-estado-gt row m-0 px-2 w-100 h-100">
                    <div className="col-3 p-0 d-flex align-items-center">
                      <div
                        className={
                          "dot " +
                          Utils.colorTarea(grupoTareas.idstatus_taskgroup, true)
                        }
                      ></div>
                    </div>
                    <div className="col-9 p-0 d-flex align-items-center">
                      {Utils.colorTarea(grupoTareas.idstatus_taskgroup, false)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2 pl-0 pr-1 d-flex align-items-center justify-content-end">
              <IconButton
                aria-controls="fade-menu"
                aria-describedby={idFiltros}
                onClick={(e) =>
                  this.setState({ anchorElFiltros: e.currentTarget })
                }
              >
                <img src={IconoSeleccionar} height="20" alt="opciones" />
              </IconButton>

              <Popover
                id={idFiltros}
                open={openFiltros}
                anchorEl={this.state.anchorElFiltros}
                onClick={(e) => this.setState({ anchorElFiltros: null })}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                getContentAnchorEl={null}
              >
                {this.props.vista === "customer" ? (
                  false
                ) : (
                  <div className="w-100">
                    <div
                      className="row m-0 opc-drop border-bottom-0"
                      onClick={() => {
                        this.props.handleState("tipoModal", 1);
                        $("#modal").modal("show");
                      }}
                    >
                      <div className="col-md-4 d-flex align-items-center">
                        <img src={IconoEditar} height="20" alt="icono" />
                      </div>
                      <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
                        Editar
                      </div>
                    </div>
                    <div
                      className="row m-0 opc-drop border-bottom-0"
                      onClick={() => {
                        this.props.handleState("tipoModal", 2);
                        $("#modal").modal("show");
                      }}
                    >
                      <div className="col-md-4 d-flex align-items-center">
                        <img src={IconoDuplicar} height="20" alt="icono" />
                      </div>
                      <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
                        Duplicar
                      </div>
                    </div>
                    {this.props.vista === "mapa" ? (
                      <div>
                        <div
                          className="row m-0 opc-drop border-bottom-0"
                          onClick={() => {
                            this.props.handleState("tipoModal", 3);
                            $("#modal").modal("show");
                          }}
                        >
                          <div className="col-md-4 d-flex align-items-center">
                            <img src={IconoReasignar} height="20" alt="icono" />
                          </div>
                          <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
                            Reasignar
                          </div>
                        </div>
                        {/* <div
                                                    className="row m-0 opc-drop border-bottom-0"
                                                    onClick={() => {
                                                        this.props.handleState('tipoModal', 4);
                                                        $('#modal').modal('show');
                                                    }}
                                                >
                                                    <div className="col-md-4 d-flex align-items-center">
                                                        <img src={IconoCambiar} height="20" alt="icono" />
                                                    </div>
                                                    <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
                                                        Cambiar estado
                                                    </div>
                                                </div> */}
                      </div>
                    ) : (
                      false
                    )}
                    <div
                      className="row m-0 opc-drop border-bottom-0"
                      onClick={() => {
                        this.props.handleState("tipoModal", 5);
                        $("#modal").modal("show");
                      }}
                    >
                      <div className="col-md-4 d-flex align-items-center">
                        <img src={IconoEliminar} height="20" alt="icono" />
                      </div>
                      <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
                        Eliminar
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="row m-0 opc-drop border-bottom-0"
                  onClick={async () => {
                    this.props.handleState(
                      "dataHistorial",
                      grupoTareas.history_taskgroup
                    );
                    this.props.handleState(
                      "tituloHistorial",
                      "grupo de tareas"
                    );
                    this.props.handleState("historial", true);
                    this.props.handleState("template", []);
                  }}
                >
                  <div className="col-md-4 d-flex align-items-center">
                    <img src={IconoReloj} height="20" alt="icono" />
                  </div>
                  <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
                    Ver Historial
                  </div>
                </div>
                <div
                  className="row m-0 opc-drop border"
                  onClick={() =>
                    window.open(
                      "/Tracking/" + N_GRUPOTAREA + "/" + grupoTareas._id,
                      "_blank"
                    )
                  }
                >
                  <div className="col-md-4 d-flex align-items-center">
                    <img src={IconoRoute} height="20" alt="icono" />
                  </div>
                  <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
                    Tracking Link
                  </div>
                </div>
              </Popover>
            </div>
          </div>
        </div>

        {this.props.historialState ? (
          <Historial
            titulo={this.props.tituloHistorial}
            historial={this.props.dataHistorial}
            handleState={this.props.handleState}
            historialState={this.props.historialState}
            template={template}
          />
        ) : (
          false
        )}

        <div className={this.props.historialState ? "w-100 d-none" : "w-100"}>
          <div
            className={
              this.props.vista === "customer"
                ? "paper w-100 pt-3"
                : "paper-mini w-100 pt-3"
            }
          >
            <div>
              {grupoTareas.idrayo_taskgroup === null ||
              this.props.vista === "customer" ? (
                false
              ) : rayos === undefined || rayos === null ? (
                false
              ) : (
                <div
                  className={clsx(
                    "row m-0 mb-3 mx-3 pt-0 py-1",
                    classes.bgSuccess
                  )}
                  style={{ borderRadius: 4 }}
                >
                  {rayos.map((rayo, i) =>
                    rayo._id === grupoTareas.idrayo_taskgroup ? (
                      <div key={i} className="row m-0 w-100">
                        <div className="col-3 d-flex align-items-center justify-content-center">
                          <img
                            src={rayo.image_rayo}
                            height="30"
                            width="30"
                            alt="rayo"
                            className="d-block mx-auto img-rayo"
                          />
                        </div>
                        <div className="col-9 d-flex align-items-center justify-content-center">
                          <div className="w-100">
                            <p className="mb-0 sub-txt-carta">
                              Último Rayo asignado al GT:
                            </p>
                            <p className="mb-0 txt-carta-name">
                              {rayo.name_rayo}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      false
                    )
                  )}
                </div>
              )}

              <div className="row m-0 mb-3 px-3 pt-0">
                <div className="col-12 orden-name d-flex align-items-start justify-content-center">
                  {/* <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    disabled
                    className="position-absolute back-lightest-blue text-white text-capitalize text-roboto-regular"
                  >
                    Tareas del grupo
                  </Button> */}
                  <Box className={classes.boxTareas}>Tareas del grupo</Box>
                </div>
              </div>

              {grupoTareas.tasks.length === 0 ? (
                <div className="row m-0 mb-3 px-3 pt-3">
                  <p className="w-100 text-center text-exo-light color-light-grey">
                    Este grupo no posee tareas
                  </p>
                </div>
              ) : (
                grupoTareas.tasks.map((tarea, index) => {
                  if (typeof tarea === undefined) {
                    return false;
                  } else {
                    return (
                      <Tooltip
                        arrow
                        title={tarea.description_task}
                        placement="right"
                        key={tarea._id}
                      >
                        <div
                          className="row m-0 px-3 mt-2 p-relative"
                          onContextMenu={(e) => {
                            this.props.handleState(
                              "dataHistorial",
                              tarea.history_task
                            );
                            this.props.handleState("tituloHistorial", "tarea");
                            this.props.handleState(
                              "template",
                              tarea.metadata_task
                            );
                            this.props.handleContextMenu(e, tarea);
                          }}
                        >
                          <div className="col-custom-10">
                            <div
                              className={
                                index === 0
                                  ? clsx(
                                      "borde-orden-list-1",
                                      classes.bordeOrdenList1
                                    )
                                  : clsx(
                                      "borde-orden-list",
                                      classes.bordeOrdenList
                                    )
                              }
                            ></div>
                            <div
                              className={
                                index === 0
                                  ? clsx(
                                      "borde-orden-list-x",
                                      classes.bordeOrdenListX
                                    )
                                  : ""
                              }
                            ></div>
                            <div
                              className={
                                "dot-orden-list mt-4 " +
                                Utils.colorTipoTarea(tarea.type_task, true)
                              }
                            ></div>
                          </div>
                          <div className="col-custom-90 pr-0">
                            <div
                              className="ver-copiado-rayo hidden"
                              id={"tarea-" + tarea._id}
                            >
                              <label>¡Copiado!</label>
                            </div>
                            <div
                              className={clsx(
                                "w-100 row m-0 mt-3 carta-orden bg-white",
                                classes.borderPrimary
                              )}
                              style={{
                                marginBottom: 4,
                                paddingTop: 10,
                              }}
                            >
                              <div
                                className={clsx(
                                  "carta-orden-estado row m-0 py-05 px-05 bg-white",
                                  classes.borderPrimary
                                )}
                                style={{ top: 7 }}
                              >
                                <div className="d-inline d-flex align-items-center">
                                  <div
                                    className={
                                      "dot " +
                                      Utils.colorTarea(
                                        tarea.idstatus_task,
                                        true
                                      )
                                    }
                                  ></div>
                                </div>
                                <label className="mb-0 pl-1 txt-carta pr-1">
                                  {Utils.colorTarea(
                                    tarea.idstatus_task,
                                    false
                                  ) + " "}
                                  <label className="mb-0 txt-carta color-medium-red">
                                    {tarea.skipped_task === 1
                                      ? " / tarea saltada"
                                      : false}
                                  </label>
                                  <label
                                    className="mb-0 position-absolute f-right cursor-pointer px-1"
                                    style={{
                                      backgroundColor: "#f2f2f2",
                                      borderRadius: 4,
                                    }}
                                    onClick={() => {
                                      Utils.justCopyText2(
                                        "tarea-" + tarea._id,
                                        tarea.order_id_task
                                      );
                                    }}
                                  >
                                    {tarea.order_id_task}
                                  </label>
                                </label>
                              </div>
                              <div
                                style={{
                                  width: 45,
                                  paddingLeft: 7,
                                  paddingTop: 7,
                                }}
                              >
                                {tarea.idrayo_task === null ||
                                rayos === undefined ||
                                rayos === null ? (
                                  <div className="scuare-light-blue"></div>
                                ) : (
                                  rayos.map((rayo, i) =>
                                    rayo._id === tarea.idrayo_task ? (
                                      <div key={i}>
                                        <img
                                          src={rayo.image_rayo}
                                          height="30"
                                          width="30"
                                          alt="rayo"
                                          className="img-rayo"
                                        />
                                      </div>
                                    ) : (
                                      false
                                    )
                                  )
                                )}
                              </div>
                              <div
                                className="row m-0"
                                style={{
                                  width:
                                    this.props.vista === "customer"
                                      ? "100%"
                                      : "calc(100% - 45px - 10px)",
                                }}
                              >
                                <div
                                  style={{
                                    width: 85,
                                    paddingTop: 7,
                                    height: 45,
                                  }}
                                  className="text-exo-bold"
                                >
                                  <p
                                    className={
                                      "mb-0 " +
                                      Utils.colorTipoTareaDetalle(
                                        tarea.type_task,
                                        true
                                      )
                                    }
                                    style={{ fontSize: "12px" }}
                                  >
                                    {Utils.colorTipoTareaDetalle(
                                      tarea.type_task,
                                      false
                                    )}
                                  </p>
                                  <p
                                    className="mb-0 sub-txt-carta cursor-pointer"
                                    style={{ paddingTop: 1 }}
                                    onClick={() => {
                                      Utils.justCopyText2(
                                        "tarea-" + tarea._id,
                                        tarea.code_task
                                      );
                                    }}
                                  >
                                    <span
                                      style={{
                                        backgroundColor: "#f2f2f2",
                                        borderRadius: 4,
                                      }}
                                      className="px-1"
                                    >
                                      {"ID: " + tarea.code_task}
                                    </span>
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "calc(100% - 85px)",
                                    paddingTop: 7,
                                    height: 42,
                                  }}
                                >
                                  <p
                                    className="mb-0 sub-txt-carta d-flex align-items-center"
                                    style={{ height: 20, paddingBottom: 3 }}
                                  >
                                    {moment(tarea.date_task)
                                      .utcOffset("+00")
                                      .format("DD-MM-YYYY HH:mm")}
                                  </p>
                                  <p className="mb-0 txt-carta-name text-ellipsis">
                                    {Utils.toCapitalize(
                                      tarea.contact_name_task.split("-")[0]
                                    )}
                                  </p>
                                </div>
                                <div
                                  className="w-100 sub-txt-carta color-light-grey"
                                  style={{ height: 87 }}
                                >
                                  <p className="mb-0">
                                    <b>
                                      {this.props.clientes
                                        ? this.props.clientes.map(
                                            (cliente, i) =>
                                              cliente._id ===
                                              tarea.idcustomer_task
                                                ? cliente.name_customer
                                                : false
                                          )
                                        : false}
                                    </b>
                                  </p>
                                  <p className="mb-0">{tarea.address_task}</p>
                                  {tarea.tags ? (
                                    <p className="mb-0">
                                      <b>{"#" + tarea.tags}</b>
                                    </p>
                                  ) : (
                                    false
                                  )}

                                  {tarea.zone_name ? (
                                    <p className="mb-0">
                                      <b>Zona:&nbsp;</b>
                                      {tarea.zone_name}
                                    </p>
                                  ) : (
                                    <p className="mb-0">
                                      <b>Zona:&nbsp;</b>
                                      <em>No definida</em>
                                    </p>
                                  )}
                                </div>
                              </div>

                              {this.props.vista === "customer" ? (
                                false
                              ) : (
                                <div
                                  className="d-flex align-items-center justify-content-start"
                                  style={{ width: 10 }}
                                >
                                  <img
                                    src={IconoFlechaAzul}
                                    className="cursor-pointer"
                                    width="6"
                                    alt="ir"
                                    onClick={() => {
                                      sessionStorage.setItem(
                                        "taskGroupID",
                                        tarea.idTasksGroup_task
                                      );
                                      // this.props.data.history.push(
                                      //     '/Home/Tarea/Editar/' + tarea._id
                                      // );
                                      window.location.href =
                                        "/Home/Tarea/Editar/" + tarea._id;
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Tooltip>
                    );
                  }
                })
              )}
            </div>
          </div>

          {this.props.vista === "customer" ? (
            false
          ) : (
            <div className="bottom-fix">
              <div className="row m-0">
                <div className="col-12 px-3">
                  <Button
                    className="text-capitalize py-1 w-100"
                    onClick={() => {
                      sessionStorage.setItem("taskGroupID", grupoTareas._id);
                      // this.props.data.history.push('/Home/Tarea/Crear/' + grupoTareas._id);
                      window.location.href =
                        "/Home/Tarea/Crear/" + grupoTareas._id;
                    }}
                    variant="contained"
                    color="primary"
                    startIcon={
                      <img
                        height="18"
                        src={IconoTarea}
                        alt="Icono tarea"
                        className="mb-1"
                      />
                    }
                  >
                    Crear Tarea
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  boxTareas: {
    backgroundColor: theme.palette.success.dark,
    color: theme.palette.success.light,
    fontFamily: "Roboto-Regular",
    position: "absolute",
    padding: ".55rem 1rem",
    borderRadius: 4,
  },
  bordeOrdenList: {
    borderLeft: "2px solid " + theme.palette.success.dark,
  },
  bordeOrdenList1: {
    borderLeft: "2px solid " + theme.palette.success.dark,
  },
  bordeOrdenListX: {
    borderTop: "2px solid " + theme.palette.success.dark,
  },
  borderPrimary: {
    border: "2px solid " + theme.palette.success.dark,
  },
  bgSuccess: {
    backgroundColor: theme.palette.success.main,
  },
});

export default withStyles(styles, { withTheme: true })(DetalleGrupoTareas);
