import { OBTENER_TEMPLATES, ERROR_TEMPLATES, RES_ERROR } from '../actions/Templates';

const estadoInicial = {
    templates: null,
    error: null,
};

const INITIAL_STATE = {
    loggedIn: true,
};

const templatesReducer = (estado = estadoInicial, accion) => {
    switch (accion.type) {
        case OBTENER_TEMPLATES:
            return {
                ...estado,
                templates: accion.templates,
            };
        case ERROR_TEMPLATES:
            return {
                ...estado,
                error: accion.error,
            };
        case RES_ERROR:
            return INITIAL_STATE;
        default:
            return estado;
    }
};

export default templatesReducer;