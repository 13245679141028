import React from "react";
import $ from "jquery";
import cloneDeep from "lodash/cloneDeep";
import PlacesAutocomplete from "react-places-autocomplete";

import Button from "@material-ui/core/Button";
import { FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import parse from "autosuggest-highlight/parse";

import Utils from "../../utils/Utils";
import CustomExpandMore from "../../components/custom/CustomExpandMore";

import IconoCerrar from "../../icons/icono-cerrar-white.svg";

class EditarTareas extends React.Component {
  camposEditar = () => {
    if (this.props.opcionEditar === "null") {
      Utils.handleAlert(
        "Añadir campo",
        "Debe seleccionar un campo válido para añadirlo al formulario de edición",
        "Ok"
      );
    } else {
      let campos = cloneDeep(this.props.camposEditar);
      let camposSelected = cloneDeep(this.props.camposEditarSelected);
      camposSelected.push(campos[this.props.opcionEditar]);

      this.props.handleState("camposEditarSelected", camposSelected);
      campos.splice(this.props.opcionEditar, 1);
      this.props.handleState("camposEditar", campos);
      this.props.handleState("opcionEditar", "null");
    }
  };

  validar = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
      this.props.modify();
    }
  };

  render() {
    return (
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal-white">
          <form onSubmit={this.validar}>
            <div className="modal-body">
              {this.props.vista === "mapa" ? (
                <div className="w-100">
                  <h4 className="h4-modal text-center">Edición GT</h4>
                  <h4 className="text-roboto-regular color-mega-dark-grey text-center">
                    ID Orden de Grupo de Tareas
                  </h4>
                </div>
              ) : (
                <div className="w-100">
                  <h4 className="h4-modal text-center">Edición Masiva</h4>
                  <h4 className="text-roboto-regular color-mega-dark-grey text-center">
                    ID Orden de Tarea/s
                  </h4>
                </div>
              )}

              <div className="w-100 back-grey-input br-4 p-2 mb-2">
                <h6 className="w-100 text-roboto-regular text-center color-mega-dark mb-0">
                  {typeof this.props.code === undefined ||
                  this.props.code === null
                    ? ""
                    : this.props.code.map((code, i) => {
                        let codigo = code.codigo;
                        if (i !== 0) {
                          codigo = ", " + codigo;
                        }
                        return codigo;
                      })}
                </h6>
              </div>

              <label className="text-roboto-regular color-mega-dark-grey mb-1">
                Selecciona la opción
              </label>
              <div className="row m-0 mb-3">
                <div className="col-10 p-0">
                  <FormControl variant="filled" className="w-100">
                    <Select
                      required
                      className="select-crear select-modal color-mega-dark-grey"
                      labelId="demo-simple-select-filled"
                      id="demo-simple-select"
                      value={this.props.opcionEditar}
                      onChange={this.props.handleChange}
                      name="opcionEditar"
                      IconComponent={CustomExpandMore}
                    >
                      <MenuItem value="null">Seleccionar</MenuItem>
                      {this.props.camposEditar.map((campo, i) => (
                        <MenuItem value={i} key={i}>
                          {campo.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-2 pr-0 pl-4">
                  <div
                    className="bg-primary br-4 w-100 h-100 d-flex justify-content-center align-items-center cursor-pointer"
                    onClick={this.camposEditar}
                  >
                    <img
                      src={IconoCerrar}
                      height="20"
                      className="rotar-icono-45"
                      alt="icon"
                    />
                  </div>
                </div>
              </div>
              {this.props.camposEditarSelected.map((campo, i) => {
                if (campo.nombre === "date_task") {
                  return (
                    <div className="row m-0 mb-3" key={i}>
                      <div className="col-3 p-0 d-flex align-items-center">
                        <label className="text-roboto-regular color-mega-dark-grey mb-0">
                          {campo.label}
                        </label>
                      </div>
                      <div className="col-9 pr-0">
                        <TextField
                          required
                          id="datetime-local"
                          name={campo.nombre}
                          type="datetime-local"
                          className="form-control txt-modal"
                          value={campo.data}
                          onChange={this.props.handleChangeEditar}
                          InputLabelProps={{
                            shrink: false,
                          }}
                        />
                      </div>
                    </div>
                  );
                } else if (campo.nombre === "address_task") {
                  return (
                    <div className="row m-0 mb-3" key={i}>
                      <div className="col-3 p-0 d-flex align-items-center">
                        <label className="text-roboto-regular color-mega-dark-grey mb-0">
                          {campo.label}
                        </label>
                      </div>
                      <div className="col-9 pr-0">
                        <PlacesAutocomplete
                          value={campo.data}
                          searchOptions={{
                            componentRestrictions: { country: ["cl"] },
                          }}
                          onChange={(e) =>
                            this.props.handleChangeEditarAddress(e)
                          }
                          onSelect={(e) => this.props.handleAddressSelect(e)}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => {
                            return (
                              <Autocomplete
                                name="address_task"
                                options={suggestions}
                                getOptionLabel={(option) => option.description}
                                onChange={(e, value) => {
                                  this.props.handleAddressSelect(value);
                                }}
                                value={{ description: campo.data }}
                                onInputChange={(e, value) => {
                                  this.props.handleChangeEditarAddress(value);
                                }}
                                getOptionSelected={(option, value) => {
                                  return value.description;
                                }}
                                clearOnBlur={true}
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      {...params}
                                      {...getInputProps({
                                        name: "address_task",
                                        className:
                                          "form-control w-100 px-2 txt-modal",
                                        placeholder:
                                          "Compañia de Jesus #1249, Santiago*",
                                      })}
                                    />
                                  );
                                }}
                                noOptionsText={
                                  loading
                                    ? "Cargando..."
                                    : "No ha seleccionado una opción"
                                }
                                renderOption={(option) => {
                                  const matches = option.matchedSubstrings;
                                  const parts = parse(
                                    option.formattedSuggestion.mainText,
                                    matches.map((match) => [
                                      match.offset,
                                      match.offset + match.length,
                                    ])
                                  );

                                  return (
                                    <Grid container alignItems="center">
                                      <Grid item>
                                        <LocationOnIcon className="color-light-grey mr-2" />
                                      </Grid>
                                      <Grid item xs>
                                        {parts.map((part, index) => (
                                          <span
                                            key={index}
                                            style={{
                                              fontWeight: part.highlight
                                                ? 700
                                                : 400,
                                            }}
                                          >
                                            {part.text}
                                          </span>
                                        ))}

                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                        >
                                          {
                                            option.formattedSuggestion
                                              .secondaryText
                                          }
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  );
                                }}
                              />
                            );
                          }}
                        </PlacesAutocomplete>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="row m-0 mb-3" key={i}>
                      <div className="col-3 p-0 d-flex align-items-center">
                        <label className="text-roboto-regular color-mega-dark-grey mb-0">
                          {campo.label}
                        </label>
                      </div>
                      <div className="col-9 pr-0">
                        <input
                          required
                          placeholder={campo.label}
                          className="form-control txt-modal"
                          name={campo.nombre}
                          value={campo.data}
                          onChange={this.props.handleChangeEditar}
                          maxLength={
                            campo.nombre === "contact_phone_task" ? 9 : 80
                          }
                        />
                      </div>
                    </div>
                  );
                }
              })}
            </div>
            <div className="modal-footer row m-0">
              <div className="col-6 d-flex justify-content-center">
                <Button
                  type="button"
                  variant="text"
                  color="primary"
                  onClick={() => {
                    this.props.resetEditar();
                    $("#modal").modal("hide");
                  }}
                >
                  Cancelar
                </Button>
              </div>
              <div className="col-6 d-flex justify-content-center">
                <Button type="submit" variant="contained" color="secondary">
                  Confirmar
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default EditarTareas;
