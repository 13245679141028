import React from "react";
import $ from "jquery";
import moment from "moment";

import Utils from "../../utils/Utils";

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Checkbox, IconButton, Popover, Button } from "@material-ui/core";

import IconoEliminar from "../../icons/icono-trash-black.svg";
import IconoAccion from "../../icons/icono-accion-horizontal-black1.png";
import IconoEditar from "../../icons/icono-pencil-black.svg";
import IconoDuplicar from "../../icons/icono-duplicar-black.svg";
import IconoReasignar from "../../icons/icono-user-black.svg";
import IconoCambiar from "../../icons/icono-cambiar-black.svg";
import IconoVolver from "../../icons/return.svg";
import IconoCancelar from "../../icons/cancelar.svg";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

import Skeleton from "@material-ui/lab/Skeleton";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";

const DotEstado = ({ id }) => {
  return (
    <Button
      className={
        Utils.colorTarea(id, true) +
        " pt-0 pb-0 py-1 txt-carta text-capitalize text-roboto-regular button-state"
      }
      disabled
    >
      {Utils.colorTarea(id, false)}
    </Button>
  );
};

const TipoTarea = ({ id }) => {
  return (
    <div
      className={
        Utils.colorTipoTarea(id, true) +
        " dot-tipo-tarea text-white d-flex align-items-center justify-content-center d-block mx-auto"
      }
    >
      {Utils.colorTipoTarea(id, false)}
    </div>
  );
};

const Etiqueta = ({ url }) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <IconButton>
        <LocalOfferIcon />
      </IconButton>
    </a>
  );
};

const ListaTareas = ({
  columns,
  tareas,
  rayos,
  page,
  rowsPerPage,
  openFiltros,
  grupoTareas,
  filtroTareas,
  estadoSelected,
  anchorElFiltros,
  cleanSelectedT,
  checkAll,
  handleCheck,
  handleState,
  getTasksGroupById,
  propsData,
  getTemplate,
}) => {
  const linkSeguimiento = (task) => {
    if (task.customer.length !== 0) {
      let nameCustomer = "";
      task.customer.forEach((element) => {
        nameCustomer = element.name_customer;
      });

      let newName = Utils.nameCustomerSeguimiento(nameCustomer);
      let link =
        "https://seguimiento.thor.rayoapp.com/" +
        newName +
        "/?id=" +
        task.order_id_task;
      link.replace(/\s/g, "");
      window.open(link);
    } else {
      Utils.handleAlert(
        "Link de seguimiento",
        "Esta tarea no tiene la información suficiente para generar el link de seguimiento, contactenos para más información",
        "Ok"
      );
    }
  };

  return (
    <TableContainer className="table-white small-font mt-4">
      <Table aria-label="Type activities">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell align={column.align} key={index}>
                {column.label === "check" ? (
                  tareas.length === 0 ? (
                    false
                  ) : (
                    <Checkbox
                      className="p-2"
                      color="primary"
                      checked={grupoTareas.seleccionadoT}
                      onClick={() => {
                        if (grupoTareas.seleccionadoT) {
                          cleanSelectedT();
                        } else {
                          checkAll();
                        }
                      }}
                    />
                  )
                ) : (
                  <b>{column.label}</b>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tareas ? (
            tareas.length === 0 ? (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableCell colSpan={columns.length} align="center">
                  <em>
                    - No se encuentran tareas dentro de los parametros
                    ingresados -
                  </em>
                </TableCell>
              </TableRow>
            ) : (
              tareas.map((row, index) => (
                <TableRow hover key={index}>
                  {columns.map((column, indexC) => {
                    const value = row[column.id];
                    if (column.id === "idstatus_task") {
                      return (
                        <TableCell key={indexC} align="left">
                          <DotEstado id={value} />
                        </TableCell>
                      );
                    } else if (column.id === "customer") {
                      if (row.customer) {
                        return (
                          <TableCell key={indexC} align="left">
                            {row.customer.map(
                              (customer) => customer.name_customer
                            )}
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell
                            key={indexC}
                            align="left"
                          ></TableCell>
                        );
                      }
                    } else if (column.id === "check") {
                      return (
                        <TableCell key={indexC} align="left">
                          <Checkbox
                            checked={row.selected}
                            value="checkedA"
                            color="primary"
                            onClick={(e) => handleCheck(e, row)}
                          />
                        </TableCell>
                      );
                    } else if (column.id === "date_task") {
                      return (
                        <TableCell key={indexC} align="left">
                          {/* {moment(value)
                            .utcOffset("+00")
                            .format("DD-MM-YYYY HH:mm")} */}
                          {moment(value)
                            .utcOffset("+00")
                            .format("DD-MM-YYYY HH:mm")}
                        </TableCell>
                      );
                    } else if (column.id === "end_date_task") {
                      // return (
                      // <TableCell
                      //     key={indexC}
                      //     className={
                      //         column.classtd + Utils.fechaTerminoTask(row.history_task).color
                      //     }
                      // >
                      //     {Utils.fechaTerminoTask(row.history_task).texto}
                      // </TableCell>
                      // );

                      if (value === null) {
                        return (
                          <TableCell
                            key={indexC}
                            align="left"
                            className="color-light-grey"
                          >
                            <em>-No definido-</em>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={indexC} align="left">
                            {moment(value)
                              .utcOffset("+00")
                              .format("DD-MM-YYYY HH:mm")}
                          </TableCell>
                        );
                      }
                    } else if (column.id === "type_task") {
                      return (
                        <TableCell key={indexC} align="left">
                          <TipoTarea id={value} />
                        </TableCell>
                      );
                    } else if (column.id === "link_seguimiento") {
                      return (
                        <TableCell key={indexC} align="center">
                          <IconButton
                            aria-label="history"
                            onClick={() => linkSeguimiento(row)}
                          >
                            <LibraryBooksIcon />
                          </IconButton>
                        </TableCell>
                      );
                    } else if (column.id === "metadata_task") {
                      if (value.Etiqueta) {
                        return (
                          <TableCell key={indexC} align="center">
                            <Etiqueta url={value.Etiqueta} />
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={indexC} align="center">
                            <em>-Sin etiqueta-</em>
                          </TableCell>
                        );
                      }
                    } else if (column.id === "accion") {
                      return (
                        <TableCell key={indexC} align="center">
                          <div>
                            <IconButton
                              className="p-2"
                              aria-controls="fade-menu"
                              onClick={(e) =>
                                handleState("anchorElFiltros", e.currentTarget)
                              }
                              aria-describedby={row._id}
                              name={row._id}
                            >
                              <img src={IconoAccion} width={20} alt="accion" />
                            </IconButton>

                            <Popover
                              id={openFiltros ? row._id : undefined}
                              open={
                                openFiltros && anchorElFiltros.name === row._id
                                  ? true
                                  : false
                              }
                              anchorEl={anchorElFiltros}
                              onClose={() =>
                                handleState("anchorElFiltros", null)
                              }
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              getContentAnchorEl={null}
                            >
                              <div
                                className="row m-0 opc-drop border-bottom-0"
                                onClick={() =>
                                  propsData.history.push(
                                    "/Home/Tarea/Editar/" + row._id
                                  )
                                }
                              >
                                <div className="col-md-4 d-flex align-items-center">
                                  <img
                                    src={IconoEditar}
                                    height="20"
                                    alt="icono"
                                  />
                                </div>
                                <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
                                  Editar
                                </div>
                              </div>
                              <div
                                className="row m-0 opc-drop border-bottom-0"
                                onClick={() => {
                                  handleState("tarea", row);
                                  handleState("tipoModal", 2);
                                  handleState("anchorElFiltros", null);
                                  $("#modal").modal("show");
                                }}
                              >
                                <div className="col-md-4 d-flex align-items-center">
                                  <img
                                    src={IconoDuplicar}
                                    height="20"
                                    alt="icono"
                                  />
                                </div>
                                <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
                                  Duplicar
                                </div>
                              </div>
                              <div
                                className="row m-0 opc-drop border-bottom-0"
                                onClick={() => {
                                  let rayo = "";
                                  if (
                                    row.idrayo_task === "null" ||
                                    row.idrayo_task === null
                                  ) {
                                    rayo = "";
                                  } else {
                                    const i = rayos.findIndex(
                                      (element) =>
                                        element._id === row.idrayo_task
                                    );
                                    if (i > 0) {
                                      rayo = rayos[i];
                                    }
                                  }
                                  handleState("tarea", row);
                                  handleState("rayo_selected", rayo);
                                  handleState("tipoModal", 3);
                                  handleState("anchorElFiltros", null);
                                  $("#modal").modal("show");
                                }}
                              >
                                <div className="col-md-4 d-flex align-items-center">
                                  <img
                                    src={IconoReasignar}
                                    height="20"
                                    alt="icono"
                                  />
                                </div>
                                <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
                                  Reasignar
                                </div>
                              </div>
                              <div
                                className="row m-0 opc-drop border-bottom-0"
                                onClick={() => {
                                  handleState(
                                    "opcionEditarEstado",
                                    row.idstatus_task
                                  );
                                  handleState("tarea", row);
                                  handleState("tipoModal", 4);
                                  handleState("anchorElFiltros", null);
                                  $("#modal").modal("show");
                                  getTemplate(row);
                                }}
                              >
                                <div className="col-md-4 d-flex align-items-center">
                                  <img
                                    src={IconoCambiar}
                                    height="20"
                                    alt="icono"
                                  />
                                </div>
                                <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
                                  Cambiar estado
                                </div>
                              </div>

                              <div
                                className="row m-0 opc-drop border-bottom-0"
                                onClick={() => {
                                  handleState("tarea", row);
                                  handleState("tipoModal", 7);
                                  handleState("anchorElFiltros", null);
                                  $("#modal").modal("show");
                                }}
                              >
                                <div className="col-md-4 d-flex align-items-center">
                                  <img
                                    src={IconoVolver}
                                    height="20"
                                    alt="icono"
                                  />
                                </div>
                                <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
                                  Re abrir tarea
                                </div>
                              </div>

                              <div
                                className="row m-0 opc-drop border-bottom-0"
                                onClick={() => {
                                  handleState("tarea", row);
                                  handleState("tipoModal", 8);
                                  handleState("anchorElFiltros", null);
                                  $("#modal").modal("show");
                                }}
                              >
                                <div className="col-md-4 d-flex align-items-center">
                                  <img
                                    src={IconoCancelar}
                                    height="20"
                                    alt="icono"
                                  />
                                </div>
                                <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
                                  Cancelar tarea
                                </div>
                              </div>

                              <div
                                className="row m-0 opc-drop border"
                                onClick={() => {
                                  handleState("tarea", row);
                                  handleState("tipoModal", 5);
                                  handleState("anchorElFiltros", null);
                                  $("#modal").modal("show");
                                }}
                              >
                                <div className="col-md-4 d-flex align-items-center">
                                  <img
                                    src={IconoEliminar}
                                    height="20"
                                    alt="icono"
                                  />
                                </div>
                                <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
                                  Eliminar
                                </div>
                              </div>
                            </Popover>
                          </div>
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell
                          key={indexC}
                          align="left"
                          className={
                            column.id === "order_id_task" ? "href" : ""
                          }
                          onClick={() => {
                            if (column.id === "order_id_task") {
                              getTasksGroupById(row.idTasksGroup_task);
                            }
                          }}
                        >
                          {value}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))
            )
          ) : (
            <TableRow hover role="checkbox" tabIndex={-1}>
              {columns.map((e, i) => (
                <TableCell key={i}>
                  <Skeleton />
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListaTareas;
