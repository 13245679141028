
import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import cloneDeep from "lodash/cloneDeep";
import "../../css/global.css";
import url from "../../config";
import Utils from "../../assets/utils/Utils";
import { withStyles } from "@material-ui/core/styles";
import CopyText from "../../assets/components/custom/CopyText";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@material-ui/core";

import ActiveDot from "../../assets/components/mantenedores/ActiveDot";
import TextFieldOutlined from "../../assets/components/custom/TextFieldOutlined";

import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import Skeleton from "@material-ui/lab/Skeleton";

const IconoUsuario =
  "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/genericImage.png";
const columns = [
  {
    id: "copiar",
    label: "Copiar ID",
    align: "left",
  },
  { id: "image_provider", label: "Imagen", align: "left" },
  { id: "name_provider", label: "Nombre", align: "left" },
  { id: "dni_provider", label: "DNI", align: "left" },
  {
    id: "type_vehicles_provider",
    label: "Tipos de vehículos",
    align: "left",
  },
  { id: "status_provider", label: "Estado", align: "left" },
  {
    id: "accion",
    label: "Acción",
    align: "right",
  },
];
class Proveedores extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,

      search: "",
      tipoModal: 1,
      tipoVehiculos: [],
      proveedores: null,
      form: {
        proveedor: "",
        key: "",
        email_provider: "",
        phone_provider: "",
        code_provider: "",
        image_provider: IconoUsuario,
        name_provider: "",
        dni_provider: "",
        nationality_provider: "",
        number_license_provider: "",
        status_provider: "1",
        type_vehicles_provider: [],
        EPA_pickup_price: "",
        EPA_delivery_price: "",
        EPA_appointment_price: "",
        Rayo_pickup_price: "",
        Rayo_delivery_price: "",
        Rayo_appointment_price: "",
        emails: [],
        id_partners: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.getProveedores();
  }

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  keyPress = (e) => {
    if(e.keyCode == 13){
      this.setState({
        mail: {
          ...this.state.mail,
          email: this.state.form.mails,
        },
      });
    }
 }

 

     

  handlePhone = (e) => {
    let name = "phone_provider";
    let value = "+ " + e.replace(/[^0-9.]/g, "");
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  handleChangeJustNumbers = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let numbers = value.replace(/[^0-9.]/g, "");
    this.setState({
      form: {
        ...this.state.form,
        [name]: numbers,
      },
    });
  };

  handleChangeButton = (value) => {
    this.setState({
      form: {
        ...this.state.form,
        status_provider: value,
      },
    });
  };

  handleVehicle = (i) => {
    let newArray = [...this.state.form.type_vehicles_provider];
    newArray[i] = {
      ...newArray[i],
      activo: !newArray[i].activo,
    };

    this.setState({
      form: {
        ...this.state.form,
        type_vehicles_provider: newArray,
      },
    });
  };

  handleImage = (e) => {
    try {
      e.preventDefault();
      const estado = this;
      const { name } = e.target;
      const reader = new FileReader();

      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function () {
        estado.setState({
          form: {
            ...estado.state.form,
            [name]: reader.result,
          },
        });
      };
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error al subir la imagen, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  handleModal = async (tipo, proveedor) => {
    this.setState({
      tipoModal: tipo,
    });
    if (tipo === 1) {
      $("#modalFormulario").modal("show");
    } else {
      let array = cloneDeep(this.state.tipoVehiculos);
      array.forEach((v) => {
        proveedor.type_vehicles_provider.forEach((r) => {
          if (v._id === r.idTypeVehicle) {
            v.activo = true;
          }
        });
      });
      await this.setState({
        form: {
          ...this.state.form,
          key: proveedor._id,
          email_provider: proveedor.email_provider
            ? proveedor.email_provider
            : "",
          phone_provider: proveedor.phone_provider
            ? "+ " + proveedor.phone_provider
            : "",
          code_provider: proveedor.code_provider,
          image_provider: proveedor.image_provider,
          name_provider: proveedor.name_provider,
          dni_provider: proveedor.dni_provider,
          nationality_provider: proveedor.nationality_provider,
          number_license_provider: proveedor.number_license_provider,
          status_provider: proveedor.status_provider,
          type_vehicles_provider: array,
          EPA_pickup_price: proveedor.EPA_pickup_price,
          EPA_delivery_price: proveedor.EPA_delivery_price,
          EPA_appointment_price: proveedor.EPA_appointment_price,
          Rayo_pickup_price: proveedor.Rayo_pickup_price,
          Rayo_delivery_price: proveedor.Rayo_delivery_price,
          Rayo_appointment_price: proveedor.Rayo_appointment_price,
          id_partners: proveedor.id_partners,
        },
      });

      //console.log("siberia",this.state)
      $("#modalFormulario").modal("show");
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0,
    });
  };

  emptyForm = () => {
    this.setState({
      form: {
        ...this.state.form,
        key: "",
        email_provider: "",
        phone_provider: "",
        code_provider: "",
        image_provider: IconoUsuario,
        name_provider: "",
        dni_provider: "",
        nationality_provider: "",
        number_license_provider: "",
        status_provider: "1",
        type_vehicles_provider: [...this.state.tipoVehiculos],
        EPA_pickup_price: "",
        EPA_delivery_price: "",
        EPA_appointment_price: "",
        Rayo_pickup_price: "",
        Rayo_delivery_price: "",
        Rayo_appointment_price: "",
        id_partners: "",
      },
    });
  };

  getProveedores = async () => {
    try {
      let usuario = JSON.parse(sessionStorage.getItem("user"));
      let data = await axios({
        method: "get",
        url: url.host + "/Providers/providers/",
        headers: { tenant: usuario.tenant },
      }).then(({ data }) => data);
      this.setState({ proveedores: data });
      this.getTipoVehiculos();
    } catch (err) {
      Utils.handleAlert(
        "Obtener proveedores",
        "No se han podido cargar los proveedores",
        "Ok"
      );
    }
  };

  getTipoVehiculos = async () => {
    try {
      let usuario = JSON.parse(sessionStorage.getItem("user"));
      let data = await axios({
        method: "get",
        url: url.host + "/Type-Vehicles/type_vehicles/",
        headers: { tenant: usuario.tenant },
      }).then(({ data }) => data);
      data.forEach((v) => {
        v.activo = false;
        return v;
      });
      this.setState({
        tipoVehiculos: data,
        form: {
          ...this.state.form,
          type_vehicles_provider: data,
        },
      });
    } catch (err) {
      Utils.handleAlert(
        "Obtener tipos de vehículo",
        "No se han podido cargar los tipos de vehículo",
        "Ok"
      );
    }
  };

  createProveedor = async () => {
    try {
      let tiposVehiculo = cloneDeep(this.state.form.type_vehicles_provider);
      let newTiposVehiculo = [];

      tiposVehiculo.forEach((tipo) => {
        if (tipo.activo) {
          newTiposVehiculo.push({
            idTypeVehicle: tipo._id,
          });
        }
      });

      let imagen = this.state.form.image_provider;
      if (imagen === IconoUsuario) {
        imagen = "";
      }

      let numero = this.state.form.phone_provider.split("+");

      const proveedor = {
        code_provider: this.state.form.code_provider,
        image_provider: imagen,
        email_provider: this.state.form.email_provider,
        phone_provider: numero.length === 1 ? numero[0] : numero[1],
        name_provider: this.state.form.name_provider.trim(),
        dni_provider: this.state.form.dni_provider.trim(),
        nationality_provider: this.state.form.nationality_provider,
        number_license_provider: this.state.form.number_license_provider,
        status_provider: parseInt(this.state.form.status_provider),
        type_vehicles_provider: newTiposVehiculo,
        EPA_pickup_price: parseInt(this.state.form.EPA_pickup_price),
        EPA_delivery_price: parseInt(this.state.form.EPA_delivery_price),
        EPA_appointment_price: parseInt(this.state.form.EPA_appointment_price),
        Rayo_pickup_price: parseInt(this.state.form.Rayo_pickup_price),
        Rayo_delivery_price: parseInt(this.state.form.Rayo_delivery_price),
        Rayo_appointment_price: parseInt(
          this.state.form.Rayo_appointment_price
        ),
        tenant: this.state.form.tenant,
        id_partners: this.state.form.id_partners
      };

      let usuario = JSON.parse(sessionStorage.getItem("user"));
      let res = await url.api.post("/Providers/provider/", proveedor, { headers: { tenant: usuario.tenant } });
      if (res.status === 201 || res.status === 200) {
        $("#modalFormulario").modal("hide");
        this.getProveedores();
        Utils.handleAlert(
          "Proveedor ingresado",
          "El proveedor se ha ingresado exitosamente",
          "Ok"
        );
        this.emptyForm();
      } else {
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al ingresar el proveedor, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al ingresar un proveedor, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  confirmDelete = (key) => {
    Swal.fire({
      title: "¿Eliminar Proveedor?",
      text: "Si confirma, no podrá deshacer la acción y el proveedor será eliminado de forma permanente",
      imageUrl:
        "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayoapp-red.svg",
      showCancelButton: true,
      customClass: {
        image: "imageSweetAlert",
        title: "titleSweetAlert",
        content: "textSweetAlert",
        confirmButton: "buttonSweetAlert",
        cancelButton: "btn-secondary",
      },
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.deleteProveedor(key);
      }
    });
  };

  deleteProveedor = async (key) => {
    try {
      const proveedor = {
        key: key,
        tenant: this.state.form.tenant
      };
      let usuario = JSON.parse(sessionStorage.getItem("user"));
      let res = await url.api.delete("/Providers/provider/", {
        data: proveedor,
        headers: { Authorization: "***", headers: { tenant: usuario.tenant } },
      });
      if (res.status === 200) {
        this.getProveedores();
        Utils.handleAlert(
          "Proveedor eliminado",
          "El proveedor se ha eliminado exitosamente",
          "Ok"
        );
      } else {
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al eliminar el proveedor, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al eliminar el proveedor, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  modifyProveedor = async () => {
    try {
      let tiposVehiculo = cloneDeep(this.state.form.type_vehicles_provider);
      let newTiposVehiculo = [];

      tiposVehiculo.forEach((tipo) => {
        if (tipo.activo) {
          newTiposVehiculo.push({
            idTypeVehicle: tipo._id,
          });
        }
      });

      let imagen = this.state.form.image_provider;
      if (imagen === IconoUsuario) {
        imagen = "";
      }

      let numero = this.state.form.phone_provider.split("+");

      const proveedor = {
        key: this.state.form.key,
        code_provider: this.state.form.code_provider,
        image_provider: imagen,
        email_provider: this.state.form.email_provider
          .toLowerCase()
          .replace(" ", ""),
        phone_provider: numero.length === 1 ? numero[0] : numero[1],
        name_provider: this.state.form.name_provider.trim(),
        dni_provider: this.state.form.dni_provider.trim(),
        nationality_provider: this.state.form.nationality_provider,
        number_license_provider: this.state.form.number_license_provider,
        status_provider: parseInt(this.state.form.status_provider),
        type_vehicles_provider: newTiposVehiculo,
        EPA_pickup_price: parseInt(this.state.form.EPA_pickup_price),
        EPA_delivery_price: parseInt(this.state.form.EPA_delivery_price),
        EPA_appointment_price: parseInt(this.state.form.EPA_appointment_price),
        Rayo_pickup_price: parseInt(this.state.form.Rayo_pickup_price),
        Rayo_delivery_price: parseInt(this.state.form.Rayo_delivery_price),
        Rayo_appointment_price: parseInt(
          this.state.form.Rayo_appointment_price
        ),
        tenant: this.state.form.tenant,
        id_partners: this.state.form.id_partners
      };

      let usuario = JSON.parse(sessionStorage.getItem("user"));
      let res = await url.api.put("/Providers/provider/", proveedor, { headers: { tenant: usuario.tenant } });
      if (res.status === 200) {
        this.getProveedores();
        $("#modalFormulario").modal("hide");
        Utils.handleAlert(
          "Proveedor modificado",
          "El proveedor se ha modificado exitosamente",
          "Ok"
        );
        this.emptyForm();
      } else {
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al modificar el proveedor, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al modificar el proveedor, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  render() {
    const { page, rowsPerPage, tipoModal } = this.state;
    const { classes } = this.props;

    const ImagenProveedor = ({ imagen }) => {
      return <img src={imagen} height="35" className="icon-table" alt="rayo" />;
    };

    const TipoVehiculos = ({ tipoVehiculos }) => {
      let array = cloneDeep(this.state.tipoVehiculos);
      array.forEach((v) => {
        tipoVehiculos.forEach((r) => {
          if (v._id === r.idTypeVehicle) {
            v.activo = true;
          }
        });
      });

      return array.map((tipo, index) => {
        return (
          <Button
            variant="contained"
            disabled
            className={
              tipo.activo
                ? classes.btnTipoVehiculosActive
                : classes.btnTipoVehiculos
            }
            key={index}
          >
            <img
              src={
                tipo.activo
                  ? tipo.active_icon_type_vehicle
                  : tipo.inactive_icon_type_vehicle
              }
              width="25"
              alt="Icono activo"
            />
          </Button>
        );
      });
    };

    const Vehiculos = ({ vehiculos }) => {
      return vehiculos.map((vehiculo, index) => {
        return (
          <Button
            variant="contained"
            className={
              vehiculo.activo
                ? classes.btnTipoVehiculosActive
                : classes.btnTipoVehiculos
            }
            onClick={() => this.handleVehicle(index)}
            key={index}
          >
            <img
              src={
                vehiculo.activo
                  ? vehiculo.active_icon_type_vehicle
                  : vehiculo.inactive_icon_type_vehicle
              }
              width="25"
              alt="Icono activo"
            />
          </Button>
        );
      });
    };

    return (
      <Box pt={4}>
        <Container>
          <Typography variant="h1" gutterBottom>
            Proveedores
          </Typography>

          <Box width="100%" display="flex" mb={3}>
            <Box flexGrow="1">
              <TextFieldOutlined
                size="small"
                name="search"
                value={this.state.search}
                onChange={(e) => {
                  if (this.state.page !== 0) this.setState({ page: 0 });
                  this.setState({ search: e.target.value });
                }}
                variant="outlined"
                placeholder="Buscar..."
                style={{ minWidth: 250 }}
              />
            </Box>
            <Button
              color="secondary"
              variant="contained"
              data-toggle="modal"
              data-target="#crearModal"
              onClick={() => this.handleModal(1)}
            >
              Crear nuevo
            </Button>
          </Box>

          <TableContainer className="table-white">
            <Table aria-label="Type activities">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell align={column.align} key={index}>
                      <b>{column.label}</b>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.proveedores ? (
                  this.state.proveedores.length === 0 ? (
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell colSpan={columns.length} align="center">
                        <em>- No existen proveedores creados -</em>
                      </TableCell>
                    </TableRow>
                  ) : (
                    this.state.proveedores
                      .filter((proveedor) => {
                        let bandera = false;
                        if (
                          proveedor.name_provider
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase()) ||
                          proveedor.dni_provider
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase()) ||
                          proveedor._id
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase())
                        ) {
                          bandera = true;
                        }
                        return bandera;
                      })
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, i) => (
                        <TableRow hover key={i}>
                          <TableCell align="left">
                            <CopyText text={row._id} />
                          </TableCell>
                          <TableCell align="left">
                            <ImagenProveedor imagen={row.image_provider} />
                          </TableCell>
                          <TableCell align="left">
                            {row.name_provider}
                          </TableCell>
                          <TableCell align="left">{row.dni_provider}</TableCell>
                          <TableCell align="left">
                            <TipoVehiculos
                              tipoVehiculos={row.type_vehicles_provider}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <ActiveDot status={row.status_provider} />
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              aria-label="update"
                              onClick={() => this.handleModal(2, row)}
                            >
                              <CreateIcon color="primary" />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() => this.confirmDelete(row._id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                  )
                ) : (
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    {columns.map((e, i) => (
                      <TableCell key={i}>
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="w-100 mb-4">
            <TablePagination
              rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todas" }]}
              labelRowsPerPage="Filas por página"
              labelDisplayedRows={({ from, to, count, page }) => {
                return from + " - " + to + " de " + count + " proveedores";
              }}
              component="div"
              count={this.state.proveedores ? this.state.proveedores.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </div>

          <div
            className="modal fade"
            id="modalFormulario"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-xl"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <Typography
                    variant="h5"
                    className="text-white"
                    id="editarModalLabel"
                  >
                    {tipoModal === 1
                      ? "Crear Proveedor"
                      : "Modificar Proveedor"}
                  </Typography>

                  <IconButton
                    aria-label="close"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => {
                      $("#modalFormulario").modal("hide");
                      this.emptyForm();
                    }}
                  >
                    <CloseIcon htmlColor="#ffffff" fontSize="large" />
                  </IconButton>
                </div>
                <form onSubmit={this.validarProveedor}>
                  <div className="modal-body">
                    <div className="w-100 mb-3">
                      <label className=" d-flex justify-content-start">
                        <img
                          src={this.state.form.image_provider}
                          width="100"
                          height="100"
                          alt="Proveedor"
                          className="icon-table"
                        />
                        <span>
                          <input
                            type="file"
                            id="image_provider"
                            accept="image/*"
                            name="image_provider"
                            onChange={this.handleImage}
                          />
                        </span>
                      </label>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Código</label>
                        {tipoModal === 1 ? (
                          <label className="texto-form-red-light"> *</label>
                        ) : (
                          false
                        )}
                        <input
                          disabled={tipoModal === 1 ? false : true}
                          required={tipoModal === 1 ? true : false}
                          onChange={this.handleChange}
                          type="text"
                          name="code_provider"
                          className="form-control"
                          placeholder="Código"
                          value={this.state.form.code_provider}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">DNI</label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          name="dni_provider"
                          className="form-control"
                          placeholder="DNI"
                          maxLength={13}
                          value={this.state.form.dni_provider}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">
                          Nombre proveedor
                        </label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          name="name_provider"
                          className="form-control"
                          placeholder="Nombre"
                          value={this.state.form.name_provider}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Nacionalidad</label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          name="nationality_provider"
                          className="form-control"
                          placeholder="Nacionalidad"
                          value={this.state.form.nationality_provider}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">N° licencia</label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          name="number_license_provider"
                          className="form-control"
                          placeholder="N° licencia"
                          value={this.state.form.number_license_provider}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Estado</label>
                        <label className="texto-form-red-light"> *</label>
                        <div className="form-row">
                          <div className="form-group mb-0 col-md-6">
                            <Button
                              type="button"
                              onClick={() => this.handleChangeButton(1)}
                              variant="contained"
                              color={
                                parseInt(this.state.form.status_provider) === 1
                                  ? "primary"
                                  : "default"
                              }
                              className="w-100 text-roboto-regular"
                            >
                              <div className="row m-0 w-100">
                                <div className="col-sm-3 px-0 d-flex justify-content-center align-items-center">
                                  <div className="dot activo"></div>
                                </div>
                                <div className="col-sm-9 px-0">Activo</div>
                              </div>
                            </Button>
                          </div>
                          <div className="form-group mb-0 col-md-6">
                            <Button
                              type="button"
                              onClick={() => this.handleChangeButton(2)}
                              variant="contained"
                              color={
                                parseInt(this.state.form.status_provider) === 2
                                  ? "primary"
                                  : "default"
                              }
                              className="w-100 text-roboto-regular"
                            >
                              <div className="row m-0 w-100">
                                <div className="col-sm-3 px-0 d-flex justify-content-center align-items-center">
                                  <div className="dot inactivo"></div>
                                </div>
                                <div className="col-sm-9 px-0">Inactivo</div>
                              </div>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Teléfono</label>
                        <label className="texto-form-red-light"> *</label>
                        <PhoneInput
                          preferredCountries={["cl", "mx"]}
                          country={"cl"}
                          inputProps={{
                            name: "phone_provider",
                            required: true,
                            type: "text",
                            minLength: 7,
                          }}
                          className="form-control"
                          placeholder="989828918"
                          name="phone_provider"
                          value={this.state.form.phone_provider}
                          onChange={this.handlePhone}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">
                          Correo electrónico
                        </label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          // disabled={tipoModal === 1 ? false : true}
                          onChange={this.handleChange}
                          type="text"
                          name="email_provider"
                          className="form-control"
                          placeholder="proveedor@gmail.com"
                          value={this.state.form.email_provider}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1 w-100">
                          Vehículo
                        </label>
                        <Vehiculos
                          vehiculos={this.state.form.type_vehicles_provider}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">
                          Pago Proveedor PickUp
                        </label>
                        <input
                          required
                          onChange={this.handleChangeJustNumbers}
                          type="text"
                          name="EPA_pickup_price"
                          className="form-control"
                          placeholder="0"
                          value={this.state.form.EPA_pickup_price}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">
                          Pago Proveedor Delivery
                        </label>
                        <input
                          required
                          onChange={this.handleChangeJustNumbers}
                          type="text"
                          name="EPA_delivery_price"
                          className="form-control"
                          placeholder="0"
                          value={this.state.form.EPA_delivery_price}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">
                          Pago Proveedor Cita
                        </label>
                        <input
                          required
                          onChange={this.handleChangeJustNumbers}
                          type="text"
                          name="EPA_appointment_price"
                          className="form-control"
                          placeholder="0"
                          value={this.state.form.EPA_appointment_price}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">
                          Pago Rayo PickUp
                        </label>
                        <input
                          required
                          onChange={this.handleChangeJustNumbers}
                          type="text"
                          name="Rayo_pickup_price"
                          className="form-control"
                          placeholder="0"
                          value={this.state.form.Rayo_pickup_price}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">
                          Pago Rayo Delivery
                        </label>
                        <input
                          required
                          onChange={this.handleChangeJustNumbers}
                          type="text"
                          name="Rayo_delivery_price"
                          className="form-control"
                          placeholder="0"
                          value={this.state.form.Rayo_delivery_price}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">
                          Pago Rayo Cita
                        </label>
                        <input
                          required
                          onChange={this.handleChangeJustNumbers}
                          type="text"
                          name="Rayo_appointment_price"
                          className="form-control"
                          placeholder="0"
                          value={this.state.form.Rayo_appointment_price}
                        />
                      </div>
                        {/*
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Tenant</label>
                        <input
                          required={false}
                          disabled={tipoModal === 1 ? false : true}
                          onChange={this.handleChange}
                          type="text"
                          name="tenant"
                          className="form-control"
                          placeholder="Tenant"
                          value={this.state.form.tenant}
                        />
                      </div> */}
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">ID Partners</label>
                        <input
                          required={false}
                          onChange={this.handleChange}
                          type="text"
                          name="id_partners"
                          className="form-control"
                          placeholder="Id Partners"
                          value={this.state.form.id_partners}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Emails</label>
                        <input
                          required={false}
                          onChange={this.handleChange}
                          type="text"
                          name="emails"
                          className="form-control"
                          placeholder="Emails"
                          value={this.state.form.emails}
                          onKeyDown={this.keyPress}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <Button color="secondary" variant="contained" type="submit">
                      {tipoModal === 1 ? "Crear" : "Modificar"}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Container>
      </Box>
    );
  }

  validar = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  validarProveedor = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.validar(event);
    } else {
      this.validar(event);
      if (this.state.tipoModal === 1) {
        this.createProveedor();
      } else {
        this.modifyProveedor();
      }
    }
  };
}

const styles = (theme) => ({
  btnTipoVehiculos: {
    marginRight: "0.4rem !important",
    backgroundColor: "#E0E0E0 !important",
  },
  btnTipoVehiculosActive: {
    marginRight: "0.4rem !important",
    backgroundColor: theme.palette.primary.main + " !important",
  },
});

export default withStyles(styles, { withTheme: true })(Proveedores);
