import {
  OBTENER_GRUPO_TAREAS_FILTRO,
  ERROR_GRUPO_TAREAS,
  RES_ERROR,
  FILTROS,
  MODIFICAR_GT,
  DES_SELECCIONAR_GT,
  GUARDAR_CAMBIO_GT,
  OBTENER_TAREAS,
  ACTUALIZAR_CANTIDAD_TAREAS,
  ERROR_TAREAS,
  MODIFICAR_T,
  DES_SELECCIONAR_T,
  UPDATE_GT,
  CREATE_GT,
  DELETE_GT,
  SELECCIONAR_TODAS_T,
  INSERT_TASK_IN_GT,
  REINICIAR_ESTADOS,
  CREATE_T,
  DELETE_T,
  UPDATE_T,
  ELIMINAR_LOCALIZACIONES,
  OBTENER_TAREAS_CLIENTE,
} from "../actions/GrupoTareas";

const estadoInicial = {
  grupoTareas0: null,
  grupoTareasNombre0: "No Asignados",
  localidades0: null,
  grupoTareas1: null,
  grupoTareasNombre1: "Asignados",
  localidades1: null,
  error: null,
  seleccionado: false,
  seleccionDesdeMapa: false,
  totalSeleccionado: 0,
  wayPoints: [],

  filtros: null,

  tareasRecepcion: null,
  tareas: null,
  seleccionadoT: false,
  paginador: 0,
  countTasks: 0,
};

const INITIAL_STATE = {
  loggedIn: true,
};

const grupoTareasReducer = (estado = estadoInicial, accion) => {
  switch (accion.type) {
    case REINICIAR_ESTADOS:
      return {
        ...estado,
        grupoTareas0: accion.grupoTareas0,
        grupoTareas1: accion.grupoTareas1,
        localidades0: accion.localidades0,
        localidades1: accion.localidades1,
      };
    case ELIMINAR_LOCALIZACIONES:
      return {
        ...estado,
        localidades0: [],
        localidades1: [],
      };
    case OBTENER_GRUPO_TAREAS_FILTRO:
      return {
        ...estado,
        ["grupoTareas" + accion.panel]: accion.grupoTareas,
        ["localidades" + accion.panel]: accion.localidades,
        ["grupoTareasNombre" + accion.panel]: accion.estado,
        seleccionado: false,
        totalSeleccionado: 0,
        filtros: accion.filtros,
      };
    case GUARDAR_CAMBIO_GT:
      return {
        ...estado,
        ["grupoTareas" + accion.panel]: accion.grupoTareas,
        ["localidades" + accion.panel]: accion.localidades,
        ["grupoTareasNombre" + accion.panel]: accion.estado,
      };
    case UPDATE_GT:
      return {
        ...estado,
        ["grupoTareas" + accion.panel]: accion.grupoTareas,
        ["localidades" + accion.panel]: accion.localidades,
      };
    case CREATE_GT:
      return {
        ...estado,
        ["grupoTareas" + accion.panel]: accion.grupoTareas,
        ["localidades" + accion.panel]: accion.localidades,
      };
    case DELETE_GT:
      return {
        ...estado,
        ["grupoTareas" + accion.panel]: accion.grupoTareas,
        ["localidades" + accion.panel]: accion.localidades,
      };
    case INSERT_TASK_IN_GT:
      return {
        ...estado,
        ["grupoTareas" + accion.panel]: accion.grupoTareas,
        ["localidades" + accion.panel]: accion.localidades,
      };
    case MODIFICAR_GT:
      return {
        ...estado,
        ["grupoTareas" + accion.panel]: accion.grupoTareas,
        seleccionado: accion.seleccionado,
        totalSeleccionado: accion.totalSeleccionado,
        wayPoints: accion.wayPoints,
        seleccionDesdeMapa: accion.seleccionDesdeMapa,
      };
    case DES_SELECCIONAR_GT:
      return {
        ...estado,
        ["grupoTareas" + accion.panel]: accion.grupoTareas,
        seleccionado: accion.seleccionado,
        totalSeleccionado: 0,
        wayPoints: accion.wayPoints,
        seleccionDesdeMapa: accion.seleccionDesdeMapa,
      };
    case ERROR_GRUPO_TAREAS:
      return {
        ...estado,
        error: accion.error,
      };

    case OBTENER_TAREAS:
      return {
        ...estado,
        tareas: accion.tareas,
        filtros: accion.filtros,
        seleccionadoT: accion.seleccionadoT,
        paginador: accion.paginador,
        countTasks: accion.countTasks,
      };
    case ACTUALIZAR_CANTIDAD_TAREAS:
      return {
        ...estado,
        countTasks: accion.countTasks,
      };
    case CREATE_T:
      return {
        ...estado,
        tareas: accion.tareas,
      };
    case OBTENER_TAREAS_CLIENTE:
      return {
        ...estado,
        tareasRecepcion: accion.tareas,
      };
    case DELETE_T:
      return {
        ...estado,
        tareas: accion.tareas,
      };
    case UPDATE_T:
      return {
        ...estado,
        tareas: accion.tareas,
      };
    case MODIFICAR_T:
      return {
        ...estado,
        tareas: accion.tareas,
        seleccionadoT: accion.seleccionadoT,
      };
    case DES_SELECCIONAR_T:
      return {
        ...estado,
        tareas: accion.tareas,
        seleccionadoT: accion.seleccionadoT,
      };
    case SELECCIONAR_TODAS_T:
      return {
        ...estado,
        tareas: accion.tareas,
        seleccionadoT: accion.seleccionadoT,
      };
    case ERROR_TAREAS:
      return {
        ...estado,
        error: accion.error,
      };
    case FILTROS:
      return { ...estado, filtros: accion.filtros };
    case RES_ERROR:
      return INITIAL_STATE;
    default:
      return estado;
  }
};

export default grupoTareasReducer;
