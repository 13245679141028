import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

const TextFieldOutlined = withStyles((theme) => ({
  root: {
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    borderRadius: 4
  },
}))(TextField);

export default TextFieldOutlined;
