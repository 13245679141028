import React from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { OBSERVER } from '../assets/utils/Helpers';

const PrivateRouteDispatcher = ({ component: Component, ...res }) => {
    let usuario = JSON.parse(sessionStorage.getItem('user'));

    return (
        <Route
            {...res}
            render={(props) =>
                usuario === 'null' || !usuario || usuario.type === OBSERVER ? (
                    <Redirect to={{ pathname: '/Login', state: { from: props.location } }} />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

export default withRouter(PrivateRouteDispatcher);
