import React from "react";
import Swal from "sweetalert2";
import axios from "axios";
import $ from "jquery";
import cloneDeep from "lodash/cloneDeep";
import "../../css/global.css";
import Utils from "../../assets/utils/Utils";
import url from "../../config";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import Switch from "@material-ui/core/Switch";

import ActiveDot from "../../assets/components/mantenedores/ActiveDot";
import TextFieldOutlined from "../../assets/components/custom/TextFieldOutlined";
import IconoDocumentoBlack from "../../assets/icons/icono-document-upload-black.svg";

import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import Skeleton from "@material-ui/lab/Skeleton";

const columns = [
  { id: "image_type_activity", label: "Actividades", align: "left" },
  { id: "status_type_activity", label: "Estado", align: "left" },
  { id: "accion", label: "Acción", align: "right" },
];

const ImageText = ({ image, text }) => {
  return (
    <Box display="inline-flex" paddingTop="4px">
      <Box height={20} display="flex" alignItems="center" marginRight={2}>
        <img src={image} height="20" width="20" alt="icono tipo de actividad" />
      </Box>
      <Box height={20} display="flex" alignItems="center">
        {text}
      </Box>
    </Box>
  );
};

class Tipo_Actividades extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,

      tipoModal: 1,
      tipoActividades: null,
      dropdown_text: "",
      search: "",

      form: {
        key: "",
        image_type_activity: IconoDocumentoBlack,
        name_type_activity: "",
        status_type_activity: "1",
        tipoActividades: "",
        dropdown_activated: false,
        dropdown_options: [],
      },
    };
  }

  componentDidMount = () => {
    this.getTipoActividades();
  };

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  handleChangeSwitch = (e) => {
    e.preventDefault();
    const { name, checked } = e.target;
    this.setState({
      form: {
        ...this.state.form,
        [name]: checked,
      },
    });
  };

  handleChangeButton = (value) => {
    this.setState({
      form: {
        ...this.state.form,
        status_type_activity: value,
      },
    });
  };

  handleDeleteDropdown = (i) => {
    let dropdown = cloneDeep(this.state.form.dropdown_options);
    dropdown.splice(i, 1);
    this.setState({
      form: {
        ...this.state.form,
        dropdown_options: dropdown,
      },
    });
  };

  handleAddDropdown = () => {
    let dropdown = cloneDeep(this.state.form.dropdown_options);
    dropdown.push(this.state.dropdown_text);
    this.setState({
      dropdown_text: "",
      form: {
        ...this.state.form,
        dropdown_options: dropdown,
      },
    });
  };

  handleImage = (e) => {
    try {
      e.preventDefault();
      const estado = this;
      const { name } = e.target;
      const reader = new FileReader();

      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function () {
        estado.setState({
          form: {
            ...estado.state.form,
            [name]: reader.result,
          },
        });
      };
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error al subir la imagen, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0,
    });
  };

  handleModal = async (tipo, actividad) => {
    this.setState({
      tipoModal: tipo,
    });
    if (tipo === 1) {
      $("#modalFormulario").modal("show");
    } else {
      await this.setState({
        form: {
          ...this.state.form,
          key: actividad._id,
          image_type_activity: actividad.image_type_activity,
          name_type_activity: actividad.name_type_activity,
          status_type_activity: actividad.status_type_activity,
          dropdown_activated: actividad.dropdown_activated,
          dropdown_options: actividad.dropdown_options,
        },
      });
      $("#modalFormulario").modal("show");
    }
  };

  emptyForm = () => {
    this.setState({
      dropdown_text: "",
      form: {
        key: "",
        image_type_activity: IconoDocumentoBlack,
        name_type_activity: "",
        status_type_activity: "1",
        tipoActividades: "",
        dropdown_activated: false,
        dropdown_options: [],
      },
    });
  };

  getTipoActividades = async () => {
    try {
      let usuario = JSON.parse(sessionStorage.getItem("user"));
      let data = await axios({
        method: "get",
        url: url.host + "/Type-Activities/type_activities/",
        headers: { tenant: usuario.tenant },
      }).then(({ data }) => data);
      this.setState({ tipoActividades: data });
    } catch (err) {
      Utils.handleAlert(
        "Obtener tipos de actividades",
        "No se han podido cargar los tipos de actividades",
        "Ok"
      );
    }
  };

  createActividad = async () => {
    try {
      // let dropdownData = this.state.form.dropdown_options;
      // if (!this.state.form.dropdown_activated) {
      //     dropdownData = [];
      // }
      let usuario = JSON.parse(sessionStorage.getItem("user"));
      let res = await url.api.post("/Type-Activities/type_activity/", {
        image_type_activity: this.state.form.image_type_activity,
        name_type_activity: this.state.form.name_type_activity,
        status_type_activity: 1,
        dropdown_activated: this.state.form.dropdown_activated,
        tenant: this.state.form.tenant
        // dropdown_options: dropdownData,
      }, {
        headers: { tenant: usuario.tenant }
      });
      if (res.status === 201 || res.status === 200) {
        $("#modalFormulario").modal("hide");
        this.getTipoActividades();
        Utils.handleAlert(
          "Tipo de actividad ingresada",
          "El tipo de actividad se ha ingresado exitosamente",
          "Ok"
        );
        this.emptyForm();
      } else {
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al ingresar el tipo de actividad, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al ingresar el tipo de actividad, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  confirmDelete = (key) => {
    Swal.fire({
      title: "¿Eliminar Tipo de Actividad?",
      text: "Si confirma, no podrá deshacer la acción y el tipo de actividad será eliminado de forma permanente",
      imageUrl:
        "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayoapp-red.svg",
      showCancelButton: true,
      customClass: {
        image: "imageSweetAlert",
        title: "titleSweetAlert",
        content: "textSweetAlert",
        confirmButton: "buttonSweetAlert",
        cancelButton: "btn-secondary",
      },
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.deleteActividad(key);
      }
    });
  };

  deleteActividad = async (key) => {
    try {
      const tipoActividad = {
        key: key,
        tenant: this.state.form.tenant
      };
      let usuario = JSON.parse(sessionStorage.getItem("user"));
      let res = await url.api.delete("/Type-Activities/type_activity/", {
        data: tipoActividad,
        headers: { Authorization: "***", tenant: usuario.tenant },
      });
      if (res.status === 200) {
        this.getTipoActividades();
        Utils.handleAlert(
          "Tipo de actividad eliminada",
          "El tipo de actividad se ha eliminado exitosamente",
          "Ok"
        );
      } else {
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al eliminar el tipo de actividad, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al eliminar el tipo de actividad, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  modifyActividad = async () => {
    try {
      // let dropdownData = this.state.form.dropdown_options;
      // if (!this.state.form.dropdown_activated) {
      //     dropdownData = [];
      // }
      let usuario = JSON.parse(sessionStorage.getItem("user"));
      let res = await url.api.put("/Type-Activities/type_activity/", {
        key: this.state.form.key,
        image_type_activity: this.state.form.image_type_activity,
        name_type_activity: this.state.form.name_type_activity,
        status_type_activity: this.state.form.status_type_activity,
        dropdown_activated: false,
        tenant: this.state.form.tenant,
        // dropdown_options: dropdownData,
      }, {
        headers: { tenant: usuario.tenant }
      });
      if (res.status === 200) {
        this.getTipoActividades();
        $("#modalFormulario").modal("hide");
        Utils.handleAlert(
          "Tipo de actividad modificado",
          "El tipo de actividad se ha modificado exitosamente",
          "Ok"
        );
        this.emptyForm();
      } else {
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al modificar el tipo de actividad, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al modificar el tipo de actividad, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  render() {
    const { page, rowsPerPage, tipoModal } = this.state;

    return (
      <Box pt={4}>
        <Container>
          <Typography variant="h1" gutterBottom>
            Tipo de actividades
          </Typography>

          <Box width="100%" display="flex" mb={3}>
            <Box flexGrow="1">
              <TextFieldOutlined
                size="small"
                name="search"
                value={this.state.search}
                onChange={(e) => {
                  if (this.state.page !== 0) this.setState({ page: 0 });
                  this.setState({ search: e.target.value });
                }}
                variant="outlined"
                placeholder="Buscar..."
                style={{ minWidth: 250 }}
              />
            </Box>
            <Button
              color="secondary"
              variant="contained"
              data-toggle="modal"
              data-target="#crearModal"
              onClick={() => this.handleModal(1)}
            >
              Crear nuevo
            </Button>
          </Box>

          <TableContainer className="table-white">
            <Table aria-label="Type activities">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell align={column.align} key={index}>
                      <b>{column.label}</b>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.tipoActividades ? (
                  this.state.tipoActividades.length === 0 ? (
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell colSpan={columns.length} align="center">
                        <em>- No existen tipos de actividades creados -</em>
                      </TableCell>
                    </TableRow>
                  ) : (
                    this.state.tipoActividades
                      .filter((activity) => {
                        let bandera = false;
                        if (
                          activity.name_type_activity
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase()) ||
                          activity._id
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase())
                        ) {
                          bandera = true;
                        }
                        return bandera;
                      })
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, i) => (
                        <TableRow hover key={i}>
                          <TableCell align="left">
                            <ImageText
                              image={row.image_type_activity}
                              text={row.name_type_activity}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <ActiveDot status={row.status_type_activity} />
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              aria-label="update"
                              onClick={() => this.handleModal(2, row)}
                            >
                              <CreateIcon color="primary" />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() => this.confirmDelete(row._id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                  )
                ) : (
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    {columns.map((e, i) => (
                      <TableCell key={i}>
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="w-100 mb-4">
            <TablePagination
              rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todas" }]}
              labelRowsPerPage="Filas por página"
              labelDisplayedRows={({ from, to, count, page }) => {
                return (
                  from + " - " + to + " de " + count + " tipos de actividades"
                );
              }}
              component="div"
              count={
                this.state.tipoActividades
                  ? this.state.tipoActividades.filter((activity) => {
                      let bandera = false;
                      if (
                        activity.name_type_activity
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase()) ||
                        activity._id
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase())
                      ) {
                        bandera = true;
                      }
                      return bandera;
                    }).length
                  : 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </div>

          <div
            className="modal fade"
            id="modalFormulario"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            data-backdrop="static"
            aria-hidden="true"
            scrollable="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <Typography
                    variant="h5"
                    className="text-white"
                    id="editarModalLabel"
                  >
                    {tipoModal === 1
                      ? "Crear tipo de actividad"
                      : "Modificar tipo de actividad"}
                  </Typography>

                  <IconButton
                    aria-label="close"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => {
                      $("#modalFormulario").modal("hide");
                      this.emptyForm();
                    }}
                  >
                    <CloseIcon htmlColor="#ffffff" fontSize="large" />
                  </IconButton>
                </div>
                <form onSubmit={this.validarTipoActividad}>
                  <div className="modal-body">
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="texto-form">Icono actividad</label>
                        <label className="texto-form-red-light">*</label>
                        <label className="filebutton d-flex justify-content-center back-grey">
                          <img
                            src={this.state.form.image_type_activity}
                            width="25"
                            alt="Icono actividad"
                          />
                          <span>
                            <input
                              type="file"
                              id="image_type_activity"
                              name="image_type_activity"
                              accept="image/*"
                              onChange={this.handleImage}
                            />
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="texto-form">Nombre</label>
                      <label className="texto-form-red-light">*</label>
                      <input
                        required
                        onChange={this.handleChange}
                        type="text"
                        name="name_type_activity"
                        className="form-control"
                        placeholder="Nombre actividad"
                        value={this.state.form.name_type_activity}
                      />
                    </div>
                    <div className="form-group">
                      <label className="texto-form">Estado</label>
                      <label className="texto-form-red-light">*</label>
                      <div className="form-row">
                        <div className="form-group mb-0 col-md-6">
                          <Button
                            type="button"
                            onClick={() => this.handleChangeButton(1)}
                            variant="contained"
                            color={
                              parseInt(this.state.form.status_type_activity) ===
                              1
                                ? "primary"
                                : "default"
                            }
                            className="w-100 text-roboto-regular"
                          >
                            <div className="row m-0 w-100">
                              <div className="col-sm-3 px-0 d-flex justify-content-center align-items-center">
                                <div className="dot activo"></div>
                              </div>
                              <div className="col-sm-9 px-0">Activo</div>
                            </div>
                          </Button>
                        </div>
                        <div className="form-group mb-0 col-md-6">
                          <Button
                            type="button"
                            onClick={() => this.handleChangeButton(2)}
                            variant="contained"
                            color={
                              parseInt(this.state.form.status_type_activity) ===
                              2
                                ? "primary"
                                : "default"
                            }
                            className="w-100 text-roboto-regular"
                          >
                            <div className="row m-0 w-100">
                              <div className="col-sm-3 px-0 d-flex justify-content-center align-items-center">
                                <div className="dot inactivo"></div>
                              </div>
                              <div className="col-sm-9 px-0">Inactivo</div>
                            </div>
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.form.dropdown_activated}
                            onChange={this.handleChangeSwitch}
                            name="dropdown_activated"
                            className="switch-white"
                          />
                        }
                        className="text-white mb-0"
                        label="Tipo Dropdown"
                      />
                    </div>
                    <div className="form-group ">
                        <label className="texto-form mb-1">Tenant</label>
                        <input
                          required={false}
                          disabled={tipoModal === 1 ? false : true}
                          onChange={this.handleChange}
                          type="text"
                          name="tenant"
                          className="form-control"
                          placeholder="Tenant"
                          value={this.state.form.tenant}
                        />
                      </div>
                  </div>
                  <div className="modal-footer">
                    <Button color="secondary" variant="contained" type="submit">
                      {tipoModal === 1 ? "Crear" : "Modificar"}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Container>
      </Box>
    );
  }

  validar = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  validarTipoActividad = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.validar(event);
    } else {
      this.validar(event);
      if (this.state.tipoModal === 1) {
        this.createActividad();
      } else {
        this.modifyActividad();
      }
    }
  };
}

export default Tipo_Actividades;
