import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import "react-phone-input-2/lib/style.css";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import CopyText from "../../assets/components/custom/CopyText";

import url from "../../config";
import Utils from "../../assets/utils/Utils";
import Cargando from "../../assets/components/cargando/Cargando";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TablePagination from "@material-ui/core/TablePagination";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import IconButton from "@material-ui/core/IconButton";

import { AMBIENTE } from "./../../assets/utils/Helpers";

import TextFieldOutlined from "../../assets/components/custom/TextFieldOutlined";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import Skeleton from "@material-ui/lab/Skeleton";

const IconoUsuario =
  "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/genericImage.png";
const columns = [
  { id: "copiar", label: "Copiar ID", align: "left" },
  { id: "code", label: "Código", align: "left" },
  { id: "name", label: "Nombre", align: "left" },
  { id: "pickup_price", label: "Precio Pick-up", align: "left" },
  { id: "delivery_price", label: "Precio Delivery", align: "left" },
  { id: "appointment_price", label: "Precio Cita", align: "left" },
  { id: "customer_id", label: "Cliente", align: "left" },
  { id: "accion", label: "Acción", align: "right" },
];

class Servicios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: null,
      page: 0,
      rowsPerPage: 10,

      search: "",
      tipoModal: 1,
      tipoVehiculos: [],
      servicios: null,
      custumers: null,

      form: {
        key: "",
        appointment_price: "",
        code: "",
        customer_id: "",
        delivery_price: "",
        name: "",
        pickup_price: "",
        tasks: "",
        customer_rayo: [],
        
      },
      cargando: false,
    };
  }

  componentDidMount = () => {
    let user = JSON.parse(sessionStorage.getItem("user"));
    this.setState({ usuario: user });
    this.getServicios();
  };

  handleClientes = (i) => {
    let newArray = [...this.state.form.customer_rayo];
    newArray[i] = {
      ...newArray[i],
      activo: !newArray[i].activo,
    };

    this.setState({
      form: {
        ...this.state.form,
        customer_rayo: newArray,
      },
    });
  };

  handleCargando(value) {
    this.setState({ cargando: value });
  }

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let valor = value;
    this.setState({
      form: {
        ...this.state.form,
        [name]: valor,
      },
    });
  };


  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0,
    });
  };

  handleModal = async (tipo, servicio) => {
    this.setState({ tipoModal: tipo });
    if (tipo === 1) {
      $("#modalFormulario").modal("show");
    } else {

      await this.setState({
        form: {
          ...this.state.form,
          key: servicio._id,
          code: servicio.code,
          name: servicio.name,
          pickup_price: servicio.pickup_price,
          delivery_price: servicio.delivery_price,
          appointment_price: servicio.appointment_price,
          customer_id: servicio.customer_id,
        },
      });
      $("#modalFormulario").modal("show");
    }
  };

  emptyForm = () => {
    this.setState({
      form: {
        ...this.state.form,
        key: "",
        code: "",
        name: "",
        pickup_price: "",
        delivery_price: "",
        appointment_price: "",
        customer_rayo: [...this.state.custumers],
        customer_id: "",
      },
    });
  };

  getClientes = async () => {
    try {
      let clientes = Utils.getCustomers();
      let usuario = JSON.parse(sessionStorage.getItem("user"));
      let data = await axios({
        method: "get",
        url: url.host + "/Customers/customers" + clientes,
        headers: { tenant: usuario.tenant },
      }).then(({ data }) => data);
      let customers = data.sort(function (a, b) {
        if (
          a.name_customer.toLowerCase().trim() <
          b.name_customer.toLowerCase().trim()
        )
          return -1;
        if (
          a.name_customer.toLowerCase().trim() >
          b.name_customer.toLowerCase().trim()
        )
          return 1;
        return 0;
      });

      customers.forEach((v) => {
        v.activo = false;
        return v;
      });
      this.setState({
        custumers: customers,
        form: {
          ...this.state.form,
          customer_rayo: customers,
        },
      });
    } catch (err) {
      Utils.handleAlert(
        "Obtener clientes",
        "No se han podido cargar los clientes",
        "Ok"
      );
    }
  };



  getServicios = async () => {
    try {
      let usuario = JSON.parse(sessionStorage.getItem("user"));
      let data = await axios({
        method: "get",
        url: url.host + "/servicios/servicios",
        headers: { tenant: usuario.tenant },
      });
      if (data.status === 200) {
        this.setState({ servicios: data.data });
        this.getClientes();
      } else if (data.status === 204) {
        this.setState({ servicios: [] });
      } else {
        Utils.handleAlert(
          "Obtener servicios",
          "No se han podido cargar los servicios",
          "Ok"
        );
      }
    } catch (err) {
      Utils.handleAlert(
        "Obtener servicios",
        "No se han podido cargar los servicios",
        "Ok"
      );
    }
  };

  createServicio = async () => {
    try {
        const servicio = {
            code: this.state.form.code,
            name: this.state.form.name,
            pickup_price: this.state.form.pickup_price,
            delivery_price: this.state.form.delivery_price,
            appointment_price: this.state.form.appointment_price,
            customer_id: this.state.form.customer_id,        
            tenant: this.state.form.tenant,
        };

        await url.api({
          method: "POST",
          url: url.host + "/servicios/servicios/", servicio,
          //headers: { tenant: usuario.tenant },
        })
        .then((response) => {
            if (response.status === 201 || response.status === 200) {
                $("#modalFormulario").modal("hide");
                this.getServicios();
                this.handleCargando(false);
                Utils.handleAlert(
                "Servicio ingresado",
                "El servicio se ha ingresado exitosamente",
                "Ok"
                );
                this.emptyForm();
            } else {
            this.handleCargando(false);
            Utils.handleAlert(
                "Error " + response.status,
                "Ha ocurrido un error al ingresar el servicio, vuelva a intentarlo",
                "Ok"
            );
            }
        })
        .catch((error) => {
            if (error.response) {
                let title = "Error en acción " + error.response.status;
                if (error.response.status === 412) {
                    title = "Precondición fallida";
                }
                this.handleCargando(false);
                Utils.handleAlert(
                    title,
                    error.response.data.error.detail.error_message,
                    "Ok"
                );
            } 
        });
      
    } catch (error) {
      this.handleCargando(false);
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al ingresar un servicio, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  confirmDelete = (key) => {
    Swal.fire({
      title: "¿Eliminar Servicio?",
      text: "Si confirma, no podrá deshacer la acción y el servicio será eliminado de forma permanente",
      imageUrl:
        "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayoapp-red.svg",
      showCancelButton: true,
      customClass: {
        image: "imageSweetAlert",
        title: "titleSweetAlert",
        content: "textSweetAlert",
        confirmButton: "buttonSweetAlert",
        cancelButton: "btn-secondary",
      },
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.deleteServicio(key);
      }
    });
  };

  deleteServicio = async (key) => {
    await this.handleCargando(true);
    try {
      const servicio = {
        _id: key,
        tenant: this.state.form.tenant
      };
      let res = await url.api.delete("/servicios/servicios/", {
        data: servicio,
        headers: { Authorization: "***" },
      });
      if (res.status === 200) {
        this.getServicios();
        this.handleCargando(false);
        Utils.handleAlert(
          "Servicio eliminado",
          "El servicio se ha eliminado exitosamente",
          "Ok"
        );
      } else {
        this.handleCargando(false);
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al eliminar el servicio, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      this.handleCargando(false);
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al eliminar el servicio, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  modifyServicio = async () => {
    try {
        const servicio = {
          _id: this.state.form.key,
          code: this.state.form.code,
          name: this.state.form.name,
          pickup_price: this.state.form.pickup_price,
          delivery_price: this.state.form.delivery_price,
          appointment_price: this.state.form.appointment_price,
          customer_id: this.state.form.customer_id,
          tenant: this.state.form.tenant,
        };

        await url.api
          .put("/servicios/servicios/", servicio)
          .then((res) => {
            if (res.status === 200) {
              this.getServicios();
              $("#modalFormulario").modal("hide");
              this.handleCargando(false);
              Utils.handleAlert(
                "Servicio modificado",
                "El servicio se ha modificado exitosamente",
                "Ok"
              );
              this.emptyForm();
            } else {
              this.handleCargando(false);
              Utils.handleAlert(
                "Error " + res.status,
                "Ha ocurrido un error al modificar el servicio, vuelva a intentarlo",
                "Ok"
              );
            }
          })
          .catch((error) => {
            if (error.response) {
              let title = "Error en acción " + error.response.status;
              if (error.response.status === 412) {
                title = "Precondición fallida";
              }
              this.handleCargando(false);
              Utils.handleAlert(
                title,
                error.response.data.error.detail.error_message,
                "Ok"
              );
            } else {
              this.handleCargando(false);
              Utils.handleAlert(
                "Error al realizar la peticion",
                error.message,
                "Ok"
              );
            }
          });
    
    } catch (error) {
      this.handleCargando(false);
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al modificar el servicio, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  render() {
    const { page, rowsPerPage, tipoModal } = this.state;
    const { classes } = this.props;

/*     const SeleccionClientes = ({ clientes }) => {
      return clientes.map((client, index) => {
        return (
            <option value={client._id} key={index}>
                {client.name_customer}
            </option>
        );
      });
    }; */

    const SeleccionClientes = ({ clientes }) => {
        return (
            <select
              required
              className="custom-select"
              name="customer_id"
              onChange={this.handleChange}
              value={this.state.form.customer_id}
            >
            <option value="" disabled>
            - Seleccionar Cliente -
            </option>
            {clientes.map((client, index) => (
                 <option value={client._id} key={index}>
                    {client.name_customer}
                </option>
            ))}
        </select>
        );
    };

    const Clientes = ({ clientes }) => {
      if (this.state.custumers === null) {
        return false;
      } else {
        let array = cloneDeep(this.state.custumers);
        array.forEach((v) => {
            if (v._id === clientes) {
                v.activo = true;
            }
        });

        return array.map((client, index) => {
          if (client.activo) {
            return (
              <p className="mb-0" key={index}>
                {"- " + client.name_customer}
              </p>
            );
          } else {
            return "";
          }
        });
      }
    };

    return (
      <Box pt={4}>
        <Container>
          <Typography variant="h1" gutterBottom>
            Servicios
          </Typography>

          <Box width="100%" display="flex" mb={3}>
            <Box flexGrow="1">
              <TextFieldOutlined
                size="small"
                name="search"
                value={this.state.search}
                onChange={(e) => {
                  if (this.state.page !== 0) this.setState({ page: 0 });
                  this.setState({ search: e.target.value });
                }}
                variant="outlined"
                placeholder="Buscar..."
                style={{ minWidth: 250 }}
              />
            </Box>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => this.handleModal(1)}
            >
              Crear nuevo
            </Button>
          </Box>

          <TableContainer className="table-white">
            <Table aria-label="Servicios">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell align={column.align} key={index}>
                      <b>{column.label}</b>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.servicios ? (
                  this.state.servicios.length === 0 ? (
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell colSpan={columns.length} align="center">
                        <em>- No existen servicios creados -</em>
                      </TableCell>
                    </TableRow>
                  ) : (
                    this.state.servicios
                      .filter((servicio) => {
                        let bandera = false;
                        if (
                          servicio.name
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase()) ||
                          servicio.code
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase()) ||
                          servicio._id
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase())
                        ) {
                          bandera = true;
                        }
                        return bandera;
                      })
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, i) => (
                        <TableRow hover key={i}>
                          <TableCell align="left">
                            <CopyText text={row._id} />
                          </TableCell>
                          <TableCell align="left">{row.code}</TableCell>
                          <TableCell align="left">{row.name}</TableCell>
                          <TableCell align="left">{row.pickup_price}</TableCell>
                          <TableCell align="left">{row.delivery_price}</TableCell>
                          <TableCell align="left">{row.appointment_price}</TableCell>
                          <TableCell align="left">
                            <Clientes clientes={row.customer_id} />
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              aria-label="update"
                              onClick={() => this.handleModal(2, row)}
                            >
                              <CreateIcon color="primary" />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() => this.confirmDelete(row._id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                          </TableRow>
                      ))
                  )
                ) : (
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    {columns.map((e, i) => (
                      <TableCell key={i}>
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="w-100 mb-4">
            <TablePagination
              rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todas" }]}
              labelRowsPerPage="Filas por página"
              labelDisplayedRows={({ from, to, count, page }) => {
                return from + " - " + to + " de " + count + " servicios";
              }}
              component="div"
              count={
                this.state.servicios
                  ? this.state.servicios.filter((servicio) => {
                      let bandera = false;
                      if (
                        servicio.name
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase()) ||
                        servicio.code
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase()) ||
                        servicio._id
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase()) ||
                        servicio.customer_id
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase())
                        
                      ) {
                        bandera = true;
                      }
                      return bandera;
                    }).length
                  : 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </div>

          <div
            className="modal fade"
            id="modalFormulario"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            data-backdrop="static"
            aria-hidden="true"
            scrollable="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-xl"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <Typography
                    variant="h5"
                    className="text-white"
                    id="editarModalLabel"
                  >
                    {tipoModal === 1 ? "Crear Servicio" : "Modificar Servicio"}
                  </Typography>

                  <IconButton
                    aria-label="close"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => {
                      $("#modalFormulario").modal("hide");
                      this.emptyForm();
                    }}
                  >
                    <CloseIcon htmlColor="#ffffff" fontSize="large" />
                  </IconButton>
                </div>
                <form onSubmit={this.validarServicio}>
                  <div className="modal-body">
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">ID</label>
                        <input
                          disabled={true}
                          required={false}
                          type="text"
                          className="form-control"
                          placeholder="ID"
                          value={this.state.form.key}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Código</label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          name="code"
                          className="form-control"
                          placeholder="Código"
                          value={this.state.form.code}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Nombre Servicio</label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Nombre Servicio"
                          value={this.state.form.name}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Precio Pick-Up</label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          name="pickup_price"
                          className="form-control"
                          placeholder="Precio Pick-Up"
                          value={this.state.form.pickup_price}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Precio Delivery</label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          name="delivery_price"
                          className="form-control"
                          placeholder="Precio Delivery"
                          value={this.state.form.delivery_price}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">
                          Precio Cita
                        </label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          name="appointment_price"
                          className="form-control"
                          placeholder="Precio Cita"
                          value={this.state.form.appointment_price}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-4 mb-0">
                        <label className="texto-form mb-1 w-100">
                          Cliente
                          <label className="texto-form-red-light">*</label>
                        </label>

                        <SeleccionClientes
                          clientes={this.state.form.customer_rayo}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1 w-100">Tenant</label>
                        <input
                          required={false}
                          disabled={tipoModal === 1 ? false : true}
                          onChange={this.handleChange}
                          type="text"
                          name="tenant"
                          className="form-control"
                          placeholder="Tenant"
                          value={this.state.form.tenant}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <Button color="secondary" variant="contained" type="submit">
                      {tipoModal === 1 ? "Crear" : "Modificar"}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>


          <Cargando cargando={this.state.cargando} />
        </Container>
      </Box>
    );
  }

  validar = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  validarServicio = (event) => {
    this.handleCargando(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.validar(event);
      this.handleCargando(false);
    } else {
      this.validar(event);
      if (this.state.tipoModal === 1) {
        this.createServicio();
      } else {
        this.modifyServicio();
      }
    }
  };
}

const styles = (theme) => ({
  btnTipoVehiculos: {
    marginRight: "0.4rem !important",
    backgroundColor: "#E0E0E0 !important",
  },
  btnTipoVehiculosActive: {
    marginRight: "0.4rem !important",
    backgroundColor: theme.palette.primary.main + " !important",
  },
});

const mapStateToProps = (store) => {
  return {
    comunas: store.comunas,
    proveedores: store.proveedores,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(Servicios)
);
