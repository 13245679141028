import * as grupoTareasAccion from "../../store/actions/GrupoTareas";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import Utils from "./Utils";

class Watchs {
  // ---- BSON TO STRING TASK GROUP KEYS -----
  static taskGroup_Keys = (document) => {
    let taskGroup = cloneDeep(document);
    taskGroup.id = Utils.getBSONtoString(document._id);
    taskGroup._id = Utils.getBSONtoString(document._id);
    taskGroup.idstatus_taskgroup = Utils.getBSONtoString(
      document.idstatus_taskgroup
    );
    if (document.idrayo_taskgroup == null) {
      taskGroup.idrayo_taskgroup = null;
    } else {
      taskGroup.idrayo_taskgroup = Utils.getBSONtoString(
        document.idrayo_taskgroup
      );
    }
    taskGroup.idcustomer_taskgroup = Utils.getBSONtoString(
      document.idcustomer_taskgroup
    );
    taskGroup.start_date_taskgroup =
      moment(taskGroup.start_date_taskgroup)
        .add(3, "hour")
        .format("YYYY-MM-DDTHH:MM:SS") + ".000Z";

    return taskGroup;
  };

  // ---- BSON TO STRING TASK KEYS -----
  static task_Keys = (document) => {
    try {
      let task = cloneDeep(document);
      task.id = Utils.getBSONtoString(document._id);
      task._id = Utils.getBSONtoString(document._id);
      task.idTasksGroup_task = Utils.getBSONtoString(
        document.idTasksGroup_task
      );
      task.template_failed_task = {
        idTemplate: Utils.getBSONtoString(
          document.template_failed_task.idTemplate
        ),
      };
      task.template_successful_task = {
        idTemplate: Utils.getBSONtoString(
          document.template_successful_task.idTemplate
        ),
      };
      task.idstatus_task = Utils.getBSONtoString(document.idstatus_task);
      if (document.idrayo_task == null) {
        task.idrayo_task = null;
      } else {
        task.idrayo_task = Utils.getBSONtoString(document.idrayo_task);
      }
      task.idcustomer_task = Utils.getBSONtoString(document.idcustomer_task);
      task.date_task =
        moment(task.date_task).utcOffset("+00").format("YYYY-MM-DDTHH:mm:ss") +
        ".000Z";

      return task;
    } catch (error) {
      console.log(error);
    }
  };

  // ---- WATCHS TASK GROUPS -----
  static taskgroup_created = async (
    taskGroup,
    dispatch,
    taskGroups,
    customers
  ) => {
    try {
      const taskGroups0 = taskGroups.grupoTareas0;
      const taskGroups1 = taskGroups.grupoTareas1;
      const filtrosGlobales = JSON.parse(sessionStorage.getItem("filtros"));

      let iR = filtrosGlobales[1].findIndex(
        (element) => element.filter === "idRayo"
      );
      let encontrado = Utils.findCustommer(filtrosGlobales, taskGroup);
      let rayoComparar = taskGroup.idrayo_taskgroup;

      if (filtrosGlobales[1][iR].data === null) rayoComparar = null;
      if (encontrado && filtrosGlobales[1][iR].data === rayoComparar) {
        if (taskGroups0 != null || taskGroups1 != null) {
          taskGroup.customer = [
            { _id: taskGroup.idcustomer_taskgroup, name_customer: "" },
          ];

          if (customers) {
            const i = customers.findIndex(
              (element) => element._id === taskGroup.idcustomer_taskgroup
            );
            if (i >= 0) {
              taskGroup.customer = [
                {
                  _id: taskGroup.idcustomer_taskgroup,
                  name_customer: customers[i].name_customer,
                },
              ];
            }
          }

          let createGT = grupoTareasAccion.createGT(
            taskGroups0,
            taskGroups1,
            taskGroup
          );
          dispatch(createGT);
        }
      }
    } catch (error) {
      // Utils.handleAlert(
      //     'Actualizar data',
      //     'Se ha realizado un cambio, pero ha ocurrido un error al momento de mostrarlo, recargue la página para visualizarlo',
      //     'Ok'
      // );
    }
  };

  static taskgroup_updated = async (
    taskGroup,
    dispatch,
    taskGroups,
    customers
  ) => {
    try {
      const taskGroups0 = taskGroups.grupoTareas0;
      const taskGroups1 = taskGroups.grupoTareas1;
      const filtrosGlobales = JSON.parse(sessionStorage.getItem("filtros"));

      let iR = filtrosGlobales[1].findIndex(
        (element) => element.filter === "idRayo"
      );
      let encontrado = Utils.findCustommer(filtrosGlobales, taskGroup);
      let rayoComparar = taskGroup.idrayo_taskgroup;
      if (filtrosGlobales[1][iR].data === null) {
        rayoComparar = null;
      }

      if (encontrado && filtrosGlobales[1][iR].data === rayoComparar) {
        if (taskGroups0 != null || taskGroups1 != null) {
          let estadoGrupo = taskGroup.idstatus_taskgroup;
          const i0 = taskGroups0.findIndex(
            (element) => element._id === taskGroup.id
          );
          const i1 = taskGroups1.findIndex(
            (element) => element._id === taskGroup.id
          );
          let tasks = [];
          if (i0 >= 0) {
            let estado0 = filtrosGlobales[0][0].data;
            if (estado0) {
              let entraArray = false;
              estado0
                .toString()
                .split(",")
                .forEach((element) => {
                  if (element === estadoGrupo || estado0 === null)
                    entraArray = true;
                });

              tasks = taskGroups0[i0].tasks;
              if (entraArray === false) {
                let deleteGT = grupoTareasAccion.deleteGT(taskGroups0, 0, i0);
                await dispatch(deleteGT);
              }
            }
          }

          if (i1 >= 0) {
            let estado1 = filtrosGlobales[0][1].data;
            if (estado1) {
              let entraArray1 = false;
              estado1
                .toString()
                .split(",")
                .forEach((element) => {
                  if (element === estadoGrupo || estado1 === null)
                    entraArray1 = true;
                });

              tasks = taskGroups1[i1].tasks;
              if (entraArray1 === false) {
                let deleteGT = grupoTareasAccion.deleteGT(taskGroups1, 1, i1);
                await dispatch(deleteGT);
              }
            }
          }

          taskGroup.tasks = tasks;
          let updateGT = grupoTareasAccion.updateGT(
            taskGroups0,
            taskGroups1,
            customers,
            taskGroup
          );
          dispatch(updateGT);
        }
      }
    } catch (error) {
      // Utils.handleAlert(
      //     'Actualizar data',
      //     'Se ha realizado un cambio, pero ha ocurrido un error al momento de mostrarlo, recargue la página para visualizarlo',
      //     'Ok'
      // );
    }
  };

  static taskgroup_deleted = async (result, dispatch, taskGroups) => {
    try {
      const taskGroups0 = taskGroups.grupoTareas0;
      const taskGroups1 = taskGroups.grupoTareas1;

      let taskGroup = {};
      taskGroup.id = Utils.getBSONtoString(result.value.documentKey._id);
      taskGroup._id = Utils.getBSONtoString(result.value.documentKey._id);

      const i0 = taskGroups0.findIndex(
        (element) => element._id === taskGroup.id
      );
      const i1 = taskGroups1.findIndex(
        (element) => element._id === taskGroup.id
      );

      if (i0 >= 0) {
        let deleteGT = grupoTareasAccion.deleteGT(taskGroups0, 0, i0);
        await dispatch(deleteGT);
      } else if (i1 >= 0) {
        let deleteGT = grupoTareasAccion.deleteGT(taskGroups1, 1, i1);
        await dispatch(deleteGT);
      }
    } catch (error) {
      // Utils.handleAlert(
      //     'Actualizar data',
      //     'Se ha realizado un cambio, pero ha ocurrido un error al momento de mostrarlo, recargue la página para visualizarlo',
      //     'Ok'
      // );
    }
  };

  // ---- WATCHS TASKS -----
  static task_created = async (task, dispatch, tasks, rayos) => {
    try {
      const filtrosGlobales = JSON.parse(sessionStorage.getItem("filtros"));
      let iR = filtrosGlobales[1].findIndex(
        (element) => element.filter === "idRayo"
      );
      let encontrado = Utils.findCustommerTask(filtrosGlobales, task);
      let rayoComparar = task.idrayo_task;

      if (filtrosGlobales[1][iR].data === null) {
        rayoComparar = null;
      }

      if (encontrado && filtrosGlobales[1][iR].data === rayoComparar) {
        task.name_rayo = Utils.nameRayo(task.idrayo_task, rayos);
        let createT = grupoTareasAccion.createT(tasks, task);
        dispatch(createT);
      }
    } catch (error) {}
  };

  static task_updated = async (task, dispatch, tasks, rayos) => {
    try {
      const filtrosGlobales = JSON.parse(sessionStorage.getItem("filtros"));
      let iR = filtrosGlobales[1].findIndex(
        (element) => element.filter === "idRayo"
      );
      let encontrado = Utils.findCustommerTask(filtrosGlobales, task);
      let rayoComparar = task.idrayo_task;

      if (filtrosGlobales[1][iR].data === null) {
        rayoComparar = null;
      }

      if (encontrado && filtrosGlobales[1][iR].data === rayoComparar) {
        task.name_rayo = Utils.nameRayo(task.idrayo_task, rayos);
        //task_Keys
        let updateT = grupoTareasAccion.updateT(tasks, task);
        dispatch(updateT);
      }
    } catch (error) {}
  };

  static task_deleted = async (result, dispatch, tasks) => {
    try {
      let task = {};
      task._id = Utils.getBSONtoString(result.value.documentKey._id);
      task.id = Utils.getBSONtoString(result.value.documentKey._id);

      const i = tasks.findIndex((element) => element._id === task.id);

      if (i >= 0) {
        let deleteT = grupoTareasAccion.deleteT(tasks, i);
        await dispatch(deleteT);
      }
    } catch (error) {}
  };
}

export default Watchs;
