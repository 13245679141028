import React, { useEffect, useState } from "react";

import L from "leaflet";
import "leaflet-routing-machine";

export default function Testimonials({ zonas }) {
  const [mapaRef, setMapaRef] = useState(undefined);
  const [route, setRoute] = useState(undefined);

  useEffect(() => {
    initialData();
  }, []);

  const initialData = () => {
    const map = L.map("mapaRouta");
    L.tileLayer(
      "https://api.mapbox.com/styles/v1/rayoapp/ckhfarifi0bfq19t72qem6bh3/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicmF5b2FwcCIsImEiOiJjanA0aW52aXowbjNvM3BzYW1tZDVyeGxzIn0.BHRdulbBe-zysvzUJwVvQw",
      {
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      }
    ).addTo(map);
    const position = [-33.4473526, -70.6379711];
    map.setView(position, 10, { animation: true });
    setMapaRef(map);
  };

  useEffect(() => {
    if (zonas) {
      let array = [];

      zonas.steps.forEach((point) => {
        array.push(L.latLng(point.location[1], point.location[0]));
      });

      setRoute(
        L.Routing.control({
          waypoints: array,
          routeWhileDragging: true,
          lineOptions: {
            styles: [
              { color: "rgba(102, 173, 255, 0.8)", opacity: 1, weight: 5 },
            ],
          },
          createMarker: function (i, waypoint, n) {
            let j = i + 1;
            let iconoDiv = L.divIcon({
              html:
                '<div class="container-icon"><div class="circle d-flex justify-content-center align-items-center"><p class="p-0">' +
                j +
                '</p></div><div class="triangle"></div></div>',
              iconAnchor: [16, 46],
              draggable: false,
            });
            return L.marker(waypoint.latLng, {
              icon: iconoDiv,
              draggable: false,
            });
          },
        }).addTo(mapaRef)
      );
    }
  }, [zonas]);

  return <div id="mapaRouta" className="map"></div>;
}
