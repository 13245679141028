import url from "../../config";
import Utils from "../../assets/utils/Utils";
import axios from "axios";
import cloneDeep from "lodash/cloneDeep";

export const ERROR_GRUPO_TAREAS = "ERROR_GRUPO_TAREAS";
export const RES_ERROR = "RES_ERROR";
export const error = () => {
  return async (dispatch) => {
    dispatch({ type: RES_ERROR });
  };
};

export const REINICIAR_ESTADOS = "REINICIAR_ESTADOS";
export const reiniciarEstados = () => {
  return async (dispatch) => {
    dispatch({
      type: REINICIAR_ESTADOS,
      grupoTareas0: null,
      grupoTareas1: null,
      localidades0: null,
      localidades1: null,
    });
  };
};

export const FILTROS = "FILTROS";
export const OBTENER_GRUPO_TAREAS_FILTRO = "OBTENER_GRUPO_TAREAS_FILTRO";
export const obtenerGTFiltro = (filtros, panel, pathName, tipo) => {
  return async (dispatch) => {
    try {
      let filtrosGlobales = JSON.parse(sessionStorage.getItem("filtros"));
      let usuario = JSON.parse(sessionStorage.getItem("user"));

      let query = "/Task-Groups/tasks_group/reduced/by?";
      if (usuario)
        if (usuario.type === 2)
          query = "/Task-Groups/tasks_group/epa/reduced/by?";

      let newFilters = cloneDeep(filtros);
      
      //console.log("siberia",pathName)
      if (pathName === "/Home/Mapa" || pathName === "/home/mapa" || pathName === "/Home/Lista" || pathName === "/home/lista") {
        if (tipo) {
          let clientes = Utils.getCustomerString();
          newFilters.push(
            { filter: "idCustomer", data: clientes },
            { filter: "idRayo", data: null }
          );
        }

        let newFiltrosGlobales = Utils.changeFilter(
          pathName,
          panel,
          newFilters,
          filtrosGlobales
        );
        sessionStorage.setItem("filtros", JSON.stringify(newFiltrosGlobales));
        let stringifyFiltro = Utils.stringifyFilters(
          pathName,
          panel,
          newFiltrosGlobales
        );

        if (usuario)
          if (usuario.type === 2)
            stringifyFiltro = stringifyFiltro + Utils.getFilterIdProviderEPA();
        
        
        await axios({
          method: "get",
          url: url.host + query + stringifyFiltro,
          headers: { tenant: usuario.tenant },
        })
          .then((response) => {
            switch (response.status) {
              case 200:
                let taskGroups = response.data;
                let arreglo = Utils.localizacionesT(taskGroups);
                let estado = Utils.nombreEstado(
                  newFiltrosGlobales[0][parseInt(panel)].data
                );

                taskGroups.forEach((element) => {
                  delete element.history_taskgroup;
                  delete element.end_date_taskgroup;
                  delete element.creation_date_taskgroup;
                  delete element.prioritized_EPA;

                  element.selected = false;
                });
                dispatch({
                  type: OBTENER_GRUPO_TAREAS_FILTRO,
                  grupoTareas: taskGroups,
                  estado: estado,
                  localidades: arreglo,
                  panel: panel,
                  filtros: filtrosGlobales,
                });
                break;

              default:
                dispatch({
                  type: ERROR_GRUPO_TAREAS,
                  error:
                    "Error " +
                    response.status +
                    " al realizar la petición de obtener grupos de tareas",
                });
                break;
            }
          })
          .catch((e) => {
            dispatch({
              type: ERROR_GRUPO_TAREAS,
              error:
                "Error al realizar la petición de obtener grupos de tareas",
            });
          });
      } else {
        return async (dispatch) => {
          dispatch({ type: FILTROS, filtros: filtrosGlobales });
        };
      }
    } catch (e) {
      dispatch({
        type: ERROR_GRUPO_TAREAS,
        error: "Error al realizar la petición de obtener grupos de tareas",
      });
    }
  };
};

export const GUARDAR_CAMBIO_GT = "GUARDAR_CAMBIO_GT";
export const guardarCambiosGT = (gruposTareas, panel) => {
  return async (dispatch) => {
    let taskGroups = gruposTareas;
    let arreglo = Utils.localizacionesT(taskGroups);
    let filtrosGlobales = JSON.parse(sessionStorage.getItem("filtros"));
    let estado = Utils.nombreEstado(filtrosGlobales[0][parseInt(panel)].data);
    dispatch({
      type: GUARDAR_CAMBIO_GT,
      grupoTareas: taskGroups,
      localidades: arreglo,
      estado: estado,
      panel: panel,
    });
  };
};

export const MODIFICAR_GT = "MODIFICAR_GT";
export const modificarGT = (
  gruposTareas,
  panel,
  seleccionados,
  seleccionDesdeMapa
) => {
  return async (dispatch) => {
    let totalSeleccionado = 0;
    let estado = false;
    let wayPoints = [];

    gruposTareas.forEach((grupo) => {
      seleccionados.forEach((seleccionado) => {
        if (grupo._id === seleccionado) {
          grupo.selected = !grupo.selected;
        }

        if (grupo.selected === true) {
          estado = true;
        }
      });

      if (grupo.selected === true) {
        grupo.tasks.forEach((tarea) => {
          wayPoints.push({
            location: { lat: tarea.latitude_task, lng: tarea.longitude_task },
          });
        });
        totalSeleccionado = totalSeleccionado + 1;
      }
    });

    if (estado === false) {
      gruposTareas.forEach((grupo) => {
        grupo.selected = false;
      });
      seleccionDesdeMapa = false;
      wayPoints = [];
    }

    wayPoints = wayPoints.filter(
      (thing, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.location.lat === thing.location.lat &&
            t.location.lng === thing.location.lng
        )
    );

    dispatch({
      type: MODIFICAR_GT,
      grupoTareas: gruposTareas,
      panel: panel,
      seleccionado: estado,
      totalSeleccionado: totalSeleccionado,
      wayPoints: wayPoints,
      seleccionDesdeMapa: seleccionDesdeMapa,
    });
  };
};

export const DES_SELECCIONAR_GT = "DES_SELECCIONAR_GT";
export const desseleccionarGT = (gruposTareas, panel) => {
  return async (dispatch) => {
    let estado = false;
    gruposTareas.forEach((grupo) => {
      grupo.selected = false;
    });
    let wayPoints = [];

    dispatch({
      type: DES_SELECCIONAR_GT,
      grupoTareas: gruposTareas,
      panel: panel,
      seleccionado: estado,
      totalSeleccionado: 0,
      wayPoints: wayPoints,
      seleccionDesdeMapa: false,
    });
  };
};

export const ERROR_TAREAS = "ERROR_TAREAS";
export const OBTENER_TAREAS = "OBTENER_TAREAS";
export const obtenerTareas = (
  filtros,
  tipo,
  num,
  filtersTask,
  filterText,
  rows
) => {
  return async (dispatch) => {
    try {
      let filtrosGlobales = JSON.parse(sessionStorage.getItem("filtros"));
      let usuario = JSON.parse(sessionStorage.getItem("user"));

      // ===================>>>>> DESCOMENTAR-PAGINADOR-API START <<<<<===================
      let query = "/Tasks/tasks/reduced/by/words?";
      // ===================>>>>> DESCOMENTAR-PAGINADOR-API END <<<<<===================
      // let query = '/Tasks/tasks/reduced/by?';

      if (usuario)
        if (usuario.type === 2) query = "/Tasks/tasks/reduced/by/epa?";

      let newFilters = cloneDeep(filtros);
      if (tipo) {
        let clientes = Utils.getCustomerString();
        newFilters.push(
          { filter: "idCustomer", data: clientes },
          { filter: "idRayo", data: null },
          { filter: "idStatusTask", data: null },
          { filter: "all", data: null }
        );
      }

      if (filtersTask) {
        newFilters.push({ filter: "idStatusTask", data: filtersTask });
      } else if (filtersTask === "") {
        newFilters.push({ filter: "idStatusTask", data: null });
      }

      // ===================>>>>> DESCOMENTAR-PAGINADOR-API START <<<<<===================
      if (filterText) {
        newFilters.push(filterText);
      } else if (filterText === "") {
        newFilters.push({ filter: "all", data: null });
      }
      // ===================>>>>> DESCOMENTAR-PAGINADOR-API END <<<<<===================

      let newFiltrosGlobales = Utils.changeFilter(
        "/Mapa/Lista",
        3,
        newFilters,
        filtrosGlobales
      );
      sessionStorage.setItem("filtros", JSON.stringify(newFiltrosGlobales));
      let stringifyFiltro = Utils.stringifyFilters(
        "/Mapa/Lista",
        3,
        newFiltrosGlobales
      );

      if (usuario)
        if (usuario.type === 2)
          stringifyFiltro =
            stringifyFiltro + Utils.getFilterIdProviderEPATask();

      let pagina = 0;
      // ===================>>>>> DESCOMENTAR-PAGINADOR-API START <<<<<===================
      if (num) {
        if (rows) {
          stringifyFiltro = stringifyFiltro + "&skip=" + num + "&limit=" + rows;
          pagina = num / rows;
        } else {
          stringifyFiltro = stringifyFiltro + "&skip=" + num + "&limit=10";
          pagina = num / 10;
        }
      } else {
        if (rows) {
          stringifyFiltro = stringifyFiltro + "&skip=0&limit=" + rows;
        } else {
          stringifyFiltro = stringifyFiltro + "&skip=0&limit=10";
        }
      }
      // ===================>>>>> DESCOMENTAR-PAGINADOR-API END <<<<<===================

      await axios({
        method: "get",
        url: url.host + query + stringifyFiltro,
        headers: { tenant: usuario.tenant },
      })
        .then((response) => {
          switch (response.status) {
            case 200:
              let tareas;
              let totalTareas = 0;
              // ===================>>>>> DESCOMENTAR-PAGINADOR-API START <<<<<===================
              if (usuario.type === 2) {
                tareas = response.data;
                totalTareas = response.data.length;
              } else {
                tareas = response.data.resultTask;
                totalTareas = response.data.cantDocuments;
              }
              // ===================>>>>> DESCOMENTAR-PAGINADOR-API END <<<<<===================
              // let tareas = response.data;

              tareas.forEach((tarea) => {
                tarea.selected = false;
              });
              dispatch({
                type: OBTENER_TAREAS,
                tareas: tareas,
                filtros: filtrosGlobales,
                seleccionadoT: false,
                paginador: pagina,
                countTasks: totalTareas,
              });
              break;

            default:
              dispatch({
                type: ERROR_TAREAS,
                error:
                  "Error " +
                  response.status +
                  " al realizar la petición de obtener tareas",
              });
              break;
          }
        })
        .catch((e) => {
          dispatch({
            type: ERROR_TAREAS,
            error: "Error al realizar la petición de obtener tareas",
          });
        });
    } catch (e) {
      dispatch({
        type: ERROR_TAREAS,
        error: "Error al realizar la petición de obtener tareas",
      });
    }
  };
};

export const ACTUALIZAR_CANTIDAD_TAREAS = "ACTUALIZAR_CANTIDAD_TAREAS";
export const actualizarCantidadTareas = (num) => {
  return async (dispatch) => {
    dispatch({
      type: ACTUALIZAR_CANTIDAD_TAREAS,
      countTasks: num,
    });
  };
};

export const MODIFICAR_T = "MODIFICAR_T";
export const modificarTareas = (tareas, seleccionados) => {
  return async (dispatch) => {
    let estado = false;
    tareas.forEach((tarea) => {
      seleccionados.forEach((seleccionado) => {
        if (tarea._id === seleccionado) {
          tarea.selected = !tarea.selected;
        }

        if (tarea.selected === true) {
          estado = true;
        }
      });
    });

    dispatch({
      type: MODIFICAR_T,
      tareas: tareas,
      seleccionadoT: estado,
    });
  };
};

export const DES_SELECCIONAR_T = "DES_SELECCIONAR_T";
export const desseleccionarTareas = (tareas) => {
  return async (dispatch) => {
    let estado = false;
    tareas.forEach((tarea) => {
      tarea.selected = false;
    });

    dispatch({
      type: DES_SELECCIONAR_T,
      tareas: tareas,
      seleccionadoT: estado,
    });
  };
};

export const SELECCIONAR_TODAS_T = "SELECCIONAR_TODAS_T";
export const seleccionarTodasTareas = (tareas) => {
  return async (dispatch) => {
    let estado = true;

    dispatch({
      type: SELECCIONAR_TODAS_T,
      tareas: tareas,
      seleccionadoT: estado,
    });
  };
};

export const UPDATE_GT = "UPDATE_GT";
export const updateGT = (
  gruposTareas0,
  gruposTareas1,
  clientes,
  grupoModificado
) => {
  return async (dispatch) => {
    try {
      let filtrosGlobales = JSON.parse(sessionStorage.getItem("filtros"));
      let estadoGrupo = grupoModificado.idstatus_taskgroup;
      let estado0 = filtrosGlobales[0][0].data;
      let estado1 = filtrosGlobales[0][1].data;

      let estado0array = estado0.toString().split(",");
      let estado1array = estado1.toString().split(",");
      let entraArray0 = false;
      let entraArray1 = false;
      estado0array.forEach((element) => {
        if (element === estadoGrupo || estado0 === null) entraArray0 = true;
      });
      estado1array.forEach((element) => {
        if (element === estadoGrupo || estado1 === null) entraArray1 = true;
      });

      if (entraArray0) {
        const i = gruposTareas0.findIndex(
          (element) => element._id === grupoModificado._id
        );
        if (i >= 0) {
          let grupoAnterior = cloneDeep(gruposTareas0[i]);
          grupoModificado.selected = grupoAnterior.selected;
          gruposTareas0[i] = grupoModificado;
          grupoModificado.tasks = grupoAnterior.tasks;
          grupoModificado.customer = grupoAnterior.customer;

          let arreglo = Utils.localizacionesT(gruposTareas0);

          dispatch({
            type: UPDATE_GT,
            grupoTareas: gruposTareas0,
            localidades: arreglo,
            panel: 0,
          });
        } else {
          let grupo = cloneDeep(grupoModificado);
          let grupos = cloneDeep(gruposTareas0);
          if (!grupo.tasks) {
            grupo.tasks = [];
          }

          grupo.selected = false;
          if (clientes == null) {
            grupo.customer = [
              {
                _id: grupo.idcustomer_taskgroup,
                name_customer: "",
              },
            ];
          } else {
            const i = clientes.findIndex(
              (element) => element._id === grupo.idcustomer_taskgroup
            );
            if (i >= 0) {
              grupo.customer = [
                {
                  _id: grupo.idcustomer_taskgroup,
                  name_customer: clientes[i].name_customer,
                },
              ];
            } else {
              grupo.customer = [
                {
                  _id: grupo.idcustomer_taskgroup,
                  name_customer: "",
                },
              ];
            }
          }

          grupos.push(grupo);

          let arreglo = Utils.localizacionesT(grupos);

          dispatch({
            type: UPDATE_GT,
            grupoTareas: grupos,
            localidades: arreglo,
            panel: 0,
          });
        }
      }

      if (entraArray1) {
        const i = gruposTareas1.findIndex(
          (element) => element._id === grupoModificado._id
        );
        if (i >= 0) {
          let grupoAnterior = cloneDeep(gruposTareas1[i]);
          grupoModificado.selected = grupoAnterior.selected;
          grupoModificado.tasks = grupoAnterior.tasks;
          grupoModificado.customer = grupoAnterior.customer;
          gruposTareas1[i] = grupoModificado;

          let arreglo = Utils.localizacionesT(gruposTareas1);

          dispatch({
            type: UPDATE_GT,
            grupoTareas: gruposTareas1,
            localidades: arreglo,
            panel: 1,
          });
        } else {
          let grupo = cloneDeep(grupoModificado);
          let grupos = cloneDeep(gruposTareas1);
          if (!grupo.tasks) {
            grupo.tasks = [];
          }
          grupo.selected = false;

          if (clientes == null) {
            grupo.customer = [
              {
                _id: grupo.idcustomer_taskgroup,
                name_customer: "",
              },
            ];
          } else {
            const i = clientes.findIndex(
              (element) => element._id === grupo.idcustomer_taskgroup
            );
            if (i >= 0) {
              grupo.customer = [
                {
                  _id: grupo.idcustomer_taskgroup,
                  name_customer: clientes[i].name_customer,
                },
              ];
            } else {
              grupo.customer = [
                {
                  _id: grupo.idcustomer_taskgroup,
                  name_customer: "",
                },
              ];
            }
          }
          grupos.push(grupo);

          let arreglo = Utils.localizacionesT(grupos);

          dispatch({
            type: UPDATE_GT,
            grupoTareas: grupos,
            localidades: arreglo,
            panel: 1,
          });
        }
      }
    } catch (error) { }
  };
};

export const CREATE_GT = "UPDATE_GT";
export const createGT = (gruposTareas0, gruposTareas1, grupoCreado) => {
  return async (dispatch) => {
    let filtrosGlobales = JSON.parse(sessionStorage.getItem("filtros"));
    let estadoGrupo = grupoCreado.idstatus_taskgroup;
    let estado0 = filtrosGlobales[0][0].data;
    let estado1 = filtrosGlobales[0][1].data;

    if (gruposTareas0 != null) {
      let estado0array = estado0.toString().split(",");
      let entraArray = false;
      estado0array.forEach((element) => {
        if (element === estadoGrupo || estado0 === null) entraArray = true;
      });
      if (entraArray) {
        let grupo = cloneDeep(grupoCreado);
        let grupos = cloneDeep(gruposTareas0);
        if (!grupo.tasks) grupo.tasks = [];
        grupo.selected = false;
        grupos.push(grupo);

        let arreglo = Utils.localizacionesT(grupos);
        dispatch({
          type: CREATE_GT,
          grupoTareas: grupos,
          localidades: arreglo,
          panel: 0,
        });
      }
    }

    if (gruposTareas1 != null) {
      let estado1array = estado1.toString().split(",");
      let entraArray = false;
      estado1array.forEach((element) => {
        if (element === estadoGrupo || estado1 === null) entraArray = true;
      });
      if (entraArray) {
        let grupo = cloneDeep(grupoCreado);
        let grupos = cloneDeep(gruposTareas1);
        if (!grupo.tasks) grupo.tasks = [];
        grupo.selected = false;
        grupos.push(grupo);

        let arreglo = Utils.localizacionesT(grupos);
        dispatch({
          type: CREATE_GT,
          grupoTareas: grupos,
          localidades: arreglo,
          panel: 1,
        });
      }
    }
  };
};

export const DELETE_GT = "DELETE_GT";
export const deleteGT = (gruposTareas, panel, i) => {
  return async (dispatch) => {
    let grupos = cloneDeep(gruposTareas);
    grupos.splice(i, 1);
    let arreglo = Utils.localizacionesT(grupos);

    dispatch({
      type: DELETE_GT,
      grupoTareas: grupos,
      localidades: arreglo,
      panel: panel,
    });
  };
};

export const INSERT_TASK_IN_GT = "INSERT_TASK_IN_GT";
export const insertTaskInTG = (gruposTareas, tarea, panel, i) => {
  return async (dispatch) => {
    let grupos = cloneDeep(gruposTareas);
    grupos[i].tasks.push(tarea);
    let arreglo = Utils.localizacionesT(grupos);

    dispatch({
      type: INSERT_TASK_IN_GT,
      grupoTareas: grupos,
      localidades: arreglo,
      panel: panel,
    });
  };
};

export const DELETE_T = "DELETE_T";
export const deleteT = (tareas, i) => {
  return async (dispatch) => {
    if (tareas) {
      let tasks = cloneDeep(tareas);
      tasks.splice(i, 1);

      dispatch({
        type: DELETE_T,
        tareas: tasks,
      });
    }
  };
};

export const UPDATE_T = "UPDATE_T";
export const updateT = (tareas, tarea) => {
  return async (dispatch) => {
    if (tareas) {
      const i = tareas.findIndex((element) => element._id === tarea._id);
      if (i >= 0) {
        let tareaAnterior = cloneDeep(tareas[i]);
        tarea.selected = tareaAnterior.selected;
        tarea.customer = tareaAnterior.customer;
        tareas[i] = tarea;

        dispatch({
          type: UPDATE_T,
          tareas: tareas,
        });
      }
    }
  };
};

export const CREATE_T = "CREATE_T";
export const createT = (tareas, tarea) => {
  return async (dispatch) => {
    if (tareas) {
      tarea.selected = false;
      tareas.push(tarea);

      dispatch({
        type: CREATE_T,
        tareas: tareas,
      });
    }
  };
};

export const ELIMINAR_LOCALIZACIONES = "ELIMINAR_LOCALIZACIONES";
export const eliminarLocalizaciones = () => {
  return async (dispatch) => {
    dispatch({
      type: ELIMINAR_LOCALIZACIONES,
      localidades: null,
    });
  };
};

export const OBTENER_TAREAS_CLIENTE = "OBTENER_TAREAS_CLIENTE";
export const obtenerTareasCliente = (
  stringFilter
) => {
  return async (dispatch) => {
    try {
      let filtrosGlobales = JSON.parse(sessionStorage.getItem("filtros"));

      let cliente = Utils.stringifyFiltersCliente(filtrosGlobales)
      let stringifyFiltro = stringFilter + cliente;


      let usuario = JSON.parse(sessionStorage.getItem("user"));
      await axios({
        method: "get",
        url: url.host + "/Tasks/tasks/multiscan/by?" + stringifyFiltro,
        headers: { tenant: usuario.tenant },
      })
        .then((response) => {
          switch (response.status) {
            case 200:
              let tareas = response.data;
              dispatch({
                type: OBTENER_TAREAS_CLIENTE,
                tareas: tareas,
              });
              break;

            default:
              dispatch({
                type: ERROR_TAREAS,
                error:
                  "Error al realizar la petición de obtener tareas",
              });
              break;
          }
        })
        .catch((e) => {
          dispatch({
            type: ERROR_TAREAS,
            error: "Error al realizar la petición de obtener tareas",
          });
        });
    } catch (e) {
      dispatch({
        type: ERROR_TAREAS,
        error: "Error al realizar la petición de obtener tareas",
      });
    }
  };
};