import { OBTENER_PROVEEDORES } from '../actions/Proveedores';

const estadoInicial = {
    proveedores: null,
    error: null,
};

const proveedoresReducer = (estado = estadoInicial, accion) => {
    switch (accion.type) {
        case OBTENER_PROVEEDORES:
            return {
                ...estado,
                proveedores: accion.proveedores,
            };
        default:
            return estado;
    }
};

export default proveedoresReducer;