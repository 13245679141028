import React from "react";
import $ from "jquery";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

class EditarRayo extends React.Component {
  render() {
    let rayos = this.props.rayos;
    if (this.props.rayos === undefined || this.props.rayos === null) {
      rayos = [];
    }

    return (
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal-white">
          <div className="modal-body">
            <h4 className="h4-modal text-center">
              {this.props.vista === "mapa" || this.props.vista === "lista"
                ? "Reasignar Rayo"
                : "Reasignación Masiva Rayo"}
            </h4>
            <h4 className="text-roboto-regular color-mega-dark-grey text-center">
              {this.props.vista === "mapa"
                ? "ID Grupo de Tareas"
                : this.props.vista === "lista"
                ? "ID de Tarea"
                : "ID Tareas"}
            </h4>
            <div className="w-100 back-grey-input br-4 p-2 mb-2">
              <h6 className="w-100 text-roboto-regular text-center color-mega-dark mb-0">
                {typeof this.props.code === undefined ||
                this.props.code === null
                  ? ""
                  : this.props.code.map((code, i) => {
                      let codigo = code.codigo;
                      if (i !== 0) {
                        codigo = ", " + codigo;
                      }
                      return codigo;
                    })}
              </h6>
            </div>

            <label className="text-roboto-regular color-mega-dark-grey mb-1">
              Selecciona la opción
            </label>
            <Autocomplete
              id="combo-box-demo"
              className="w-100 autocomplete-rayo"
              name="rayo_selected"
              noOptionsText={"No se encuentran opciones"}
              value={this.props.rayo_selected}
              options={rayos}
              getOptionSelected={(option, value) =>
                value.value === option.value
              }
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name_rayo
              }
              onChange={(e, newValue) => {
                let valor = {
                  target: { name: "rayo_selected", value: newValue },
                };
                this.props.handleChange(valor);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="rayo_selected"
                  variant="outlined"
                  className="outline-primary"
                  placeholder="Seleccionar"
                />
              )}
            />
          </div>
          <div className="modal-footer row m-0">
            <div className="col-6 d-flex justify-content-center">
              <Button
                type="button"
                variant="text"
                color="primary"
                onClick={() => $("#modal").modal("hide")}
              >
                Cancelar
              </Button>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <Button
                type="button"
                variant="contained"
                color="secondary"
                onClick={() => this.props.modify()}
              >
                Confirmar
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditarRayo;
