import url from '../../config';
import axios from 'axios';
import Utils from '../../assets/utils/Utils';
import { BSON } from 'realm-web';

export const ERROR_CLIENTES = 'ERROR_CLIENTES';
export const RES_ERROR = 'RES_ERROR';
export const error = () => {
    return async (dispatch) => {
        dispatch({ type: RES_ERROR });
    };
};

export const OBTENER_CLIENTES = 'OBTENER_CLIENTES';
export const obtenerClientes = () => {
    return async (dispatch) => {
        try {
            let usuario = JSON.parse(sessionStorage.getItem("user"));
            let clientes = Utils.getCustomers();
            await axios({
                method: 'get',
                url: url.host + '/Customers/customers' + clientes,
                headers: { tenant: usuario.tenant },
            })
                .then((response) => {
                    switch (response.status) {
                        case 200:
                            let clientesSort = response.data.sort(function (a, b) {
                                if (a.name_customer.toLowerCase().trim() < b.name_customer.toLowerCase().trim()) return -1;
                                if (a.name_customer.toLowerCase().trim() > b.name_customer.toLowerCase().trim()) return 1;
                                return 0;
                            });
                            dispatch({
                                type: OBTENER_CLIENTES,
                                clientes: clientesSort,
                            });
                            break;

                        default:
                            dispatch({
                                type: ERROR_CLIENTES,
                                error: 'Error ' + response.status + ' al realizar la petición obtener clientes',
                            });
                            break;
                    }
                })
                .catch((e) => {
                    dispatch({
                        type: ERROR_CLIENTES,
                        error: 'Error al realizar la petición obtener clientes',
                    });
                });
        } catch (e) {
            dispatch({
                type: ERROR_CLIENTES,
                error: 'Error al realizar la petición obtener clientes',
            });
        }
    };
};
