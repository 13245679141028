import url from '../../config';
import axios from 'axios';
import Utils from '../../assets/utils/Utils'

export const ERROR_TEMPLATES = 'ERROR_TEMPLATES';
export const RES_ERROR = "RES_ERROR";
export const error = () => {
  return async (dispatch) => {
    dispatch({ type: RES_ERROR });
  };
};

export const OBTENER_TEMPLATES = 'OBTENER_TEMPLATES';
export const obtenerTemplates = () => {
    return async (dispatch) => {
        try {
            let clientes = Utils.getCustomers();
            let usuario = JSON.parse(sessionStorage.getItem("user"));
            await axios({
                method: 'get',
                url: url.host + '/Templates/templates' +clientes,
                headers: { tenant: usuario.tenant },
            }).then((response) => {
                switch (response.status) {
                    case 200:

                        axios({
                            method: 'get',
                            url: url.host + '/Type-Activities/type_activities/',
                            headers: { tenant: usuario.tenant },
                        }).then((responseTA) => {
                            switch (responseTA.status) {
                                case 200:
                                    response.data.forEach((element, i) => {
                                        element.type_activity_template.forEach((tipo, t) => {
                                            responseTA.data.forEach((tipoA, ta)=>{
                                                if(tipo.idTypeActivity===tipoA._id){
                                                    tipo.image_type_activity = tipoA.image_type_activity;
                                                    tipo.name_type_activity = tipoA.name_type_activity;
                                                }
                                            })
                                        });
                                    });

                                    dispatch({
                                        type: OBTENER_TEMPLATES,
                                        templates: response.data,
                                    });
                                    break;
            
                                default:
                                    dispatch({
                                        type: ERROR_TEMPLATES,
                                        error: 'Error ' + response.status + ' al realizar la petición obtener templates',
                                    });
                                    break;
                            }
                        }).catch((e)=>{
                            dispatch({
                                type: ERROR_TEMPLATES,
                                error: 'Error al realizar la petición obtener templates',
                            });
                        })
                        break;

                    default:
                        dispatch({
                            type: ERROR_TEMPLATES,
                            error: 'Error ' + response.status + ' al realizar la petición obtener templates',
                        });
                        break;
                }
            }).catch((e)=>{
                dispatch({
                    type: ERROR_TEMPLATES,
                    error: 'Error al realizar la petición obtener templates',
                });
            })
        } catch (e) {
            dispatch({
                type: ERROR_TEMPLATES,
                error: 'Error al realizar la petición obtener templates',
            });
        }
    };
};